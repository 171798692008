import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import AudioCard from "../verses/AudioCard";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";

import { FaBookmark, FaShareAlt } from "react-icons/fa";
import { getJournalsAction } from "../../store/Bible/BibleAction";
import { useParams } from "react-router-dom";
import LoaderSpinner from "../../components/common/LoaderSpinner";
import Toastiy from "../../components/common/Toastiy";
import { copyToClipboard } from "../../utils/Utils";

const JournalDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const [popCancle, setPopCancel] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [journalDetails, setJournalDetails] = useState({});
  const [journalDetailsLoading, setJournalDetailsLoading] = useState(false);

  const handleTabChange = (index) => {
    setActiveTab(index);
  };

  const fetchJournals = () => {
    setJournalDetailsLoading(true);

    dispatch(
      getJournalsAction({
        apiPayloadRequest: { _id: id },
        callback: (response) => {
          setJournalDetails(response?.data?.[0]);
          setJournalDetailsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchJournals();
  }, []);

  const thumbnail = journalDetails?.bg_Images?.find((d) => d?.type_of_url === "1");
  const banner = journalDetails?.banner_Images?.find((d) => d?.type_of_url === "1");

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Journals"} />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          popCancle={popCancle}
          cancelBtn={!popCancle}
          cancelBtnHandle={() => setPopCancel(true)}
          tabValues={[]}
          stepBack={true}
          back={true}
          backLink="/journals"
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={() => {}}
          getCurrentLanguageTitles={() => {}}
        />
        <div className="w-full flex flex-col bg-black flex-1 overflow-y-auto p-8">
          {journalDetailsLoading ? (
            <div className="w-full h-[60vh] flex items-center justify-center">
              <LoaderSpinner />
            </div>
          ) : (
            <>
              <div
                className="aspect-[16/12] md:aspect-[16/3] bg-cover bg-top relative rounded-t-3xl overflow-hidden"
                style={{ backgroundImage: `url(${banner?.url_link})` }}
              >
                <div className="absolute h-full w-full bg-gradient-to-bl from-black/0 to-black/80 flex flex-col justify-end p-8">
                  <div className="text-white absolute top-8 left-8 flex gap-3.5 items-center text-xl">
                    {/* <FaBookmark /> */}
                    <FaShareAlt
                      className="cursor-pointer"
                      onClick={() => {
                        copyToClipboard(`https://todaywithgod.org/journals/${journalDetails?._id}`);
                        Toastiy("Copied to clipboard!", 1);
                      }}
                    />
                  </div>
                  <h3 className="text-white font-semibold text-4xl md:text-5xl">
                    {journalDetails?.journal_title}
                  </h3>
                  <span className="text-white pb-2 text-lg">{journalDetails?.author ?? ""}</span>
                </div>
              </div>
              <div className="w-full text-white text-opacity-90 p-8 bg-blackishV2">
                <p className="py-2">{journalDetails?.journal_description}</p>
                {journalDetails?.notes?.map((noteItem, noteIndex) => (
                  <div
                    className="w-full flex-1 pr-2 pb-10 overflow-y-auto custom-scrollbar"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    {noteItem?.verse_start && (
                      <div className="w-full py-2">
                        <div className="text-greyish flex gap-4 py-2">
                          <h3 className="text-2xl font-semibold">
                            {noteItem?.bookname}{" "}
                            <span>
                              {noteItem?.chapterid}:{noteItem?.verse_start}
                            </span>
                          </h3>
                        </div>
                        <p className="text-greyish">{noteItem?.verse_text}</p>
                      </div>
                    )}
                    {noteItem?.noteitems?.map((item, noteItemIndex) => {
                      if (item?.type_of_notes === "4") {
                        return (
                          <div className="w-full py-2" key={`note-item${noteItemIndex}`}>
                            <div className="text-greyish flex gap-4 py-2">
                              <h3 className="text-2xl font-semibold">
                                {item?.bookname}{" "}
                                <span>
                                  {item?.chapterid}:{item?.verse_start}
                                </span>
                              </h3>
                            </div>
                            <p className="text-greyish">{item?.verse_text}</p>
                          </div>
                        );
                      } else if (item?.type_of_notes === "0") {
                        return (
                          <div
                            className="w-full text-greyish py-3"
                            key={`note-item${noteItemIndex}`}
                          >
                            <p dangerouslySetInnerHTML={{ __html: item?.notes }}></p>
                          </div>
                        );
                      } else if (item?.type_of_notes === "3") {
                        return (
                          <div
                            className="w-full text-greyish py-3"
                            key={`note-item${noteItemIndex}`}
                          >
                            {item?.url_link && (
                              <img
                                src={item?.url_link}
                                alt={"image" + (noteItemIndex + 1)}
                                className="w-full col-span-3 object-cover object-top"
                              />
                            )}
                            {item?.isLoading && <LoaderSpinner />}
                          </div>
                        );
                      } else if (item?.type_of_notes === "1") {
                        return (
                          <div
                            className="w-full text-greyish py-3"
                            key={`note-item${noteItemIndex}`}
                          >
                            {item?.url_link && (
                              <video
                                src={item?.url_link}
                                alt={"video" + (noteItemIndex + 1)}
                                controls
                                className="w-full max-w-full aspect-video object-cover object-top bg-greyishV3  border border-greyishV2"
                              />
                            )}
                            {item?.isLoading && <LoaderSpinner />}
                          </div>
                        );
                      } else if (item?.type_of_notes === "2") {
                        return (
                          <div
                            className="w-full grid grid-cols-3 gap-2 text-greyish py-3"
                            key={`note-item${noteItemIndex}`}
                          >
                            <AudioCard url={item?.url_link} />
                          </div>
                        );
                      }
                      return null;
                    })}
                  </div>
                ))}
              </div>
            </>
          )}
        </div>
      </div>
    </div>
  );
};

export default JournalDetails;
