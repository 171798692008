import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import VersionDetailCard from "./VersionDetailCard";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { SEARCH_TABS, SEARCH_TABS_KEYS } from "../../constants/common";

const BibleVersionSearchV2 = ({
  isVisible,
  setIsDropdownActive,
  versions,
  allVersions,
  setVersions,
  loading,
  handleSelection = null,
  bibleParallelMode = false,
}) => {
  const navigate = useNavigate();
  const location = useLocation();

  const { bookId, chapterNo } = useParams();

  const { bibleVersion } = useSelector((state) => state.bible);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [searchText, setSearchText] = useState("");

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  useEffect(() => {
    if (!searchText) {
      setVersions(allVersions);
    } else {
      const text = searchText?.toLowerCase();
      const updatedLanguages = allVersions?.filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(text) || item?.abbr?.toLowerCase()?.includes(text)
        );
      });
      setVersions(updatedLanguages);
    }
  }, [allVersions, searchText]);

  const handleVersionSelection = (version) => {
    if (handleSelection) handleSelection(version);
    setIsDropdownActive(false);
    if (location?.pathname?.includes("/chapter/") && !bibleParallelMode) {
      navigate(`/chapter/${version}/${bookId}/${parseInt(chapterNo)}`);
    }
    if (searchText !== "") {
      setSearchText("");
    }
  };

  return (
    isVisible && (
      <div className="h-full flex flex-col pb-10">
        <div className="flex items-center gap-5 pb-7">
          <div className="w-full bg-white/5 md:min-w-[400px] flex items-center gap-3 border rounded-full px-5 py-2 border-greyish">
            <input
              type="text"
              value={searchText}
              placeholder={
                currentLanguageTitles?.[0]?.bible?.[SEARCH_TABS_KEYS[SEARCH_TABS.BIBLE]] ??
                "Search Bible"
              }
              onChange={(e) => handleSearch(e)}
              className="w-full bg-transparent outline-none text-white placeholder:text-greyish"
            />
          </div>
        </div>
        <ul className="flex-1 overflow-y-auto custom-scrollbar">
          {loading ? (
            <div className="w-full flex flex-col gap-5 justify-center">
              <div className="w-full grid grid-cols-12 gap-4">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
            </div>
          ) : (
            versions?.map((item, index) => {
              return (
                <li key={`version-item${index}`}>
                  <VersionDetailCard
                    data={item}
                    showAvailableTypes={true}
                    onClick={handleVersionSelection}
                    active={item?.abbr === bibleVersion}
                  />
                </li>
              );
            })
          )}
        </ul>
      </div>
    )
  );
};

export default BibleVersionSearchV2;
