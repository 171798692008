import { useEffect, useState } from "react";

import Bible from "../../assets/images/bible.gif";
import BibleDefaultView from "./BibleDefaultView";
import { useSelector } from "react-redux";

const BibleBookView = () => {
  const { isMobile } = useSelector((state) => state.common);
  const [isDropdownActive, setIsDropdownActive] = useState(false);

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector("#floatingBookView");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsDropdownActive((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div>
      <img
        className="h-14 min-h-14 cursor-pointer -translate-y-2"
        src={Bible}
        alt="Bible"
        onClick={() => setIsDropdownActive((prev) => !prev)}
      />
      {isDropdownActive && (
        <div
          className="w-[70vw] text-white absolute bg-black border border-greyish/50 backdrop-blur-md pt-8 px-8 rounded-3xl rounded-ss-none top-20 h-fit z-50 flex flex-col overflow-hidden bible-float-section-shadow"
          id="floatingBookView"
        >
          <BibleDefaultView
            height={isMobile ? 200 : 350}
            onClick={() => setIsDropdownActive(false)}
          />
        </div>
      )}
    </div>
  );
};

export default BibleBookView;
