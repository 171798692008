import * as actionTypes from "../ActionTypes";
import { put, call, takeLatest, takeEvery } from "redux-saga/effects";
import * as api from "./BibleAPI";
import { actionUpdateGlobalLoaderSagaAction } from "../GlobalLoader/GlobalLoaderAction";

function* getBibleLanguagesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getBibleLanguages, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getBibleCountryLanguagesSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getBibleCountryLanguages, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getBibleVersionsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getBibleVersions, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getVersionBooksSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getVersionBooks, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getChapterDetailsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getChapterDetails, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getBibleSearchSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getBibleSearch, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getBibleResourceKeySaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getBibleResourceKey, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteUserJournalsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteUserJournals, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getUserJournalsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getUserJournals, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getVerseDetailsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getVerseDetails, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getVerseColorSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getVerseColor, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveVerseColorSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveVerseColor, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateVerseColorSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateVerseColor, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteVerseColorSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteVerseColor, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getChapterVideosSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getChapterVideos, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getChapterJournalsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getChapterJournals, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getJournalsSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getJournals, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateUserStreakSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateUserStreak, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getUserStreakSummarySaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getUserStreakSummary, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getJournalSpeakersSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getJournalSpeakers, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getJournalsBySpeakerSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getJournalsBySpeaker, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getJournalsByCategorySaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.getJournalsByCategory, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* saveJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.saveJournal, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournal, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteJournalSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournal, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.addJournalNote, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournalNote, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteJournalNoteSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournalNote, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* addJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.addJournalNoteItem, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* updateJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.updateJournalNoteItem, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* deleteJournalNoteItemSaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.deleteJournalNoteItem, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

function* getBibleSummarySaga(action) {
  yield put(actionUpdateGlobalLoaderSagaAction(true));
  try {
    const payload = action.payload.apiPayloadRequest;
    const response = yield call(api.bibleSummary, payload);
    console.log(response, "response");
    action.payload.callback(response);
  } catch (err) {
    if (err?.response) {
      console.log(err?.response?.data?.errors[0]?.message);
    } else {
      console.log("Something Wrong!", err?.message);
    }
  } finally {
    yield put(actionUpdateGlobalLoaderSagaAction(false));
  }
}

export default function* BibleWatcherSaga() {
  yield takeLatest(actionTypes.GET_BIBLE_LANGUAGES_REQUEST, getBibleLanguagesSaga);
  yield takeLatest(actionTypes.GET_BIBLE_COUNTRY_LANGUAGES_REQUEST, getBibleCountryLanguagesSaga);
  yield takeEvery(actionTypes.GET_BIBLE_VERSIONS_REQUEST, getBibleVersionsSaga);
  yield takeEvery(actionTypes.GET_VERSION_BOOKS_REQUEST, getVersionBooksSaga);
  yield takeEvery(actionTypes.GET_BIBLE_CHAPTER_DETAILS, getChapterDetailsSaga);
  yield takeLatest(actionTypes.GET_BIBLE_SEARCH, getBibleSearchSaga);
  yield takeLatest(actionTypes.GET_BIBLE_RESOURCE_KEY, getBibleResourceKeySaga);
  yield takeLatest(actionTypes.GET_USER_JOURNALS, getUserJournalsSaga);
  yield takeLatest(actionTypes.DELETE_USER_JOURNALS, deleteUserJournalsSaga);
  yield takeEvery(actionTypes.GET_VERSE_DETAILS, getVerseDetailsSaga);
  yield takeEvery(actionTypes.GET_VERSE_COLOR, getVerseColorSaga);
  yield takeLatest(actionTypes.SAVE_VERSE_COLOR, saveVerseColorSaga);
  yield takeLatest(actionTypes.UPDATE_VERSE_COLOR, updateVerseColorSaga);
  yield takeLatest(actionTypes.DELETE_VERSE_COLOR, deleteVerseColorSaga);
  yield takeEvery(actionTypes.GET_CHAPTER_VIDEOS, getChapterVideosSaga);
  yield takeEvery(actionTypes.GET_CHAPTER_JOURNALS, getChapterJournalsSaga);
  yield takeLatest(actionTypes.GET_JOURNALS, getJournalsSaga);
  yield takeLatest(actionTypes.UPDATE_USER_STREAK, updateUserStreakSaga);
  yield takeEvery(actionTypes.GET_USER_STREAK_SUMMARY, getUserStreakSummarySaga);
  yield takeLatest(actionTypes.GET_JOURNAL_SPEAKERS, getJournalSpeakersSaga);
  yield takeLatest(actionTypes.GET_JOURNALS_BY_SPEAKER, getJournalsBySpeakerSaga);
  yield takeLatest(actionTypes.GET_JOURNALS_BY_CATEGORY, getJournalsByCategorySaga);
  yield takeLatest(actionTypes.GET_BIBLE_SUMMARY, getBibleSummarySaga);

  yield takeLatest(actionTypes.SAVE_JOURNAL_REQUEST, saveJournalSaga);
  yield takeLatest(actionTypes.UPDATE_JOURNAL_REQUEST, updateJournalSaga);
  yield takeLatest(actionTypes.DELETE_JOURNAL_REQUEST, deleteJournalSaga);

  yield takeLatest(actionTypes.ADD_JOURNAL_NOTE_REQUEST, addJournalNoteSaga);
  yield takeLatest(actionTypes.UPDATE_JOURNAL_NOTE_REQUEST, updateJournalNoteSaga);
  yield takeLatest(actionTypes.DELETE_JOURNAL_NOTE_REQUEST, deleteJournalNoteSaga);

  yield takeEvery(actionTypes.ADD_JOURNAL_NOTE_ITEM_REQUEST, addJournalNoteItemSaga);
  yield takeEvery(actionTypes.UPDATE_JOURNAL_NOTE_ITEM_REQUEST, updateJournalNoteItemSaga);
  yield takeEvery(actionTypes.DELETE_JOURNAL_NOTE_ITEM_REQUEST, deleteJournalNoteItemSaga);
}
