import { useEffect, useRef, useState } from "react";

import AudioRecorderModal from "./AudioRecorderModal";

import { FaMicrophone } from "react-icons/fa6";
import { PiWaveformBold } from "react-icons/pi";
import { LuGalleryVerticalEnd } from "react-icons/lu";

const UploadAudioButton = ({ handleAudioUpload, handleAudioCapture }) => {
  const [isActive, setIsActive] = useState(false);
  const [audioModalOpen, setAudioModalOpen] = useState(false);

  const containerRef = useRef();
  const audioInputRef = useRef();

  useEffect(() => {
    const eventHandler = (e) => {
      if (containerRef?.current && !containerRef?.current?.contains(e.target)) {
        setIsActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <>
      <div className="relative">
        <button
          className="px-4 py-2 rounded-xl border border-white/30 text-white"
          onClick={() => setIsActive(true)}
        >
          <PiWaveformBold className="text-xl" />
        </button>
        <input
          ref={audioInputRef}
          className="hidden"
          type="file"
          accept="audio/*"
          multiple={true}
          onChange={handleAudioUpload}
        />
        <ul
          className={`${
            isActive ? "flex flex-col gap-1" : "hidden"
          } absolute -top-2 -translate-y-full text-xs transition-all duration-300 z-50`}
          ref={containerRef}
          onClick={() => setIsActive(false)}
        >
          <li
            onClick={() => audioInputRef.current.click()}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <LuGalleryVerticalEnd className="text-base" />
            Select from Files
          </li>
          <li
            onClick={() => setAudioModalOpen(true)}
            className="flex items-center gap-2 bg-yellowish text-blackishV2 px-2.5 py-2 w-fit rounded-md whitespace-nowrap cursor-pointer"
          >
            <FaMicrophone className="text-base" />
            Record Audio
          </li>
        </ul>
      </div>

      <AudioRecorderModal
        isOpen={audioModalOpen}
        onClose={setAudioModalOpen}
        onSave={handleAudioCapture}
      />
    </>
  );
};

export default UploadAudioButton;
