import React, { useState, useRef, useEffect } from "react";
import { BsSoundwave } from "react-icons/bs";
import { FaPause, FaPlay } from "react-icons/fa6";

const AudioCard = ({ url, wrapperClassName = "" }) => {
  const [audioDuration, setAudioDuration] = useState(null);
  const [isPlaying, setIsPlaying] = useState(false);
  const audioRef = useRef(null);

  // Format duration in MM:SS format
  const formatDuration = (seconds) => {
    const mins = Math.floor(seconds / 60);
    const secs = Math.floor(seconds % 60);
    return `${mins}:${secs.toString().padStart(2, "0")}`;
  };

  // Play and pause functionality
  const handlePlayPause = () => {
    const audio = audioRef.current;
    if (audio) {
      if (isPlaying) {
        audio.pause();
      } else {
        audioRef.current.currentTime = 0;
        console.log("audioRef.current", audioRef.current, audioRef.current.duration);
        audio.play();
      }
      setIsPlaying(!isPlaying);
    }
  };

  return (
    <div className={`flex items-center gap-1 rounded-full border text-greyish border-greyish w-fit px-3 py-1 ${wrapperClassName}`}>
      <BsSoundwave className="text-2xl" />
      <span className="text-sm">
        {audioDuration ? formatDuration(audioDuration) : "Loading..."}
      </span>
      <button
        className="text-yellowish text-xl"
        onClick={handlePlayPause}
        title={isPlaying ? "Pause" : "Play"}
      >
        {isPlaying ? <FaPause /> : <FaPlay />}
      </button>
      <audio
        ref={audioRef}
        src={url}
        onLoadedMetadata={(e) => {
          audioRef.current.currentTime = Number.MAX_SAFE_INTEGER;
          setTimeout(() => {
            setAudioDuration(audioRef.current?.duration);
            audioRef.current.currentTime = 0;
          }, 1000);
        }}
        onPause={() => setIsPlaying(false)}
      />
    </div>
  );
};

export default AudioCard;
