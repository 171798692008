import * as actionTypes from "../ActionTypes";

export const getBibleLanguagesAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_LANGUAGES_REQUEST,
    payload: payload,
  };
};

export const getBibleCountryLanguagesAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_COUNTRY_LANGUAGES_REQUEST,
    payload: payload,
  };
};

export const getBibleVersionsAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_VERSIONS_REQUEST,
    payload: payload,
  };
};

export const setBibleParallelModeAction = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_PARALLEL_MODE,
    payload: payload,
  };
};

export const setBibleCountryLanguagesAction = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_COUNTRY_LANGUAGES,
    payload: payload,
  };
};

export const setBibleLanguageAction = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_LANGUAGE,
    payload: payload,
  };
};

export const setBibleVersionAction = (payload) => {
  return {
    type: actionTypes.SET_BIBLE_VERSION,
    payload: payload,
  };
};

export const setVersionDetailsAction = (payload) => {
  return {
    type: actionTypes.SET_VERSION_DETAILS,
    payload: payload,
  };
};

export const setBookChapterDetailsAction = (payload) => {
  return {
    type: actionTypes.SET_BOOK_CHAPTER_DETAILS,
    payload: payload,
  };
};

export const setParallelVersionDetailsAction = (payload) => {
  return {
    type: actionTypes.SET_PARALLEL_VERSION_DETAILS,
    payload: payload,
  };
};

export const setBookChapterDetailsLoadingAction = (payload) => {
  return {
    type: actionTypes.SET_BOOK_CHAPTER_DETAILS_LOADING,
    payload: payload,
  };
};

export const setParallelBookChapterDetailsAction = (payload) => {
  return {
    type: actionTypes.SET_PARALLEL_BOOK_CHAPTER_DETAILS,
    payload: payload,
  };
};

export const setParallelBookChapterDetailsLoadingAction = (payload) => {
  return {
    type: actionTypes.SET_PARALLEL_BOOK_CHAPTER_DETAILS_LOADING,
    payload: payload,
  };
};

export const setUserJournalsAction = (payload) => {
  return {
    type: actionTypes.SET_USER_JOURNALS,
    payload: payload,
  };
};

export const getVersionBooksAction = (payload) => {
  return {
    type: actionTypes.GET_VERSION_BOOKS_REQUEST,
    payload: payload,
  };
};

export const getChapterDetailsAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_CHAPTER_DETAILS,
    payload: payload,
  };
};

export const getBibleSearchAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_SEARCH,
    payload: payload,
  };
};

export const getBibleResourceKeyAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_RESOURCE_KEY,
    payload: payload,
  };
};

export const getUserJournalsAction = (payload) => {
  return {
    type: actionTypes.GET_USER_JOURNALS,
    payload: payload,
  };
};

export const deleteUserJournalsAction = (payload) => {
  return {
    type: actionTypes.DELETE_USER_JOURNALS,
    payload: payload,
  };
};

export const getVerseDetailsAction = (payload) => {
  return {
    type: actionTypes.GET_VERSE_DETAILS,
    payload: payload,
  };
};

export const getVerseColorAction = (payload) => {
  return {
    type: actionTypes.GET_VERSE_COLOR,
    payload: payload,
  };
};

export const saveVerseColorAction = (payload) => {
  return {
    type: actionTypes.SAVE_VERSE_COLOR,
    payload: payload,
  };
};

export const updateVerseColorAction = (payload) => {
  return {
    type: actionTypes.UPDATE_VERSE_COLOR,
    payload: payload,
  };
};

export const deleteVerseColorAction = (payload) => {
  return {
    type: actionTypes.DELETE_VERSE_COLOR,
    payload: payload,
  };
};

export const getChapterVideosAction = (payload) => {
  return {
    type: actionTypes.GET_CHAPTER_VIDEOS,
    payload: payload,
  };
};

export const getChapterJournalsAction = (payload) => {
  return {
    type: actionTypes.GET_CHAPTER_JOURNALS,
    payload: payload,
  };
};

export const getJournalsAction = (payload) => {
  return {
    type: actionTypes.GET_JOURNALS,
    payload: payload,
  };
};

export const updateUserStreakAction = (payload) => {
  return {
    type: actionTypes.UPDATE_USER_STREAK,
    payload: payload,
  };
};

export const getUserStreakSummaryAction = (payload) => {
  return {
    type: actionTypes.GET_USER_STREAK_SUMMARY,
    payload: payload,
  };
};

export const getJournalSpeakersAction = (payload) => {
  return {
    type: actionTypes.GET_JOURNAL_SPEAKERS,
    payload: payload,
  };
};

export const getJournalsBySpeakerAction = (payload) => {
  return {
    type: actionTypes.GET_JOURNALS_BY_SPEAKER,
    payload: payload,
  };
};

export const getJournalsByCategoryAction = (payload) => {
  return {
    type: actionTypes.GET_JOURNALS_BY_CATEGORY,
    payload: payload,
  };
};

export const saveJournalAction = (payload) => {
  return {
    type: actionTypes.SAVE_JOURNAL_REQUEST,
    payload: payload,
  };
};

export const updateJournalAction = (payload) => {
  return {
    type: actionTypes.UPDATE_JOURNAL_REQUEST,
    payload: payload,
  };
};

export const deleteJournalAction = (payload) => {
  return {
    type: actionTypes.DELETE_JOURNAL_REQUEST,
    payload: payload,
  };
};

export const addJournalNoteAction = (payload) => {
  return {
    type: actionTypes.ADD_JOURNAL_NOTE_REQUEST,
    payload: payload,
  };
};

export const updateJournalNoteAction = (payload) => {
  return {
    type: actionTypes.UPDATE_JOURNAL_NOTE_REQUEST,
    payload: payload,
  };
};

export const deleteJournalNoteAction = (payload) => {
  return {
    type: actionTypes.DELETE_JOURNAL_NOTE_REQUEST,
    payload: payload,
  };
};

export const addJournalNoteItemAction = (payload) => {
  return {
    type: actionTypes.ADD_JOURNAL_NOTE_ITEM_REQUEST,
    payload: payload,
  };
};

export const updateJournalNoteItemAction = (payload) => {
  return {
    type: actionTypes.UPDATE_JOURNAL_NOTE_ITEM_REQUEST,
    payload: payload,
  };
};

export const deleteJournalNoteItemAction = (payload) => {
  return {
    type: actionTypes.DELETE_JOURNAL_NOTE_ITEM_REQUEST,
    payload: payload,
  };
};

export const setActiveSearchTabAction = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_SEARCH_TAB,
    payload: payload,
  };
};

export const setActiveParallelSearchTabAction = (payload) => {
  return {
    type: actionTypes.SET_ACTIVE_PARALLEL_SEARCH_TAB,
    payload: payload,
  };
};

export const setAllBibleVersionsAction = (payload) => {
  return {
    type: actionTypes.SET_ALL_BIBLE_VERSIONS,
    payload: payload,
  };
};

export const setAllBibleLanguagesAction = (payload) => {
  return {
    type: actionTypes.SET_ALL_BIBLE_LANGUAGES,
    payload: payload,
  };
};

export const getBibleSummaryAction = (payload) => {
  return {
    type: actionTypes.GET_BIBLE_SUMMARY,
    payload: payload,
  };
};
