import { useEffect, useState } from "react";

import Authors from "./Authors";
import Categories from "./Categories";
import JournalsList from "./JournalsList";

import {
  getJournalsAction,
  getJournalsByCategoryAction,
  getJournalSpeakersAction,
} from "../../store/Bible/BibleAction";

import { useDispatch, useSelector } from "react-redux";

const TWGJournals = () => {
  const dispatch = useDispatch();

  const { isMobile } = useSelector((state) => state.common);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [activeBtn, setActiveBtn] = useState(0);
  const [journals, setJournals] = useState([]);
  const [journalsLoading, setJournalsLoading] = useState(false);
  const [authors, setAuthors] = useState([]);
  const [authorsLoading, setAuthorsLoading] = useState(false);
  const [categories, setCategories] = useState([]);
  const [categoriesLoading, setCategoriesLoading] = useState(false);

  const fetchJournals = () => {
    setJournalsLoading(true);

    dispatch(
      getJournalsAction({
        apiPayloadRequest: { public: "true" },
        callback: (response) => {
          setJournals(response?.data);
          setJournalsLoading(false);
        },
      })
    );
  };

  const fetchSpeakers = () => {
    setAuthorsLoading(true);

    dispatch(
      getJournalSpeakersAction({
        callback: (response) => {
          setAuthors(response?.data);
          setAuthorsLoading(false);
        },
      })
    );
  };

  const fetchCategories = () => {
    setCategoriesLoading(true);

    dispatch(
      getJournalsByCategoryAction({
        callback: (response) => {
          setCategories(response?.data);
          setCategoriesLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchJournals();
    fetchSpeakers();
    fetchCategories();
  }, []);

  return (
    <div className="w-full flex flex-col bg-black flex-1 overflow-y-auto">
      <div className="w-full flex items-center p-4 md:p-8">
        <div className="w-full flex items-center flex-wrap gap-2 md:gap-8">
          <button
            className={`border rounded-full px-4 md:px-8 py-1 ${
              activeBtn === 0 ? "text-yellowish border-yellowish" : "text-greyish border-greyish"
            }`}
            onClick={() => setActiveBtn(0)}
          >
            {currentLanguageTitles?.[0]?.bible?.journals_lbl ?? "Journals"}
          </button>
          <button
            className={`border rounded-full px-4 md:px-8 py-1 ${
              activeBtn === 1 ? "text-yellowish border-yellowish" : "text-greyish border-greyish"
            }`}
            onClick={() => setActiveBtn(1)}
          >
            {currentLanguageTitles?.[0]?.bible?.journal_categories_lbl ?? "Categories"}
          </button>
          <button
            className={`border rounded-full px-4 md:px-8 py-1 ${
              activeBtn === 2 ? "text-yellowish border-yellowish" : "text-greyish border-greyish"
            }`}
            onClick={() => setActiveBtn(2)}
          >
            {currentLanguageTitles?.[0]?.bible?.journal_authors_lbl ?? "Authors"}
          </button>
        </div>
        {/* <div
          className={`min-w-[600px] flex items-center  bg-greyishV2 text-greyish gap-3 py-2 px-3 rounded-md`}
        >
          <LuSearch className="text-2xl text-greyish" />
          <input
            type="text"
            value={searchTxt}
            placeholder={"Search the Bible"}
            onChange={(e) => setSearchTxt(e.target.value)}
            className="w-full  bg-greyishV2 text-greyish outline-none text-lg"
          />
        </div> */}
      </div>
      <div
        className={`w-full p-5 md:p-8 ${activeBtn === 1 ? "pt-0" : "pt-4"}`}
        style={{
          gridAutoFlow: "row dense",
        }}
      >
        {activeBtn === 0 && (
          <>
            {journalsLoading ? (
              <div className={`grid gap-3 ${isMobile ? "grid-cols-2" : "grid-cols-8"}`}>
                <div className="flex flex-col gap-3">
                  <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                  <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                </div>
                <div className="flex flex-col gap-3">
                  <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                  <div className="animate-pulse bg-zinc-600 aspect-[14/16] rounded-3xl rounded-se-none"></div>
                  <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                </div>
                {!isMobile && (
                  <>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[14/16] rounded-3xl rounded-se-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[14/16] rounded-3xl rounded-se-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[9/12] rounded-3xl rounded-ss-none"></div>
                    </div>
                    <div className="flex flex-col gap-3">
                      <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[14/16] rounded-3xl rounded-se-none"></div>
                      <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <JournalsList gridItems={isMobile ? 2 : 8} data={journals} />
            )}
          </>
        )}
        {activeBtn === 1 && (
          <>
            {categoriesLoading ? (
              <div className="w-full flex flex-col gap-12">
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-16 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  </div>
                  <div className={`grid gap-4 w-full ${isMobile ? "grid-cols-2" : "grid-cols-6"}`}>
                    <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                    {!isMobile && (
                      <>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </>
                    )}
                  </div>
                </div>
                <div className="flex flex-col gap-4">
                  <div className="flex items-center justify-between">
                    <div className="w-2/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-16 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  </div>
                  <div className={`grid gap-4 w-full ${isMobile ? "grid-cols-2" : "grid-cols-6"}`}>
                    <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                    {!isMobile && (
                      <>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="aspect-video animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </>
                    )}
                  </div>
                </div>
              </div>
            ) : (
              <Categories data={categories} />
            )}
          </>
        )}
        {activeBtn === 2 && (
          <>
            {authorsLoading ? (
              <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-10">
                <div className="flex gap-4">
                  <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                  <div className="flex flex-col gap-2 flex-1">
                    <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                  <div className="flex flex-col gap-2 flex-1">
                    <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  </div>
                </div>
                <div className="flex gap-4">
                  <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                  <div className="flex flex-col gap-2 flex-1">
                    <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                    <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  </div>
                </div>
                {!isMobile && (
                  <>
                    <div className="flex gap-4">
                      <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                      <div className="flex flex-col gap-2 flex-1">
                        <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                      <div className="flex flex-col gap-2 flex-1">
                        <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </div>
                    </div>
                    <div className="flex gap-4">
                      <div className="animate-pulse bg-zinc-600 rounded-2xl h-36 aspect-square"></div>
                      <div className="flex flex-col gap-2 flex-1">
                        <div className="w-full h-8 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-8/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                        <div className="w-4/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                      </div>
                    </div>
                  </>
                )}
              </div>
            ) : (
              <Authors data={authors} />
            )}
          </>
        )}
      </div>
    </div>
  );
};

export default TWGJournals;
