import { useNavigate } from "react-router-dom";

import { FaBookmark, FaShareAlt } from "react-icons/fa";
import { copyToClipboard } from "../../utils/Utils";
import Toastiy from "../../components/common/Toastiy";

const JournalsList = ({ gridItems = 8, data = [], chapter = false }) => {
  const navigate = useNavigate();

  const renderColumn = (columnIndex) => {
    const elements = [];
    for (let i = columnIndex; i < data.length; i = i + gridItems) {
      const thumbnail = data?.[i]?.bg_Images?.find((d) => d?.type_of_url === "1");
      if (i % 2 === 0) {
        elements.push(
          <div
            className="bg-gradient-to-b from-black/0 to-black/80 flex flex-col gap-16 justify-between p-5 rounded-3xl rounded-ss-none bg-cover bg-top aspect-square cursor-pointer w-full"
            style={{
              backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,0,0,0.8)),url(${thumbnail?.url_link})`,
            }}
            onClick={() =>
              navigate(`/journals/${chapter ? data?.[i]?.journalObjId : data?.[i]?._id}`)
            }
          >
            <div className="text-white flex gap-3 items-center">
              <FaShareAlt
                className="cursor-pointer"
                onClick={() => {
                  copyToClipboard(
                    `https://todaywithgod.org/journals/${
                      chapter ? data?.[i]?.journalObjId : data?.[i]?._id
                    }`
                  );
                  Toastiy("Copied to clipboard!", 1);
                }}
              />
            </div>
            <div className="flex flex-col">
              <h3 className="text-white font-semibold text-xl md:text-2xl leading-tight md:leading-7">
                {data[i]?.journal_title}
              </h3>
              <span className="text-white pb-2">{data[i]?.author}</span>
              <p className="text-greyish leading-5 text-sm overflow-ellipsis line-clamp-3">
                {data[i]?.journal_description}
              </p>
            </div>
          </div>
        );
      } else {
        if (elements.length % 3 === 0) {
          elements.push(
            <div
              className="bg-gradient-to-b from-black/0 to-black/80 flex flex-col gap-16 justify-between p-5 rounded-3xl rounded-tr-none bg-cover bg-top aspect-square cursor-pointer w-full"
              style={{
                backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,0,0,0.8)),url(${thumbnail?.url_link})`,
              }}
              onClick={() =>
                navigate(`/journals/${chapter ? data?.[i]?.journalObjId : data?.[i]?._id}`)
              }
            >
              <div className="text-white flex gap-3 items-center">
                <FaShareAlt
                  className="cursor-pointer"
                  onClick={() => {
                    copyToClipboard(
                      `https://todaywithgod.org/journals/${
                        chapter ? data?.[i]?.journalObjId : data?.[i]?._id
                      }`
                    );
                    Toastiy("Copied to clipboard!", 1);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <h3 className="text-white font-semibold text-xl md:text-2xl leading-tight md:leading-7">
                  {data[i]?.journal_title}
                </h3>
              </div>
            </div>
          );
        } else if (elements.length % 3 === 1) {
          elements.push(
            <div
              className="bg-gradient-to-b from-black/0 to-black/80 flex flex-col gap-16 justify-between p-5 rounded-3xl rounded-tr-none bg-cover bg-top aspect-square cursor-pointer w-full"
              style={{
                backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,0,0,0.8)),url(${thumbnail?.url_link})`,
              }}
              onClick={() =>
                navigate(`/journals/${chapter ? data?.[i]?.journalObjId : data?.[i]?._id}`)
              }
            >
              <div className="text-white flex gap-3 items-center">
                <FaShareAlt
                  className="cursor-pointer"
                  onClick={() => {
                    copyToClipboard(
                      `https://todaywithgod.org/journals/${
                        chapter ? data?.[i]?.journalObjId : data?.[i]?._id
                      }`
                    );
                    Toastiy("Copied to clipboard!", 1);
                  }}
                />
              </div>
              <div className="flex flex-col">
                <h3 className="text-white font-semibold text-xl md:text-2xl leading-tight md:leading-7">
                  {data[i]?.journal_title}
                </h3>
                <span className="text-white pb-2">{data[i]?.author}</span>
                <p className="text-greyish leading-5 text-sm overflow-ellipsis line-clamp-2">
                  {data[i]?.journal_description}
                </p>
              </div>
            </div>
          );
        } else {
          elements.push(
            <div
              className="bg-gradient-to-b from-black/0 to-black/80 flex flex-col gap-16 justify-between p-5 rounded-3xl rounded-tr-none bg-cover bg-top aspect-square cursor-pointer w-full"
              style={{
                backgroundImage: `linear-gradient(to bottom,rgba(0,0,0,0.1),rgba(0,0,0,0.8)),url(${thumbnail?.url_link})`,
              }}
              onClick={() =>
                navigate(`/journals/${chapter ? data?.[i]?.journalObjId : data?.[i]?._id}`)
              }
            >
              <div className="text-white flex gap-3 items-center">
                <FaBookmark />
                <FaShareAlt />
              </div>
              <div className="flex flex-col">
                <h3 className="text-white font-semibold text-xl md:text-2xl leading-tight md:leading-7">
                  {data[i]?.journal_title}
                </h3>
                <span className="text-white pb-2">{data[i]?.author}</span>
              </div>
            </div>
          );
        }
      }
    }
    return elements;
  };

  return (
    <div className={`w-full grid grid-cols-${gridItems} gap-x-2`}>
      {Array.from({ length: gridItems }).map((_, arrIndex) => {
        return (
          <div key={arrIndex} className="flex flex-col gap-2">
            {renderColumn(arrIndex)}
          </div>
        );
      })}
    </div>
  );
};

export default JournalsList;
