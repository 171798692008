import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";

import JournalsList from "./JournalsList";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import LoaderSpinner from "../../components/common/LoaderSpinner";

import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import {
  getJournalsBySpeakerAction,
  getJournalSpeakersAction,
} from "../../store/Bible/BibleAction";

const AuthorDetails = () => {
  const dispatch = useDispatch();

  const { id } = useParams();

  const { isMobile } = useSelector((state) => state.common);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const checkToken = localStorage.getItem("token");

  const [popCancle, setPopCancel] = useState(true);
  const [activeTab, setActiveTab] = useState(0);
  const [isLoading, setIsLoading] = useState(true);
  const [journals, setJournals] = useState([]);
  const [speakerDetails, setSpeakerDetails] = useState({});

  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  const fetchJournals = () => {
    setIsLoading(true);

    dispatch(
      getJournalsBySpeakerAction({
        apiPayloadRequest: { speakerObjId: id },
        callback: (response) => {
          setJournals(response?.data);
          setIsLoading(false);
        },
      })
    );
  };

  const fetchSpeakerDetails = () => {
    dispatch(
      getJournalSpeakersAction({
        apiPayloadRequest: { _id: id },
        callback: (response) => {
          setSpeakerDetails(response?.data?.[0]);
        },
      })
    );
  };

  useEffect(() => {
    fetchJournals();
    fetchSpeakerDetails();
  }, []);

  return (
    <div className="flex flex-col md:flex-row w-full overflow-hidden">
      <Navbar active={"Journals"} />
      <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
        <HeaderBar
          popCancle={popCancle}
          cancelBtn={!popCancle}
          cancelBtnHandle={() => setPopCancel(true)}
          tabValues={[]}
          stepBack={true}
          back={true}
          backLink="/journals"
          active={activeTab}
          handleTabChange={handleTabChange}
          getLanguagePreferred={() => {}}
          getCurrentLanguageTitles={() => {}}
        />
        <h2 className="bg-black text-white text-2xl px-5">
          {currentLanguageTitles?.[0]?.bible?.journals_lbl ?? "Journals"} by{" "}
          <span className="font-semibold italic">{speakerDetails?.speakerDisplayName}</span>
        </h2>
        <div className="w-full flex flex-col bg-black flex-1 overflow-y-auto p-5 md:p-8">
          {isLoading ? (
            <div className="w-full h-[60vh] flex items-center justify-center">
              <LoaderSpinner />
            </div>
          ) : (
            <JournalsList gridItems={isMobile ? 2 : 8} data={journals} />
          )}
        </div>
      </div>
    </div>
  );
};

export default AuthorDetails;
