import axios from "axios";

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
});

instance.defaults.headers.common["Content-Type"] = "application/x-www-form-urlencoded";
let isRefreshing = false;
let failedQueue = [];

const processQueue = (error, token = null) => {
  failedQueue.forEach((prom) => {
    if (token) {
      prom.resolve(token);
    } else {
      prom.reject(error);
    }
  });
  failedQueue = [];
};

instance.interceptors.request.use(
  (request) => {
    const token = localStorage.getItem("token");
    const language = localStorage.getItem("language_preferred");
    if (token) {
      request.headers.setAuthorization("Bearer " + token);

      if (["/uploadAsset"].includes(request?.url)) {
        request.headers["Content-Type"] = "multipart/form-data";
      }
    }
    request.data = {
      mobile: false,
      ...request.data,
    };
    if (!["/language/read", "/language/list"].includes(request?.url)) {
      request.data = {
        ...request.data,
        language_code: language || "en",
      };
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Response Interceptor
instance.interceptors.response.use(
  async (response) => {
    // ✅ Custom check for expired token from API response
    if (response?.data?.name === "TokenExpiredError" || response?.data?.type === 4) {
      const originalRequest = response.config;
      originalRequest._retry = true;

      if (isRefreshing) {
        return new Promise((resolve, reject) => {
          failedQueue.push({ resolve, reject });
        })
          .then((newToken) => {
            originalRequest.headers.Authorization = `Bearer ${newToken}`;
            return axios(originalRequest);
          })
          .catch((err) => Promise.reject(err));
      }

      isRefreshing = true;

      try {
        const refreshToken = localStorage.getItem("refresh_token");
        if (!refreshToken) {
          throw new Error("Refresh token not found");
        }

        // ✅ Fetch new access token using refresh token
        const { data: refreshResponse } = await axios.post(
          `${process.env.REACT_APP_BASE_URL}generateToken`,
          {
            refresh_token: refreshToken,
            client_id: process.env.REACT_APP_AUTH0_CLIENT_ID,
          }
        );

        console.log('refreshResponse.data', refreshResponse?.data)
        
        let parsedData = JSON.parse(refreshResponse?.data);
        console.log('parsedData', parsedData)
        if (!parsedData || !parsedData?.access_token) {
          throw new Error("Invalid token response");
        }

        const newToken = parsedData?.access_token
        localStorage.setItem("token", newToken);
        localStorage.setItem("refresh_token", parsedData?.refresh_token);

        instance.defaults.headers.Authorization = `Bearer ${newToken}`;
        processQueue(null, newToken);

        // ✅ Retry the failed request with the new token
        originalRequest.headers.Authorization = `Bearer ${newToken}`;
        return axios(originalRequest);
      } catch (err) {
        processQueue(err, null);
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        window.location.pathname = "/logout";
        return Promise.reject(err);
      } finally {
        isRefreshing = false;
      }
    }

    return response; // Return normal response if token is valid
  },
  (error) => Promise.reject(error)
);

export default instance;
