import image1 from "../assets/images/image1.png";
import image2 from "../assets/images/image2.png";
import image3 from "../assets/images/image3.png";
import image4 from "../assets/images/image4.png";
import image5 from "../assets/images/image5.png";
import image6 from "../assets/images/image6.png";
import plan2 from "../assets/images/plan2.png";
import plan3 from "../assets/images/plan3.png";

import IndiaFlag from "../assets/images/flags/indiaFlag.jpg";
import AganistanFlag from "../assets/images/flags/afganistanFlag.png";
import AlbaniaFlag from "../assets/images/flags/albaniaFlag.png";
import AlgeriaFlag from "../assets/images/flags/algeriaFlag.png";
import AndorraFlag from "../assets/images/flags/andorraFlag.png";
import AngolaFlag from "../assets/images/flags/angolaFlag.jpeg";

import video1 from "../assets/images/video1.png";

export const SEARCH_TABS = { BIBLE: "Bible", LANGUAGE: "Language", COUNTRY: "Country" };
export const SEARCH_TABS_KEYS = {
  [SEARCH_TABS.BIBLE]: "bible_lbl",
  [SEARCH_TABS.LANGUAGE]: "language_lbl",
  [SEARCH_TABS.COUNTRY]: "country_lbl",
};

export const journalsList = [
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "To Me",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "To Me",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "To Me",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "To Me",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "Message Notes",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven. He helps us when we're hurting.",
  },
  {
    title: "Following God",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. He helps us when we're hurting.",
  },
  {
    title: "Message Notes",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven. He helps us when we're hurting.",
  },
  {
    title: "Following God",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. He helps us when we're hurting.",
  },
  {
    title: "Message Notes",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven. He helps us when we're hurting.",
  },
  {
    title: "Following God",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. He helps us when we're hurting.",
  },
  {
    title: "Message Notes",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven. He helps us when we're hurting.",
  },
  {
    title: "Following God",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. He helps us when we're hurting.",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "His Faithfulness",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "His Faithfulness",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "His Faithfulness",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "Promises",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "His Faithfulness",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
];
export const authorsList = [
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Rebecca Paul",
    pastor: "Pastor at Hill Church",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image2,
    author: "Johnson Paul",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image3,
    author: "Jonah Adam",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image4,
    author: "John Peter",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image5,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image2,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image3,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    image: image5,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image3,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image2,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image3,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image4,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image3,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image2,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image3,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image4,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image5,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    image: image2,
    author: "Johnson Paul",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image3,
    author: "Jonah Adam",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image4,
    author: "John Peter",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image5,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
];
export const speakerList = [
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Rebecca Paul",
    pastor: "Pastor at Hill Church",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Johnson Paul",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image1,
    author: "Jonah Adam",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "John Peter",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image1,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image1,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image1,
    author: "Donald T.Jonhson",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The faithfulness of God means God is unchanging in his nature, true to his Word, has promised salvation to his people, and will keep his promises forever. He is worthy of eternal trust no matter how unlikely his promises seem.",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    pastor: "Pastor at Hill Church",
    author: "Donald T.Jonhson",
    description:
      "Psalm 145:9 The Lord is good to all; Isaiah 26:3 You will keep in perfect peace those whose minds are steadfast, James 1:17 Every good and perfect gift is from above Psalm 34:8 Taste and see that the Lord is good",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Johnson Paul",
    pastor: "Pastor at Hill Church",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven  ",
  },
  {
    title: "The Shepherd Psalm",
    pastor: "Pastor at Hill Church",
    image: image1,
    author: "Jonah Adam",
    description:
      "The resurrection of Jesus Christ is a critical element of our faith. Jesus is alive. We are forgiven ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "John Peter",
    pastor: "Pastor at Hill Church",
    description:
      "He gives us rest. He provides direction. He gives us grace. He shows us how to escape temptation. He helps us when we're hurting. ",
  },
  {
    title: "The Shepherd Psalm",
    image: image1,
    author: "Donald T.Jonhson",
    pastor: "Pastor at Hill Church",
    description:
      "Be in prayer. A way to know that you are following God's plan for your life is by being in prayer. ",
  },
];

export const dropDownList = ["NIV", "SIP", "DIP"];
export const bibleDropDownList = [
  { title: "NIV", text: "New International Version" },
  { title: "KJV", text: "King James Version" },
  { title: "NKJV", text: "New King James Version" },
  { title: "NLT", text: "New Living Translation" },
  { title: "MSG", text: "The Message" },
  { title: "ESV", text: "English Standard Version" },
];

export const country = [
  { name: "Afganistan", flag: AganistanFlag, langauges: 18 },
  { name: "Albania", flag: AlbaniaFlag, langauges: 20 },
  { name: "Algeria", flag: AlgeriaFlag, langauges: 10 },
  { name: "Andorra", flag: AndorraFlag, langauges: 15 },
  { name: "Angola", flag: AngolaFlag, langauges: 9 },
  { name: "India", flag: IndiaFlag, langauges: 23 },
];

export const language = [
  "Hindi",
  "Bengali",
  "Telugu",
  "Marathi",
  "Tamil",
  "Gujarati",
  "Urdu",
  "Kannada",
  "Odia",
  "Malayalam",
  "Punjabi",
  "Assamese",
  "Maithili",
  "Santali",
  "Kashmiri",
  "Nepali",
  "Konkani",
  "Sindhi",
  "Dogri",
  "Manipuri",
  "Bodo",
  "Sanskrit",
  "Rajasthani",
  "Tulu",
  "Bhili",
  "Khasi",
  "Mizo",
  "Garo",
  "Marwari",
  "Angika",
];

export const verses = {
  title: "A psalm of David.",
  versesList: [
    {
      name: "The Lord is my shepherd, I lack nothing.",
      book: "Romans",
      chapter: 75,
      versesNumber: 24,
    },
    {
      name: "He makes me lie down in green pastures, he leads me beside quiet waters.",
      book: "Romans",
      chapter: 88,
      versesNumber: 24,
    },
    {
      name: "He refreshes my soul. He guides me along the right paths for his name's sake.",
      book: "John",
      chapter: 64,
      versesNumber: 29,
    },
    {
      name: "Even though I walk through the darkest valley, I will fear no evil, for you are with me; your rod and your staff, they comfort me.",
      book: "Matthew",
      chapter: 36,
      versesNumber: 35,
    },
    {
      name: "You prepare a table before me in the presence of my enemies. You anoint my head with oil; my cup overflows.",
      book: "Hebrews",
      chapter: 92,
      versesNumber: 41,
    },
    {
      name: "Surely your goodness and love will follow me all the days of my life, and I will dwell in the house of the Lord forever.",
      book: "Peter",
      chapter: 122,
      versesNumber: 55,
    },
  ],
};

export const versesColor = [
  "#CEF834",
  "#34F5F8",
  "#34F882",
  "#B734F8",
  "#345BF8",
  "#F83437",
  "#FFB6C1",
  "#FFDAB9",
  "#FFFACD",
  "#E0FFFF",
  "#F0E68C",
  "#D8BFD8",
  "#E6E6FA",
  "#F5DEB3",
  "#FAFAD2",
  "#D3D3D3",
  "#FFDEAD",
  "#FFE4E1",
  "#F0FFF0",
  "#ADD8E6",
];

export const twgJournalsCategories = [
  {
    _id: 1,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image1,
    type: "journal",
  },
  {
    _id: 2,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image2,
    type: "journal",
  },
  {
    _id: 3,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image3,
    type: "journal",
  },
  {
    _id: 4,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image4,
    type: "journal",
  },
  {
    _id: 5,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image5,
    type: "journal",
  },
  {
    _id: 6,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image1,
    type: "journal",
  },
  {
    _id: 7,
    title: "The Birth",
    name: "The Birth",
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: image2,
    type: "journal",
  },
];

export const christmasPlanArray = [
  {
    _id: 1,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 2,
    title: "Joy to The World",
    name: "Joy to The World",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 3,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 4,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 5,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 6,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 7,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 8,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 8,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 9,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
  {
    _id: 10,
    title: "The Birth",
    name: "The Birth",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan2,
    type: "journal",
  },
];

export const newToFaithPlanArray = [
  {
    _id: 1,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Done",
  },
  {
    _id: 2,
    title: "Joy to The World",
    name: "Joy to The World",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "In progress",
  },
  {
    _id: 3,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Saved",
  },
  {
    _id: 4,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Done",
  },
  {
    _id: 5,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "In progress",
  },
  {
    _id: 6,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Done",
  },
  {
    _id: 7,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Done",
  },
  {
    _id: 8,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Saved",
  },
  {
    _id: 8,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Saved",
  },
  {
    _id: 9,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Done",
  },
  {
    _id: 10,
    title: "How to study the Bible",
    name: "How to study the Bible",
    duration: "5 Days",
    rating: 5,
    subtitle:
      "The Book of John, the fourth book of the New Testament, presents a unique theological perspective on Jesus Christ, emphasizing His divinity as the eternal Word of God through miracles, I Am statements, and profound spiritual truths. We will see the raising of Lazarus, the Last Supper, and Jesus' crucifixion and resurrection, with themes of light, love, and eternal life.",
    image: plan3,
    type: "journal",
    status: "Saved",
  },
];

export const versesVideosData = [
  {
    video: video1,
    title: "Psalms 23:1",
    text: "David thought about God, the God of Israel; as he thought about his relationship with God, he made the analogy of a shepherd and his sheep. God was like a shepherd to David, and David was like a sheep to God.",
  },
  {
    video: video1,
    title: "Psalms 23:1",
    text: "David thought about God, the God of Israel; as he thought about his relationship with God, he made the analogy of a shepherd and his sheep. God was like a shepherd to David, and David was like a sheep to God.",
  },
  {
    video: video1,
    title: "Psalms 23:1",
    text: "David thought about God, the God of Israel; as he thought about his relationship with God, he made the analogy of a shepherd and his sheep. God was like a shepherd to David, and David was like a sheep to God.",
  },
  {
    video: video1,
    title: "Psalms 23:1",
    text: "David thought about God, the God of Israel; as he thought about his relationship with God, he made the analogy of a shepherd and his sheep. God was like a shepherd to David, and David was like a sheep to God.",
  },
];

export const audioVersesList = [
  { title: "The Listener’s Bible : NIV Edition.", text: "Biblica, Inc" },
  { title: "New Internaltional Version", text: "Audio Edition" },
  { title: "The Listener’s Bible : NIV Edition.", text: "Biblica, Inc" },
  { title: "New Internaltional Version", text: "Audio Edition" },
  { title: "The Listener’s Bible : NIV Edition.", text: "Biblica, Inc" },
  { title: "New Internaltional Version", text: "Audio Edition" },
  { title: "The Listener’s Bible : NIV Edition.", text: "Biblica, Inc" },
];
