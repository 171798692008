import { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";

import { MdClose } from "react-icons/md";
// import Bible from "../../assets/images/Bible.svg";
import Bible from "../../assets/images/bible.gif";

const BibleDefaultView = ({ height = 400, onClick = null }) => {
  const navigate = useNavigate();

  console.log("height", height);

  const { versionDetails } = useSelector((state) => state.bible);
  const { isMobile } = useSelector((state) => state.common);

  const sliderRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [selectedBook, setSelectedBook] = useState(null);

  console.log("currentSlide", currentSlide);
  console.log("versionBooks", versionDetails);

  const lineRefs = useRef([]); // Store references to each line

  const alignTextToLine = (index) => {
    if (!lineRefs.current[index] || !sliderRef.current?.el) return;

    const nameCarousel = sliderRef.current.el;
    const swiperWrapper = nameCarousel.querySelector(".swiper-wrapper");
    const parentRect = nameCarousel.getBoundingClientRect();
    const activeItem = swiperWrapper.children[index];

    if (!activeItem) return;

    // Get the bounding rectangle of the active item
    const activeItemRect = activeItem.getBoundingClientRect();

    // Calculate the proportional position
    const totalItems = swiperWrapper.children.length;
    const proportions = [
      3.9, 3.5, 3.1, 2.8, 2.7, 2.5, 2.1, 1.9, 1.7, 1.7, 1.7, 1.7, 1.6, 1.6, 1.4, 1.4, 1.3, 1.3, 1.2,
      1.2, 1.2, 1.2, 1.1, 1.1, 1.1, 1.1, 1.1, 1.1, 1.05, 1.05, 1.05, 1, 1, 1, 1, 1, 1, 1, 0.93,
      0.93, 0.93, 0.93, 0.93, 0.93, 0.93, 0.95, 0.95, 0.92, 0.92, 0.92, 0.92, 0.9, 0.9, 0.9, 0.9,
      0.9, 0.9, 0.9, 0.9, 0.92, 0.92, 0.93, 0.94, 0.95, 0.97, 0.96,
    ];
    const proportion = (index / (totalItems - 1)) * proportions[index]; // Get the proportional position
    const targetPosition = parentRect.width * proportion;

    // Calculate the current position of the active item
    const currentPosition = activeItemRect.left + activeItemRect.width / 2 - parentRect.left;

    // Get the current translate3d value of the swiper-wrapper
    const currentTransform = getComputedStyle(swiperWrapper).transform;
    let currentTranslateX = 0;

    if (currentTransform !== "none") {
      const matrix = new DOMMatrix(currentTransform); // Use DOMMatrix
      currentTranslateX = matrix.m41; // Extract the X translation value
    }

    // Calculate the translation needed, adjusted by the current translateX
    const offsetX = targetPosition - currentPosition + currentTranslateX;

    // Apply translation to the swiper-wrapper
    swiperWrapper.style.transform = `translate3d(${offsetX}px, 0px, 0px)`;
  };

  const handleLineClick = (index) => {
    setSelectedBook(versionDetails?.books?.[index]);
    sliderRef.current?.slideTo(index, 500);
    setCurrentSlide(index);
    alignTextToLine(index);
  };

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex); // Update the current slide index
    console.log("Slide changed to:", swiper.activeIndex);
    alignTextToLine(swiper.activeIndex);
  };

  const handleSlideClick = (index) => {
    setSelectedBook(versionDetails?.books?.[index]);
    setCurrentSlide(index);
    console.log("sliderRef.current", sliderRef.current);
    sliderRef.current?.slideTo(index, 500);
    alignTextToLine(index);
  };

  return (
    <div className="w-full pt-4 px-4 rounded-xl border-[#3B3B3B] flex items-center flex-col gap-y-0 relative">
      <div className="w-full">
        <Swiper
          grabCursor={true}
          slidesPerView={"auto"}
          spaceBetween={10}
          centeredSlides={false}
          className="h-[30px] mb-2 relative custom-arrowV2"
          modules={[Keyboard, Mousewheel, Navigation]}
          onSlideChange={handleSlideChange}
          navigation={true}
          onSwiper={(swiper) => (sliderRef.current = swiper)}
        >
          {versionDetails?.books?.map((book, index) => (
            <SwiperSlide
              key={index}
              className={`!w-fit ${
                index === currentSlide
                  ? "text-[#F8BA34] text-xl my-0 py-1"
                  : "text-[#A3A3A3] text-xs py-2"
              } `}
            >
              <p
                onClick={() => {
                  handleSlideClick(index);
                }}
              >
                {book?.name}
              </p>
            </SwiperSlide>
          ))}
        </Swiper>
      </div>
      <div
        className="w-full relative overflow-hidden flex justify-center items-start z-20"
        style={{ height: `${height}px` }}
      >
        {Array.from({ length: 66 }).map((_, index) => {
          // Spread the lines over 120 degrees from -60deg to +60deg
          const angle = (isMobile ? -30 : -58) + (index * 116) / (isMobile ? 130 : 65);
          return (
            <div
              key={index}
              className={`absolute bottom-0 left-1/2 h-[180%] md:h-[200%] hover:w-[2px] cursor-pointer ${
                index === currentSlide ? "bg-yellowish w-[1.3px]" : "bg-greyish w-[1px]"
              }`}
              style={{
                transform: `rotate(${angle}deg)`,
                transformOrigin: "bottom center",
              }}
              onMouseOver={() => {
                sliderRef.current?.slideTo(index, 500);
                setCurrentSlide(index);
                alignTextToLine(index);
              }}
              onClick={() => handleLineClick(index)}
              ref={(el) => (lineRefs.current[index] = el)}
            />
          );
        })}
      </div>

      <img
        className="h-24 -translate-y-12 -translate-x-0.5 relative z-10"
        src={Bible}
        alt="Bible"
      />
      {selectedBook?.book_id && (
        <div className="w-[85%] md:w-[40%] bg-black absolute h-[250px] md:h-[300px] z-40 top-1/2 -translate-y-1/2 px-4 md:px-8 py-4 md:py-7 overflow-hidden">
          <MdClose
            className="absolute top-2 right-2 text-greyish text-lg cursor-pointer"
            onClick={() => setSelectedBook(null)}
          />
          <div className="w-full grid grid-cols-5 md:grid-cols-8 gap-2 max-h-full overflow-y-auto custom-scrollbar pr-2 py-1">
            {selectedBook?.chapters.map((chapterNo, index) => {
              return (
                <div
                  key={index}
                  onClick={() => {
                    if (onClick) onClick();
                    setSelectedBook({});
                    navigate(
                      `/chapter/${versionDetails?.abbr}/${selectedBook?.book_id}/${chapterNo}`
                    );
                  }}
                  className="text-center border border-greyishV2 flex hover:bg-yellowish hover:text-black items-center justify-center cursor-pointer rounded aspect-square text-greyish"
                >
                  {chapterNo}
                </div>
              );
            })}
          </div>
        </div>
      )}
    </div>
  );
};

export default BibleDefaultView;
