import { useAuth0 } from "@auth0/auth0-react";
import { useEffect } from "react";

const SilentAuth = () => {
  const { getAccessTokenSilently, isAuthenticated } = useAuth0();

  useEffect(() => {
    if (isAuthenticated) {
      const refresh = async () => {
        try {
          const token = await getAccessTokenSilently();
          localStorage.setItem("token", token);
          console.log("Silent authentication successful");
        } catch (error) {
          console.error("Silent authentication failed", error);
        }
      };

      // Refresh every 23 minutes
      const interval = setInterval(refresh, 23 * 60 * 60 * 1000);
      return () => clearInterval(interval);
    }
  }, [isAuthenticated, getAccessTokenSilently]);

  return null;
};

export default SilentAuth;
