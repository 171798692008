import { useSelector } from "react-redux";
import ReactModal from "react-modal";

import { MdCancel } from "react-icons/md";

import HoverJournals from "../../assets/icons/NavIcons/hoverJournals.svg";
import PlusButtonIcon from "../../assets/icons/plusButtonIcon.svg";

const VersesModel = ({
  active,
  setActive,
  isNotesActive,
  setIsNotesActive,
  isNotesPopupActive,
  setIsNewNotesActive,
  setIsNotesPopupActive,
  gridItems = 4,
  setExistingJournalId,
}) => {
  const { userJournals } = useSelector((state) => state.bible);

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const renderColumn = (columnIndex) => {
    const elements = [];
    for (let i = columnIndex; i < userJournals.length; i = i + gridItems) {
      if (i % 2 === 0) {
        elements.push(
          <div
            className={`bg-yellowish shadow-md p-4 w-full flex flex-col cursor-pointer ${
              elements.length % 3 === 0 && "rounded-br-3xl rounded-bl-3xl rounded-tr-3xl"
            } ${elements.length % 3 === 1 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"} ${
              elements.length % 3 === 2 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"
            }`}
            style={{
              gridRow: `span ${Math.ceil(
                (userJournals?.[i]?.journal_description?.length || 1) / 50
              )}`, // Dynamically adjust height
            }}
            onClick={() => {
              setIsNotesActive(true);
              setIsNewNotesActive(false);
              setExistingJournalId(userJournals?.[i]?._id);
            }}
          >
            <h4 className="text-lg font-semibold mb-2">{userJournals?.[i]?.journal_title}</h4>
            <p
              className={`text-xs text-greyishV2 overflow-ellipsis overflow-hidden flex-1 ${
                elements.length % 3 === 0 && "line-clamp-[12]"
              } ${elements.length % 3 === 1 && "line-clamp-6"} ${
                elements.length % 3 === 2 && "line-clamp-4"
              }`}
            >
              {userJournals?.[i]?.description}
            </p>
          </div>
        );
      } else {
        elements.push(
          <div
            className={`bg-yellowish shadow-md p-4 w-full flex flex-col cursor-pointer ${
              elements.length % 3 === 0 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"
            }  ${elements.length % 3 === 1 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"}  ${
              elements.length % 3 === 2 && "rounded-br-3xl rounded-bl-3xl rounded-tr-3xl"
            }`}
            style={{
              gridRow: `span ${Math.ceil(
                (userJournals?.[i]?.journal_description?.length || 1) / 50
              )}`, // Dynamically adjust height
            }}
            onClick={() => {
              setIsNotesActive(true);
              setIsNewNotesActive(false);
              setExistingJournalId(userJournals?.[i]?._id);
            }}
          >
            <h4 className="text-lg font-semibold mb-2">{userJournals?.[i]?.journal_title}</h4>
            <p
              className={`text-xs text-greyishV2 overflow-ellipsis overflow-hidden flex-1 ${
                elements.length % 3 === 0 && "line-clamp-2"
              } ${elements.length % 3 === 1 && "line-clamp-6"} ${
                elements.length % 3 === 2 && "line-clamp-4"
              }`}
            >
              {userJournals?.[i]?.journal_description}
            </p>
          </div>
        );
      }
    }
    return elements;
  };
  return (
    <>
      <ReactModal
        isOpen={isNotesPopupActive}
        className={"overflow-hidden"}
        onRequestClose={() => setIsNotesPopupActive(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          <button
            className="absolute top-24 md:top-5 right-5"
            onClick={() => setIsNotesPopupActive(false)}
          >
            <MdCancel color="#EFBC51" size={30} />
          </button>
          <div className="rounded-2xl bg-black h-[80%] w-[90%] md:w-[60%] flex flex-col px-4 md:px-8 py-3 md:py-5 ">
            <div
              className="w-full flex items-center gap-2 py-3 cursor-pointer"
              onClick={() => {
                setIsNotesActive(true);
                setIsNewNotesActive(true);
                setIsNotesPopupActive(false);
                setExistingJournalId(null);
              }}
            >
              <img src={HoverJournals} alt="HoverJournals" />
              <p className="text-yellowish font-semibold text-lg">
                {currentLanguageTitles?.[0]?.bible?.new_journal_lbl ?? "New journal"}
              </p>
            </div>
            <div className="w-full border-b border-greyishV2"></div>
            <div className="w-full flex items-center gap-2 py-3 cursor-pointer">
              <img src={PlusButtonIcon} alt="PlusButtonIcon" />
              <p className="text-yellowish font-semibold text-lg">
                {currentLanguageTitles?.[0]?.bible?.existing_journal_lbl ??
                  "Add to existing journal"}
              </p>
            </div>
            <div className="w-full h-[56vh] overflow-y-auto custom-scrollbar">
              {userJournals?.length === 0 ? (
                <div className="w-full h-[40vh] flex items-center justify-center text-greyish">
                  {currentLanguageTitles?.[0]?.bible?.no_journals_available_lbl ??
                    "No Journals Available"}
                </div>
              ) : (
                <div className={`w-full grid grid-cols-${gridItems} gap-x-2`}>
                  {Array.from({ length: gridItems }).map((_, arrIndex) => {
                    return (
                      <div key={arrIndex} className="flex flex-col gap-2">
                        {renderColumn(arrIndex)}
                      </div>
                    );
                  })}
                </div>
              )}
            </div>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default VersesModel;
