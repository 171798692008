import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Carousel } from "react-responsive-carousel";
import { Link, useNavigate } from "react-router-dom";
import { MdCancel } from "react-icons/md";

import Like from "../../assets/icons/NavIcons/hoverfav.svg";
import verseOfTheDayImg from "../../assets/images/verseOfTheDay.png";
import { PiPlayCircleFill } from "react-icons/pi";
import { FaPlay } from "react-icons/fa";
import { GoMute, GoUnmute } from "react-icons/go";
import ReactPlayer from "react-player";
import { useDispatch, useSelector } from "react-redux";
import { setHoveredActiveItem } from "../../store/Home/HomeAction";
import Hls from "hls.js";
import { MOBILE_DESCRIPTION_LENGTH } from "../../constants/CommonContants";
import StarRating from "../common/ToggleStar";

const CommonCarousel = ({
  _id = "",
  showLikes,
  imageType,
  data,
  perViewImages,
  showProgress,
  title,
  showTitle = true,
  seeAll = true,
  showItemTitle = false,
  linkTo = "",
  dynamiclinkTo = false,
  playVideoContained = false,
  seeAllLink,
  canDelete = false,
  seeAllValue = "See All",
  seeAllRoute = null,
  Backto = "Home",
  hoverCardEffect = true,
  isPromise = false,
  isPlan = false,
  planButtonText,
  testimonialClickHandler = null,
  promiseClickHandler = () => {},
  handleDelete = () => {},
  onPlanStart = () => {},
}) => {
  const audioRefs = useRef([]);
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { hoveredActiveItem } = useSelector((state) => state.languageReducer.data);

  const totalCount = data?.length;
  const numberOfLists = Math.ceil(totalCount / perViewImages);
  const mywidth = 100 / perViewImages;
  const widthOfImage = mywidth - (0.2 + perViewImages / 10);

  const [cardVisible, setCardVisible] = useState(false);
  const [playState, setPlayingState] = useState([]);

  const carouselId = useMemo(() => {
    return new Date().getTime() * Math.random();
  }, []);

  console.log("hoveredActiveItem", hoveredActiveItem);

  useEffect(() => {
    if (hoveredActiveItem) {
      setCardVisible(true);
    }

    return () => setCardVisible(false);
  }, [hoveredActiveItem]);

  const handleSeeAll = (title, data) => {
    navigate(`/${seeAllRoute ?? "seeAll"}`, {
      state: {
        title: title,
        data: data,
        Backto: Backto,
        imageType: Backto === "Testimonials" ? "portrait" : imageType,
      },
    });
  };

  const handlePlaying = useCallback(
    (index) => {
      if (playState.length > 0) {
        const update = [...playState];
        update[index].playing = !update[index].playing;

        if (update[index].playing) {
          audioRefs.current[index].current.play();
        } else {
          audioRefs.current[index].current.pause();
        }

        setPlayingState(update);
      }
    },
    [playState]
  );

  const handleNavigate = useCallback(
    (item, index) => {
      const itemType = dynamiclinkTo ? item?.type : linkTo;
      if (testimonialClickHandler) {
        testimonialClickHandler(item);
        return;
      }
      if ((itemType === "videos" || itemType === "curriculum") && !dynamiclinkTo) {
        navigate("/video-player", {
          state: {
            url: item?.url,
            title: item?.title,
            _id: item?._id,
            category: itemType,
            image: item?.image,
            download: true,
          },
        });
      } else if (itemType === "video" || itemType === "videos") {
        navigate(`/videoInfo/${item._id}`, { state: { _id: item?._id } });
      } else if (itemType === "category") {
        if (seeAllRoute) {
          navigate(`/${seeAllRoute}`, { state: { videoObjId: item?._id } });
        } else {
          navigate(`/categoryInfo/${item._id}`, { state: { _id: item?._id } });
        }
      } else if (itemType === "testimonial") {
        navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
      } else if (itemType === "curriculum") {
        navigate(`/curriculum-details/${item._id}`, {
          state: { _id: item?._id },
        });
      } else if (itemType === "lesson") {
        navigate(`/curriculum-details/${_id}`, {
          state: { _id: _id, lessonObjId: item?._id },
        });
      } else if (itemType === "journal") {
        navigate(`/journals/${item?._id}`, { state: { _id: item?._id } });
      } else if (itemType === "chapter") {
        navigate(`/chapter/${item?.versionid}/${item?.bookid}/${item?.chapterid}/`, {
          state: {
            _id: item?._id,
            verse_start: item?.verse_start,
            bookid: item?.bookid,
            chapterid: item?.chapterid,
            languageid: item?.languageid,
            versionid: item?.versionid,
          },
        });
      } else {
        navigate(`/curriculum-details/${_id}`, {
          state: { _id: item?._id },
        });
      }
    },
    [_id, dynamiclinkTo, linkTo, seeAllRoute, testimonialClickHandler, navigate]
  );

  const handlePickWhereLeftNavigate = useCallback(
    (item, index) => {
      console.log("click Item 45", item);
      if (item?.type === "video") {
        navigate(`/videoInfo/${item?._id}`, { state: { _id: item?._id } });
      } else if (item?.type === "category") {
        navigate(`/categoryInfo/${item?._id}`, { state: { _id: item?._id } });
      } else if (item?.type === "curriculum") {
        const payloadState = { _id: item?._id };
        if (item?.lessonObjId) {
          payloadState["lessonObjId"] = item?.lessonObjId;
        }
        navigate(`/curriculum-details/${item?._id}`, {
          state: payloadState,
        });
      } else if (item?.type === "testimonial") {
        navigate(`/testimonialInfo/${item._id}`, { state: { _id: item?._id } });
      } else {
        const payloadState = { _id: item?._id };
        if (item?.lessonObjId) {
          payloadState["lessonObjId"] = item?.lessonObjId;
        }
        navigate(`/curriculum-details/${_id}`, {
          state: payloadState,
        });
      }
    },
    [_id, navigate]
  );

  const CachedHoverCard = useMemo(
    () => (
      <HoverCard
        cardVisible={cardVisible}
        setCardVisible={setCardVisible}
        carouselId={carouselId}
        perViewImages={perViewImages}
        imageType={imageType}
        showProgress={showProgress}
        promiseClickHandler={promiseClickHandler}
        isPromise={isPromise}
        handlePickWhereLeftNavigate={handlePickWhereLeftNavigate}
        handleNavigate={handleNavigate}
        handlePlaying={handlePlaying}
        playVideoContained={playVideoContained}
      />
    ),
    [
      cardVisible,
      carouselId,
      perViewImages,
      imageType,
      showProgress,
      isPromise,
      handleNavigate,
      handlePickWhereLeftNavigate,
      promiseClickHandler,
      handlePlaying,
      playVideoContained,
    ]
  );

  return (
    <div
      className="mt-4 relative"
      onMouseLeave={() => {
        dispatch(setHoveredActiveItem(null));
        setCardVisible(false);
      }}
    >
      {CachedHoverCard}

      <div
        className={`pr-3 pt-6 pb-2 items-center flex ${
          showTitle ? "justify-between" : "justify-end"
        }`}
      >
        {showTitle &&
          (seeAllRoute ? (
            <Link
              to={seeAllRoute}
              className="hover:underline text-xl md:text-2xl font-semibold text-white"
            >
              {title}
            </Link>
          ) : (
            <p className="text-xl md:text-2xl font-semibold text-white">{title}</p>
          ))}
        {seeAll && (
          <button
            className="text-xs text-yellow-500 cursor-pointer"
            onClick={() => handleSeeAll(title, data)}
          >
            {seeAllValue}
          </button>
        )}
      </div>

      <Carousel
        className="p-1"
        showThumbs={false}
        showIndicators={false}
        showStatus={false}
        autoPlay={false}
        infiniteLoop
        swipeable={true}
      >
        {data?.slice(0, numberOfLists).map((_, index) => (
          <div
            key={"carousel-item" + index}
            className={`gap-3 md:gap-[1%] pb-8 grid w-full ${showItemTitle ? "pb-8" : ""}`}
            style={{
              gridTemplateColumns: `repeat(${perViewImages}, minmax(0, 1fr))`,
            }}
          >
            {data?.slice(index * perViewImages, (index + 1) * perViewImages).map((item, i) => (
              <CardDetails
                key={"carousel-card-item" + i}
                item={item}
                index={i}
                actualIndex={index}
                imageType={imageType}
                hoverCardEffect={hoverCardEffect}
                carouselId={carouselId}
                promiseClickHandler={promiseClickHandler}
                showProgress={showProgress}
                setActiveItem={(activeitem) => dispatch(setHoveredActiveItem(activeitem))}
                handlePickWhereLeftNavigate={handlePickWhereLeftNavigate}
                isPromise={isPromise}
                isPlan={isPlan}
                planButtonText={planButtonText}
                handleNavigate={handleNavigate}
                handlePlaying={handlePlaying}
                widthOfImage={widthOfImage}
                audioRefs={audioRefs}
                canDelete={canDelete}
                showItemTitle={showItemTitle}
                showLikes={showLikes}
                handleDelete={handleDelete}
                onPlanStart={onPlanStart}
              />
            ))}
          </div>
        ))}
      </Carousel>
    </div>
  );
};

export default CommonCarousel;

const CardDetails = ({
  item,
  index,
  actualIndex,
  setActiveItem,
  imageType,
  hoverCardEffect,
  carouselId,
  promiseClickHandler,
  showProgress,
  handlePickWhereLeftNavigate,
  isPromise,
  isPlan,
  planButtonText,
  handlePlaying,
  handleNavigate,
  widthOfImage,
  audioRefs,
  canDelete,
  showItemTitle,
  showLikes,
  handleDelete,
  onPlanStart,
}) => {
  return (
    <div className="flex flex-col w-full">
      <div
        className={`relative cursor-pointer bg-no-repeat bg-cover bg-top !w-full rounded-lg bg-yellowish/80 ${
          isPlan ? "aspect-square" : imageType === "landscape" ? "aspect-video" : "aspect-[12/16]"
        }`}
        onMouseEnter={() => {
          if (hoverCardEffect)
            setActiveItem({
              ...item,
              activeIndex: index,
              carouselId: carouselId,
            });
        }}
        onClick={() => {
          if (promiseClickHandler) promiseClickHandler(item);

          showProgress
            ? handlePickWhereLeftNavigate(item, index)
            : isPromise
            ? handlePlaying(index)
            : handleNavigate(item, index);
        }}
        style={{
          backgroundImage: `url('${
            item?.image ? item?.image : item?.type === "chapter" ? verseOfTheDayImg : ""
          }')`,
          width: widthOfImage + "%",
        }}
      >
        {isPromise && (
          <button className="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2">
            {/* {playState[i]?.playing ? (
                      <PiPauseCircleFill color="#EFBC51" size={55} />
                    ) : ( */}
            <PiPlayCircleFill color="#EFBC51" size={55} />
            {/* )} */}
            <audio src={item?.audio} ref={audioRefs?.current[index]} />
          </button>
        )}

        {canDelete && (
          <div
            className="text-red-600 flex justify-center items-center text-opacity-60 absolute top-2 right-2 rounded-full bg-white hover:text-opacity-100 transition-all duration-500 ease-in-out z-50"
            onClick={(e) => {
              e.stopPropagation();
              handleDelete(item._id);
            }}
          >
            <MdCancel size={25} />
          </div>
        )}
        {showProgress && (
          <div className="absolute text-sm bottom-0 rounded-b-lg flex flex-col justify-end items-center h-[60%] w-[100%] bg-gradient-to-b from-[#0000] to-[#000] pb-4">
            <p className="text-sm !text-white font-semibold text-start w-[88%]">{item?.title}</p>
            {/* <p className=" text-[12px] text-white opacity-50 text-center">
                    Completed{" "}
                    {item?.percentage ? Number(Number(item?.percentage)?.toFixed(2)) : 0} %
                  </p> */}
            <div className="w-full mt-3 px-5">
              <div className="h-[3px] w-full bg-gray-500 rounded">
                {item?.percentage !== "0" ? (
                  <div
                    className={`h-[3px] bg-yellow-500 rounded`}
                    style={{
                      width: `${parseInt(item?.percentage) > 5 ? item?.percentage : "5"}%`,
                    }}
                  ></div>
                ) : (
                  <div className={`h-[3px] `}></div>
                )}
              </div>
            </div>
          </div>
        )}
        {showLikes && <img className="absolute h-5 top-3 left-[42%]" src={Like} alt="like" />}
        {item?.type === "chapter" && (
          <div className="w-full flex flex-col gap-2 p-3 text-start text-white">
            <p className="font-semibold">{item?.title}</p>
            <p className="text-white/75 text-sm">
              "
              {(item?.verseText ?? "")?.length < 140
                ? item?.verseText ?? ""
                : (item?.verseText ?? "")?.substring(0, 140) + "..."}
              "
            </p>
          </div>
        )}
      </div>
      {isPlan && (
        <div className="flex items-center justify-between text-xs">
          <span>{item?.duration}</span>
          <StarRating rating={item?.rating} starClassName="!text-white" />
        </div>
      )}
      {showItemTitle && (
        <p className="text-white text-opacity-70 text-start text-wrap w-[95%]">{item?.title}</p>
      )}
      {isPlan && (
        <button
          className="bg-white px-8 text-sm font-semibold mt-1 w-fit rounded-2xl py-1 text-black"
          onClick={() => onPlanStart(item)}
        >
          {planButtonText ?? "Start"}
        </button>
      )}
    </div>
  );
};

const HoverCard = ({
  cardVisible,
  setCardVisible,
  carouselId,
  perViewImages,
  imageType,
  showProgress,
  promiseClickHandler,
  isPromise,
  handlePickWhereLeftNavigate,
  handleNavigate,
  handlePlaying,
  playVideoContained,
}) => {
  const dispatch = useDispatch();
  const videoRef = useRef();

  const { currentLanguageTitles, hoveredActiveItem } = useSelector(
    (state) => state.languageReducer.data
  );
  const { isMobile } = useSelector((state) => state.common);

  const [videoPlayed, setVideoPlayed] = useState(false);
  const [videoMuted, setVideoMuted] = useState(true);
  const videoUrl = hoveredActiveItem?.video ?? "";

  const isHLS = videoUrl?.includes(".m3u8");

  useEffect(() => {
    let timeout = null;
    if (cardVisible && videoUrl) {
      timeout = setTimeout(() => {
        setVideoPlayed(true);
        // videoRef.current.play();
      }, 2500);
    }

    return () => {
      clearTimeout(timeout);
      setVideoPlayed(false);
    };
  }, [cardVisible]);

  return (
    <div
      className={`absolute w-1/2 md:w-3/12 z-[100] top-0 transition-all duration-700 delay-[2000] animate-grow rounded-xl overflow-hidden bg-blackishV2 custom-drop-shadowV1 hidden ${
        cardVisible && hoveredActiveItem?.carouselId === carouselId ? "!block" : ""
      }`}
      onMouseLeave={() => {
        dispatch(setHoveredActiveItem(null));
        setCardVisible(false);
      }}
      style={
        hoveredActiveItem?.activeIndex + 1 === perViewImages
          ? {
              right: `${hoveredActiveItem?.activeIndex + 1 === perViewImages ? "1rem" : ""}`,
            }
          : {
              left: `${
                hoveredActiveItem?.activeIndex === 0
                  ? "1rem"
                  : (hoveredActiveItem?.activeIndex * 100) / perViewImages + "%"
              }`,
            }
      }
    >
      <div
        className={`w-full ${
          imageType === "landscape" ? "aspect-video" : "aspect-[12/16]"
        } relative`}
      >
        {!videoPlayed && hoveredActiveItem?.image && (
          <img
            key={hoveredActiveItem?.id}
            src={hoveredActiveItem?.image}
            className={`w-full h-full object-cover object-top z-10 relative ${
              imageType === "landscape" ? "aspect-video" : "aspect-[12/16]"
            }`}
            alt={hoveredActiveItem?.title}
          />
        )}
        {/* <video
          className={`absolute top-0 left-0 aspect-video bg-blackishV2 object-cover object-top ${
            videoPlayed ? "block" : "hidden"
          }`}
          ref={videoRef}
          src={videoUrl}
          muted
        /> */}
        <ReactPlayer
          ref={videoRef}
          url={videoUrl}
          className={`custom-player absolute top-0 left-0 aspect-video bg-blackishV2 ${
            playVideoContained ? "custom-player-v2" : "custom-player-v3"
          }`}
          width={"100%"}
          height={"100%"}
          muted={videoMuted}
          playing={videoPlayed}
          config={{
            file: {
              forceHLS: isHLS,
              hlsVersion: "latest",
              hls: (url) => {
                const hls = new Hls();
                hls.loadSource(url);
                return hls;
              },
            },
          }}
        />
        <button
          className={`bg-transparent border-none absolute text-xl text-white z-50 right-4 bottom-4 ${
            videoPlayed ? "" : "hidden"
          }`}
          onClick={() => {
            if (videoRef.current) {
              setVideoMuted((prev) => !prev);
              // videoRef.current.muted = !videoRef?.current?.muted;
            }
          }}
        >
          {videoMuted ? <GoMute /> : <GoUnmute />}
        </button>
        {hoveredActiveItem?.type === "chapter" && (
          <div
            className="w-full bg-cover h-full flex flex-col gap-2 p-3 text-start text-white absolute top-0 left-0"
            style={{ backgroundImage: `url('${verseOfTheDayImg}')` }}
          >
            <p className="font-semibold">{hoveredActiveItem?.title}</p>
            <p className="text-white/75 text-sm">
              "
              {(hoveredActiveItem?.verseText ?? "")?.length < 250
                ? hoveredActiveItem?.verseText ?? ""
                : (hoveredActiveItem?.verseText ?? "")?.substring(0, 250) + "..."}
              "
            </p>
          </div>
        )}
        <div className="absolute w-full h-[103%] top-0 left-0 bg-gradient-to-t from-blackishV2 to-black/0 z-40 from-5% to-20%"></div>
      </div>
      <div className="py-3 px-3.5">
        <button
          onClick={() => {
            if (promiseClickHandler) promiseClickHandler(hoveredActiveItem);

            showProgress
              ? handlePickWhereLeftNavigate(hoveredActiveItem, hoveredActiveItem?.activeIndex)
              : isPromise
              ? handlePlaying(hoveredActiveItem)
              : handleNavigate(hoveredActiveItem, hoveredActiveItem?.activeIndex);
          }}
          className="border-none bg-yellowish text-black px-[12%] lg:py-[10px] md:py-[8px] py-1 rounded-md font-semibold text-sm mb-4 w-full flex items-center justify-center gap-2 transition-all duration-700 hover:scale-[102%]"
        >
          {["videos", "category", "testimonial"]?.includes(hoveredActiveItem?.type) && <FaPlay />}
          {["videos", "category", "testimonial", "promise"]?.includes(hoveredActiveItem?.type)
            ? currentLanguageTitles?.[0]?.home_meta?.watchnow ?? "Watch Now"
            : currentLanguageTitles?.[0]?.home_meta?.readnow ?? "View More"}
        </button>
        <div className="px-0.5">
          <p className="mt-2 text-white text-sm font-semibold overflow-hidden overflow-ellipsis text-start text-wrap w-[95%]">
            {hoveredActiveItem?.title}
          </p>
          {hoveredActiveItem?.subtitle && (
            <p className="mt-2 text-white text-sm text-opacity-60 text-wrap mb-1">
              {isMobile
                ? hoveredActiveItem?.subtitle?.substring(0, MOBILE_DESCRIPTION_LENGTH) + ".."
                : hoveredActiveItem?.subtitle}
            </p>
          )}
        </div>
      </div>
    </div>
  );
};
