import { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";

import Toastiy from "../../components/common/Toastiy";

import { copyToClipboard } from "../../utils/Utils";
import { versesColor } from "../../constants/common";

import { LuCopy } from "react-icons/lu";
import { PiNotepadBold } from "react-icons/pi";
import { MdClose, MdOutlineBookmarks } from "react-icons/md";
import { AiOutlineCloseSquare } from "react-icons/ai";
import { useSelector } from "react-redux";

const VerseDetails = ({
  item,
  index,
  active,
  bookId,
  bgColor,
  bookName,
  verseText,
  versionId,
  setActive,
  chapterNo,
  parallelMode,
  isNotesActive,
  selectedVerses,
  handleColorChange,
  setIsNotesPopupActive,
  handleSaveVerse,
  handleRemoveColor,
}) => {
  const { isAuthenticated } = useAuth0();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [isColorPaletteOpen, setIsColorPaletteOpen] = useState(false);

  useEffect(() => {
    const eventHandler = (e) => {
      let container = document.querySelector(`#verseToolbar${chapterNo}`);
      if (container && !container?.contains(e.target)) {
        setActive(-1);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div className="relative">
      <span
        key={`${versionId}${bookId}${chapterNo}${item?.verse_start}${index}`}
        id={`${versionId}${bookId}${chapterNo}${item?.verse_start}`}
        className={`p-0.5 leading-3 ${
          selectedVerses?.[`${bookId}${item?.verse_start}`] ? "text-black" : ""
        }`}
        style={{ background: bgColor }}
      >
        {verseText}
      </span>
      {!isNotesActive && active === index && (
        <div
          className={`w-fit flex items-end absolute -translate-y-full p-5 z-30 gap-3 bg-greyishV2 rounded-full py-2 ${
            isColorPaletteOpen && isAuthenticated ? "pl-40 px-5" : "px-2"
          } ${
            parallelMode
              ? "-top-1 left-1/2 -translate-x-1/2"
              : "-top-1 left-0 md:left-auto md:-right-1 -translate-x-12 md:md:translate-x-[90%]"
          }`}
          id={`verseToolbar${chapterNo}`}
        >
          {isAuthenticated && (
            <div
              className={`flex flex-col items-center rounded-md bg-greyishV2 pl-3 ${
                isColorPaletteOpen ? "absolute left-3 pr-3" : "pr-6"
              } pt-2 pb-1 cursor-pointer`}
              onClick={(e) => {
                setIsColorPaletteOpen(true);
              }}
            >
              <div className="min-w-24 flex items-center gap-1">
                <div
                  className={`w-fit grid ${
                    isColorPaletteOpen ? "grid-cols-4" : "grid-cols-4"
                  } gap-1`}
                >
                  {selectedVerses?.[`${bookId}${item?.verse_start}`]?.verseDetails?.[0]
                    ?.verseObjId && (
                    <div
                      className="w-5 aspect-square border-2 border-red-500 cursor-pointer flex items-center justify-center rounded"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleRemoveColor(index, item);
                        setIsColorPaletteOpen(false);
                      }}
                    >
                      <MdClose className="text-red-500 text-sm my-auto leading-none" />
                    </div>
                  )}
                  {versesColor
                    ?.slice(0, isColorPaletteOpen ? versesColor?.length : 3)
                    .map((color, i) => (
                      <div
                        key={i}
                        className="w-5 aspect-square rounded"
                        style={{ background: color }}
                        onClick={(e) => {
                          e.stopPropagation();
                          handleColorChange(color, index, item);
                          setIsColorPaletteOpen(false);
                        }}
                      ></div>
                    ))}
                </div>
                {!isColorPaletteOpen && (
                  <div className="flex items-center relative">
                    <div className="w-3 aspect-square rounded-sm bg-ellipsV4 z-40"></div>
                    <div className="w-3 aspect-square rounded-sm bg-ellipsV3 z-30 absolute left-1"></div>
                    <div className="w-3 aspect-square rounded-sm bg-ellipsV5 z-20 absolute left-2"></div>
                    <div className="w-3 aspect-square rounded-sm bg-ellipsV6 z-10 absolute left-3"></div>
                  </div>
                )}
              </div>
              <span className="text-greyish text-sm">
                {currentLanguageTitles?.[0]?.bible?.highlights_lbl ?? "Highlight"}
              </span>
            </div>
          )}
          <div className="flex items-center gap-3">
            {isAuthenticated && (
              <>
                <div
                  className="flex flex-col items-center bg-blackish border border-yellowish rounded-full py-1 px-3 cursor-pointer h-14 aspect-square justify-center"
                  onClick={() => handleSaveVerse(index)}
                >
                  <MdOutlineBookmarks className="text-yellowish text-2xl" />
                  <span className="text-greyish text-xs">
                    {currentLanguageTitles?.[0]?.bible?.save_lbl ?? "Save"}
                  </span>
                </div>
                <div
                  className="flex flex-col items-center bg-blackish border border-yellowish rounded-full py-1 px-3 cursor-pointer h-14 aspect-square"
                  onClick={() => {
                    setIsNotesPopupActive(true);
                  }}
                >
                  <PiNotepadBold className="text-yellowish text-2xl" />
                  <span className="text-greyish text-xs">
                    {currentLanguageTitles?.[0]?.bible?.note_lbl ?? "Note"}
                  </span>
                </div>
              </>
            )}
            <div
              className="flex flex-col items-center bg-blackish border border-yellowish rounded-full py-1 px-3 cursor-pointer h-14 aspect-square"
              onClick={() => {
                copyToClipboard(`${bookName} ${chapterNo}:${item?.verse_start} - ${verseText}`);
                Toastiy("Copied to clipboard!", 1);
              }}
            >
              <LuCopy className="text-yellowish scale-x-[-1] text-2xl" />
              <span className="text-greyish text-xs">
                {currentLanguageTitles?.[0]?.bible?.copy_lbl ?? "Copy"}
              </span>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default VerseDetails;
