import { initialVersionDetails } from "../../constants/bible";
import { SEARCH_TABS } from "../../constants/common";
import * as actionTypes from "../ActionTypes";

const INITIAL = {
  bibleParallelMode: false,
  bibleLanguage: 17045,
  bibleVersion: "ENGESV",
  versionDetails: initialVersionDetails ?? {},
  bookChapterDetails: [],
  bookChapterDetailsLoading: true,
  parallelVersionDetails: {},
  parallelBookChapterDetails: [],
  parallelBookChapterDetailsLoading: false,
  userJournals: [],
  countryLanguages: [],
  activeSearchTab: SEARCH_TABS.BIBLE,
  activeParallelSearchTab: SEARCH_TABS.BIBLE,
  allBibleLanguages: [],
  allBibleVersions: [],
};

const bibleReducer = (state = INITIAL, action) => {
  switch (action.type) {
    case actionTypes.SET_BIBLE_PARALLEL_MODE:
      return {
        ...state,
        bibleParallelMode: action.payload,
      };
    case actionTypes.SET_BIBLE_COUNTRY_LANGUAGES:
      return {
        ...state,
        countryLanguages: action.payload,
      };
    case actionTypes.SET_BIBLE_LANGUAGE:
      return {
        ...state,
        bibleLanguage: action.payload,
      };
    case actionTypes.SET_BIBLE_VERSION:
      return {
        ...state,
        bibleVersion: action.payload,
      };
    case actionTypes.SET_VERSION_DETAILS:
      return {
        ...state,
        versionDetails: action.payload,
      };
    case actionTypes.SET_BOOK_CHAPTER_DETAILS:
      return {
        ...state,
        bookChapterDetails: action.payload,
      };
    case actionTypes.SET_BOOK_CHAPTER_DETAILS_LOADING:
      return {
        ...state,
        bookChapterDetailsLoading: action.payload,
      };
    case actionTypes.SET_PARALLEL_VERSION_DETAILS:
      return {
        ...state,
        parallelVersionDetails: action.payload,
      };
    case actionTypes.SET_PARALLEL_BOOK_CHAPTER_DETAILS:
      return {
        ...state,
        parallelBookChapterDetails: action.payload,
      };
    case actionTypes.SET_PARALLEL_BOOK_CHAPTER_DETAILS_LOADING:
      return {
        ...state,
        parallelBookChapterDetailsLoading: action.payload,
      };
    case actionTypes.SET_USER_JOURNALS:
      return {
        ...state,
        userJournals: action.payload,
      };
    case actionTypes.SET_ACTIVE_SEARCH_TAB:
      return {
        ...state,
        activeSearchTab: action.payload,
      };
    case actionTypes.SET_ACTIVE_PARALLEL_SEARCH_TAB:
      return {
        ...state,
        activeParallelSearchTab: action.payload,
      };
    case actionTypes.SET_ALL_BIBLE_VERSIONS:
      return {
        ...state,
        allBibleVersions: action.payload,
      };
    case actionTypes.SET_ALL_BIBLE_LANGUAGES:
      return {
        ...state,
        allBibleLanguages: action.payload,
      };
    default:
      return state;
  }
};

export default bibleReducer;
