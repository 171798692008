import plan1 from "../../assets/images/plan1.png";
import plan4 from "../../assets/images/plan4.png";
import { useLocation, useNavigate } from "react-router-dom/dist";

import {
  christmasPlanArray,
  newToFaithPlanArray,
} from "../../constants/common";
import CommonCarousel from "../../components/home/CommonCarousel";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
// import MyPlans from "../bible/MyPlans";
import FooterBar from "../../components/common/FooterBar";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import MyPlans from "./MyPlans";
import PlanCarousel from "./PlanCarousel";

const Plans = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { isMobile } = useSelector((state) => state.common);
  const initialTabs = ["Bible", "Plans"];

  const [tabValues, setTabsValues] = useState(initialTabs);

  const [activeTab, setActiveTab] = useState(1);
  useEffect(() => {
    if (location.pathname === "/login") {
      navigate("/");
    }
  }, [location]);

  const [activePlan, setActivePlan] = useState(0);
  const handleTabChange = (index) => {
    if (index === 0) {
      navigate("/bible");
    } else {
      setActiveTab(index);
    }
  };

  const planList = ["Home", "My Plans", "In Progress", "Completed", "Saved"];
  return (
    <div className="flex">
      <div className="flex w-full md:flex-row flex-col overflow-hidden">
        <Navbar active={"Bible"} />
        <div className="md:ml-[104px] flex flex-col w-full bg-black">
          <HeaderBar
            popCancle={true}
            tabValues={tabValues}
            active={activeTab}
            absolute={false}
            handleTabChange={handleTabChange}
            getLanguagePreferred={() => {}}
            getCurrentLanguageTitles={() => {}}
          />
          <div className="flex flex-col bg-black h-full text-white overflow-y-auto w-[92vw] max-w-full px-7">
            <div>
              {activeTab === 1 ? (
                <div className="flexn flex-col max-w-full w-full gap-x-6 p-6">
                  <h1 className="text-4xl font-semibold text-yellowish mb-2">
                    Plans
                  </h1>
                  <div className="flex flex-wrap gap-3 mb-4">
                    {planList?.map((plan, index) => {
                      return (
                        <span
                          className={`rounded-full border ${
                            activePlan === index
                              ? "border-yellowish text-yellowish"
                              : "border-white text-white"
                          }  py-1 px-3 cursor-pointer`}
                          onClick={() => setActivePlan(index)}
                        >
                          {plan}
                        </span>
                      );
                    })}
                  </div>

                  {activePlan === 0 && (
                    <>
                      <div className="grid grid-cols-4 gap-3">
                        <img
                          src={plan4}
                          alt=""
                          className="aspect-video object-cover object-top rounded-xl overflow-hidden"
                          onClick={() => navigate("/plan/1")}
                        />
                        <img
                          src={plan1}
                          alt=""
                          className="aspect-video object-cover object-top rounded-xl overflow-hidden"
                          onClick={() => navigate("/plan/2")}
                        />
                        <img
                          src={plan4}
                          alt=""
                          className="aspect-video object-cover object-top rounded-xl overflow-hidden"
                          onClick={() => navigate("/plan/3")}
                        />
                        <img
                          src={plan1}
                          alt=""
                          className="aspect-video object-cover object-top rounded-xl overflow-hidden"
                          onClick={() => navigate("/plan/4")}
                        />
                      </div>
                      <CommonCarousel
                        data={newToFaithPlanArray}
                        seeAll={true}
                        dataTitle={false}
                        dynamiclinkTo={true}
                        perViewImages={isMobile ? 2 : 8}
                        imageType={"landscape"}
                        title="In Progress"
                        showTitle={true}
                        showItemTitle={true}
                        isPlan={true}
                        planButtonText="Continue Reading"
                        onPlanStart={(item) => navigate(`/plan/${item?._id}`)}
                      />
                      <CommonCarousel
                        data={christmasPlanArray}
                        seeAll={true}
                        dataTitle={false}
                        dynamiclinkTo={true}
                        perViewImages={isMobile ? 2 : 8}
                        imageType={"landscape"}
                        title="Christmas"
                        showTitle={true}
                        showItemTitle={true}
                        isPlan={true}
                        onPlanStart={(item) => navigate(`/plan/${item?._id}`)}
                      />
                      <PlanCarousel />
                    </>
                  )}
                  {activePlan === 1 && <MyPlans filterStatus={"My Plans"} />}
                  {activePlan === 2 && <MyPlans filterStatus={"In progress"} />}
                  {activePlan === 3 && <MyPlans filterStatus={"Done"} />}
                  {activePlan === 4 && <MyPlans filterStatus={"Saved"} />}
                </div>
              ) : (
                {}
              )}
            </div>
            <FooterBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Plans;
