import { useEffect, useState } from "react";
import ReactModal from "react-modal";
import { useDispatch, useSelector } from "react-redux";
import { confirmAlert } from "react-confirm-alert";

import AudioCard from "./AudioCard";
import NotesWebcam from "./NotesWebcam";
import UploadAudioButton from "./UploadAudioButton";
import Toastiy from "../../components/common/Toastiy";
import ConfirmToast from "../../components/common/ConfirmToast";
import LoaderSpinner from "../../components/common/LoaderSpinner";
import MyStatefulEditor from "../../components/common/MyStatefulEditor";

import {
  addJournalNoteAction,
  addJournalNoteItemAction,
  deleteJournalAction,
  deleteJournalNoteAction,
  deleteJournalNoteItemAction,
  getBibleSearchAction,
  getUserJournalsAction,
  saveJournalAction,
  setUserJournalsAction,
  updateJournalAction,
  updateJournalNoteAction,
} from "../../store/Bible/BibleAction";
import { uploadAssestsAction } from "../../store/Home/HomeAction";

import { FaPlus } from "react-icons/fa";
import { LuSearch } from "react-icons/lu";
import { MdCancel } from "react-icons/md";
import { FiMinusCircle } from "react-icons/fi";
import { RiDeleteBin5Line } from "react-icons/ri";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";

import CloseIcon from "../../assets/icons/Cross.svg";
import Camera from "../../assets/icons/Notes/camera.svg";
import AddBtn from "../../assets/icons/Notes/addBtn.svg";
import AddButton from "../../assets/icons/Notes/addBtn.svg";
import RightTick from "../../assets/icons/Notes/rightTick.svg";
import LeftArrowIcon from "../../assets/icons/leftArrowIcon.svg";

const NotesModel = ({
  isNotesActive,
  setIsNotesActive,
  isNewNotesActive,
  activeVerseDetails,
  setActiveVerseDetails,
  existingJournalId = null,
  onDelete,
  versionDetails,
  onNoteSave,
}) => {
  const dispatch = useDispatch();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [contents, setContents] = useState("");
  const [isWebcamActive, setIsWebcamActive] = useState(false);
  const [newVerseActive, setNewVerseActive] = useState(false);
  const [forceEditorUpdate, setForceEditorUpdate] = useState("");
  const [journalTitle, setJournalTitle] = useState("");
  const [currNoteIndex, setCurrNoteIndex] = useState(0);
  const [journalId, setJorunalId] = useState();
  const [journalLoading, setJournalLoading] = useState(false);

  const [searchTxt, setSearchTxt] = useState("");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  console.log("activeVerseDetails12", activeVerseDetails);
  console.log("currNoteIndex", currNoteIndex);
  console.log("journalId", journalId);

  const [journalValues, setJournalValues] = useState({
    journal_id: "",
    notes:
      !existingJournalId && activeVerseDetails
        ? [
            {
              noteObjId: "",
              bookid: activeVerseDetails?.book_id,
              chapterid: activeVerseDetails?.chapter,
              verse_start: activeVerseDetails?.verse_start,
              verse_end: activeVerseDetails?.verse_end,
              notes: activeVerseDetails?.verse_text,
              bookname: activeVerseDetails?.book_name,
              noteitems: [],
            },
          ]
        : [],
  });

  const [originalJournalValues, setOriginalJournalValues] = useState({});

  console.log("journalValues", journalValues);
  console.log("activeVerseDetails", activeVerseDetails);

  const fetchJournalDetails = () => {
    setJournalLoading(true);
    dispatch(
      getUserJournalsAction({
        apiPayloadRequest: {
          _id: journalId,
        },
        callback: (response) => {
          const journalData = {
            ...(response.data?.[0] ?? {}),
            notes: [
              ...(activeVerseDetails?.verse_text
                ? [
                    {
                      noteObjId: "",
                      bookid: activeVerseDetails?.book_id,
                      chapterid: activeVerseDetails?.chapter,
                      verse_start: activeVerseDetails?.verse_start,
                      verse_end: activeVerseDetails?.verse_end,
                      notes: activeVerseDetails?.verse_text,
                      bookname: activeVerseDetails?.book_name,
                      noteitems: [],
                    },
                  ]
                : []),
              ...(response?.data?.[0]?.notes
                ? response?.data?.[0]?.notes?.sort(
                    (a, b) => new Date(b.lastUpdatedDate) - new Date(a.lastUpdatedDate)
                  )
                : []),
            ],
          };
          setJournalValues(journalData);
          setOriginalJournalValues(journalData);
          setJorunalId(response?.data?.[0]?._id);
          setJournalTitle(response?.data?.[0]?.journal_title);
          setCurrNoteIndex(0);
          setJournalLoading(false);
          setActiveVerseDetails({});
        },
      })
    );
  };

  useEffect(() => {
    if (journalId) fetchJournalDetails();
  }, [journalId]);

  useEffect(() => {
    if (existingJournalId) setJorunalId(existingJournalId);
  }, [existingJournalId]);

  console.log("journalValues", journalValues);

  const addNote = () => {
    setJournalValues((prev) => ({ ...prev, notes: [...(prev?.notes ?? []), {}] }));
    setCurrNoteIndex(journalValues?.notes?.length);
  };

  const fetchUserJournals = () => {
    dispatch(
      getUserJournalsAction({
        callback: (response) => {
          dispatch(setUserJournalsAction(response?.data));
        },
      })
    );
  };

  const handleSaveJournal = (responseCallback = null) => {
    if (!journalTitle) {
      Toastiy("Add a journal title to proceed further.");
      return;
    }
    if (journalId && journalValues?.journal_title !== journalTitle) {
      dispatch(
        updateJournalAction({
          apiPayloadRequest: {
            _id: journalTitle,
            journal_title: journalTitle,
          },
          callback: (response) => {
            if (responseCallback) responseCallback(response);
          },
        })
      );
    } else if (!journalId) {
      dispatch(
        saveJournalAction({
          apiPayloadRequest: {
            public: false,
            publish: true,
            journal_title: journalTitle,
          },
          callback: (response) => {
            setJorunalId(response?.data?.[0]?._id);
            fetchUserJournals();
            if (responseCallback) responseCallback(response);
          },
        })
      );
    } else {
      if (responseCallback) responseCallback();
    }
  };

  const handleDeleteJournal = () => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this journal?"
            deleteButtonText="Yes"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteJournalAction({
                  apiPayloadRequest: {
                    _id: journalId,
                  },
                  callback: () => {
                    if (onDelete) {
                      onDelete();
                    }
                    setIsNotesActive(false);
                    Toastiy("Notes Deleted Successfully!!", 1);
                  },
                })
              );
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75 z-10000",
    };

    confirmAlert(options);
  };

  const handleSaveNoteItem = (noteObjId, journalId, item, responseCallback = null) => {
    const payload = {
      _id: journalId,
      noteObjId: noteObjId,
      type_of_notes: item?.type_of_notes,
      notes: item?.notes ?? "",
    };
    if (["1", "2", "3"].includes(item?.type_of_notes)) {
      payload.url_link = item?.url_link ?? "";
    }
    if ("4" === item?.type_of_notes) {
      payload.bookid = item?.bookid ?? "";
      payload.chapterid = item?.chapterid ?? "";
      payload.verse_start = item?.verse_start ?? "";
      payload.verse_end = item?.verse_end ?? "";
      payload.bookname = item?.bookname ?? "";
    }
    if (!item?.notesItemObjId) {
      dispatch(
        addJournalNoteItemAction({
          apiPayloadRequest: payload,
          callback: (resp) => {
            if (responseCallback) responseCallback();
          },
        })
      );
    }
  };

  const handleSaveNote = (index, responseCallback) => {
    const updatedJournalValues = structuredClone(journalValues);
    if (contents !== "") {
      updatedJournalValues.notes[currNoteIndex] = {
        ...(updatedJournalValues.notes[currNoteIndex] ?? {}),
        noteitems: [
          ...(updatedJournalValues.notes[currNoteIndex]?.noteitems ?? []),
          { type_of_notes: "0", notes: contents },
        ],
      };

      setContents("");
      setForceEditorUpdate(true);
      setTimeout(() => {
        setForceEditorUpdate(false);
      }, 500);
    }
    if (!journalId) {
      handleSaveJournal((res) => {
        dispatch(
          addJournalNoteAction({
            apiPayloadRequest: {
              _id: res?.data?.[0]?._id,
              notesTitle: " ",
              bookid: updatedJournalValues?.notes?.[index]?.bookid,
              chapterid: updatedJournalValues?.notes?.[index]?.chapterid,
              verse_start: updatedJournalValues?.notes?.[index]?.verse_start,
              verse_end: updatedJournalValues?.notes?.[index]?.verse_end,
              verse_text: updatedJournalValues?.notes?.[index]?.verse_text,
              bookname: updatedJournalValues?.notes?.[index]?.bookname,
              versionid: versionDetails?.abbr,
              languageid: versionDetails?.language_id,
            },
            callback: (response) => {
              const promiseArray = [];
              if (onNoteSave) onNoteSave(response);
              updatedJournalValues?.notes?.[index]?.noteitems?.map((noteitem) => {
                promiseArray.push(
                  new Promise((resolve, reject) => {
                    handleSaveNoteItem(response?.noteObjId, res?.data?.[0]?._id, noteitem, resolve);
                  })
                );
              });
              Promise.allSettled(promiseArray).then(() => {
                fetchJournalDetails();
              });
              Toastiy("Notes Saved Successfully!!", 1);
            },
          })
        );
      });
    } else {
      handleSaveJournal((res) => {
        console.log("journalValues5654", updatedJournalValues);
        console.log("originalJournalValues5654", updatedJournalValues);
        if (updatedJournalValues?.notes?.[index]?.noteObjId) {
          if (
            originalJournalValues?.notes?.[index]?.bookid !==
            updatedJournalValues?.notes?.[index]?.bookid
          ) {
            dispatch(
              updateJournalNoteAction({
                apiPayloadRequest: {
                  _id: journalId,
                  notesTitle: "",
                  noteObjId: updatedJournalValues?.notes?.[index]?.noteObjId,
                  bookid: updatedJournalValues?.notes?.[index]?.bookid,
                  chapterid: updatedJournalValues?.notes?.[index]?.chapterid,
                  verse_start: updatedJournalValues?.notes?.[index]?.verse_start,
                  verse_end: updatedJournalValues?.notes?.[index]?.verse_end,
                  verse_text: updatedJournalValues?.notes?.[index]?.verse_text,
                  bookname: updatedJournalValues?.notes?.[index]?.bookname,
                  versionid: updatedJournalValues?.notes?.[index]?.versionid,
                  languageid: updatedJournalValues?.notes?.[index]?.languageid,
                },
                callback: (response) => {
                  Toastiy("Notes Updated Successfully!!", 1);
                },
              })
            );
          }
          const promiseArray = [];
          updatedJournalValues?.notes?.[index]?.noteitems?.map((noteitem) => {
            promiseArray.push(
              new Promise((resolve, reject) => {
                handleSaveNoteItem(
                  updatedJournalValues?.notes?.[index]?.noteObjId,
                  journalId,
                  noteitem,
                  resolve
                );
              })
            );
          });
          Promise.allSettled(promiseArray).then(() => {
            fetchJournalDetails();
          });
        } else {
          dispatch(
            addJournalNoteAction({
              apiPayloadRequest: {
                _id: journalId,
                notesTitle: " ",
                bookid: updatedJournalValues?.notes?.[index]?.bookid,
                chapterid: updatedJournalValues?.notes?.[index]?.chapterid,
                verse_start: updatedJournalValues?.notes?.[index]?.verse_start,
                verse_end: updatedJournalValues?.notes?.[index]?.verse_end,
                verse_text: updatedJournalValues?.notes?.[index]?.verse_text,
                bookname: updatedJournalValues?.notes?.[index]?.bookname,
                versionid: versionDetails?.abbr,
                languageid: versionDetails?.language_id,
              },
              callback: (response) => {
                const promiseArray = [];
                if (onNoteSave) onNoteSave(response);
                updatedJournalValues?.notes?.[index]?.noteitems?.map((noteitem) => {
                  promiseArray.push(
                    new Promise((resolve, reject) => {
                      handleSaveNoteItem(response?.noteObjId, journalId, noteitem, resolve);
                    })
                  );
                });
                Promise.allSettled(promiseArray).then(() => {
                  fetchJournalDetails();
                });
                Toastiy("Notes Saved Successfully!!", 1);
              },
            })
          );
        }
      });
    }
  };

  const handleDeleteNote = (item, index) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this note?"
            deleteButtonText="Yes"
            onClose={() => onClose()}
            onSuccess={() => {
              if (item?.noteObjId) {
                dispatch(
                  deleteJournalNoteAction({
                    apiPayloadRequest: {
                      _id: journalId,
                      noteObjId: item?.noteObjId,
                    },
                    callback: () => {
                      fetchJournalDetails();
                    },
                  })
                );
              }
              setJournalValues((prev) => {
                const updatedData = structuredClone(prev);
                updatedData?.notes?.splice(index, 1);
                return updatedData;
              });
              Toastiy("Notes Deleted Successfully!!");
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75 z-10000",
    };

    confirmAlert(options);
  };

  const handleDeleteNoteItem = (item, noteIndex, itemIndex) => {
    console.log("item", item);
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this item?"
            deleteButtonText="Yes"
            onClose={() => onClose()}
            onSuccess={() => {
              if (journalValues?.notes?.[noteIndex]?.noteitems?.[itemIndex]?.notesItemObjId) {
                dispatch(
                  deleteJournalNoteItemAction({
                    apiPayloadRequest: {
                      _id: journalId,
                      noteObjId: journalValues?.notes?.[noteIndex]?.noteObjId,
                      notesItemObjId:
                        journalValues?.notes?.[noteIndex]?.noteitems?.[itemIndex]?.notesItemObjId,
                    },
                    callback: () => {
                      // setIsNotesActive(false);
                    },
                  })
                );
              }
              setJournalValues((prev) => {
                const updatedData = structuredClone(prev);
                updatedData?.notes?.[noteIndex]?.noteitems?.splice(itemIndex, 1);
                return updatedData;
              });
              onClose();
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75 z-10000",
    };

    confirmAlert(options);
  };

  const uploadAsset = (noteIndex, itemIndex, file) => {
    console.log("noteIndex, itemIndex, file", noteIndex, itemIndex, file);
    dispatch(
      uploadAssestsAction({
        apiPayloadRequest: { file: file },
        callback: (response) => {
          setJournalValues((prev) => {
            console.log("prev", prev);
            const updatedData = structuredClone(prev);
            updatedData.notes[noteIndex].noteitems[itemIndex].url_link = response?.url;
            updatedData.notes[noteIndex].noteitems[itemIndex].isLoading = false;
            return updatedData;
          });
        },
      })
    );
  };

  const handleSearch = (e) => {
    setSearchTxt(e.target.value);

    setIsSearching(true);
    dispatch(
      getBibleSearchAction({
        apiPayloadRequest: {
          filesetid: versionDetails?.abbr,
          searchquery: e.target.value,
        },
        callback: (res) => {
          setSearchResults(res?.data?.verses?.data ?? []);
          setIsSearching(false);
        },
      })
    );
  };

  return (
    <>
      <ReactModal
        isOpen={isNotesActive}
        className={"overflow-hidden"}
        onRequestClose={() => setIsNotesActive(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex justify-center items-center flex-col gap-6 relative">
          {!isWebcamActive && (
            <button
              className="absolute top-24 md:top-5 right-6 md:right-5"
              onClick={() => setIsNotesActive(false)}
            >
              <MdCancel color="#EFBC51" size={30} />
            </button>
          )}

          <div className="rounded-2xl bg-blackishV2 h-[80%] md:h-[90%] w-[90%] md:w-[50%] flex flex-col py-6">
            {journalLoading ? (
              <div className="w-full h-full bg-white/05 flex items-center  justify-center">
                <LoaderSpinner />
              </div>
            ) : (
              <div className="w-full px-4 md:px-12 flex flex-col h-full overflow-y-auto custom-scrollbar">
                <div className="w-full flex items-center justify-between">
                  <div className="flex items-center gap-3 text-yellowish text-2xl font-semibold">
                    <img
                      src={LeftArrowIcon}
                      alt="LeftArrowIcon"
                      className="cursor-pointer"
                      onClick={() => setIsNotesActive(false)}
                    />
                    <input
                      type="text"
                      placeholder={
                        currentLanguageTitles?.[0]?.bible?.jorunal_title_placeholder ?? "Add Title"
                      }
                      className="text-yellowish bg-transparent outline-none border-b border-yellowish ml-3"
                      value={journalTitle}
                      onChange={(e) => {
                        setJournalTitle(e.target.value);
                      }}
                    />
                  </div>
                </div>
                {journalValues?.notes?.map((noteItem, noteIndex) => (
                  <div
                    className={`flex flex-col border p-3 md:p-5 pr-5 md:pr-8 mt-5 bg-blackish relative ${
                      noteIndex === currNoteIndex
                        ? "border-yellowish/50"
                        : "border-greyish/40 !h-24 cursor-pointer"
                    } ${
                      noteIndex % 2 === 0
                        ? "rounded-ss-3xl rounded-ee-3xl"
                        : "rounded-se-3xl rounded-es-3xl"
                    }`}
                    onClick={() => {
                      setCurrNoteIndex(noteIndex);
                      if (noteIndex === currNoteIndex) {
                        setCurrNoteIndex(null);
                      } else {
                        setCurrNoteIndex(noteIndex);
                      }
                    }}
                  >
                    <span className="absolute top-3 left-3 text-yellowish cursor-pointer">
                      {noteIndex === currNoteIndex ? <IoIosArrowUp /> : <IoIosArrowDown />}
                    </span>
                    <RiDeleteBin5Line
                      className="text-lg absolute right-3 top-3 text-red-500 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleDeleteNote(noteItem, noteIndex);
                      }}
                    />
                    <div
                      className={`w-full flex-1 pr-2 pb-10 ${
                        noteIndex === currNoteIndex
                          ? "overflow-y-auto custom-scrollbar"
                          : "overflow-y-hidden"
                      }`}
                      onClick={(e) => {
                        if (noteIndex === currNoteIndex) e.stopPropagation();
                      }}
                    >
                      {noteItem?.verse_start && (
                        <div className="w-full py-2 relative">
                          <div className="text-greyish flex gap-4 py-2">
                            <h3 className="text-2xl font-semibold">
                              {noteItem?.bookname}{" "}
                              <span>
                                {noteItem?.chapterid}:{noteItem?.verse_start}
                              </span>
                            </h3>
                          </div>
                          <p className="text-greyish">{noteItem?.notes}</p>

                          <FiMinusCircle
                            className="text-white absolute top-5 -right-1 cursor-pointer"
                            onClick={() => {
                              setJournalValues((prev) => {
                                const updatedData = structuredClone(prev);
                                delete updatedData.notes[noteIndex]?.bookid;
                                delete updatedData.notes[noteIndex]?.chapterid;
                                delete updatedData.notes[noteIndex]?.verse_start;
                                delete updatedData.notes[noteIndex]?.verse_end;
                                delete updatedData.notes[noteIndex]?.verse_text;
                                delete updatedData.notes[noteIndex]?.bookname;
                                return updatedData;
                              });
                            }}
                          />
                        </div>
                      )}
                      {noteItem?.noteitems?.map((item, noteItemIndex) => {
                        if (item?.type_of_notes === "4") {
                          return (
                            <div className="w-full py-2 relative" key={`note-item${noteItemIndex}`}>
                              <div className="text-greyish flex gap-4 py-2">
                                <h3 className="text-2xl font-semibold">
                                  {item?.bookname}{" "}
                                  <span>
                                    {item?.chapterid}:{item?.verse_start}
                                  </span>
                                </h3>
                              </div>
                              <p className="text-greyish">{item?.notes}</p>
                              <FiMinusCircle
                                className="text-white absolute top-5 -right-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNoteItem(noteItem, noteIndex, noteItemIndex)
                                }
                              />
                            </div>
                          );
                        } else if (item?.type_of_notes === "0") {
                          return (
                            <div
                              className="w-full text-greyish py-3 relative"
                              key={`note-item${noteItemIndex}`}
                            >
                              <p dangerouslySetInnerHTML={{ __html: item?.notes }}></p>
                              <FiMinusCircle
                                className="text-white absolute top-5 -right-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNoteItem(noteItem, noteIndex, noteItemIndex)
                                }
                              />
                            </div>
                          );
                        } else if (item?.type_of_notes === "3") {
                          return (
                            <div
                              className="w-full text-greyish py-3 relative"
                              key={`note-item${noteItemIndex}`}
                            >
                              {item?.url_link && (
                                <img
                                  src={item?.url_link}
                                  alt={"image" + (noteItemIndex + 1)}
                                  className="w-8/12 col-span-3 object-cover object-top"
                                />
                              )}
                              {item?.isLoading && <LoaderSpinner />}
                              <FiMinusCircle
                                className="text-white absolute top-5 -right-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNoteItem(noteItem, noteIndex, noteItemIndex)
                                }
                              />
                            </div>
                          );
                        } else if (item?.type_of_notes === "1") {
                          return (
                            <div
                              className="w-full text-greyish py-3 relative"
                              key={`note-item${noteItemIndex}`}
                            >
                              {item?.url_link && (
                                <video
                                  src={item?.url_link}
                                  alt={"video" + (noteItemIndex + 1)}
                                  controls
                                  className="w-8/12 max-w-full aspect-video object-cover object-top bg-greyishV3  border border-greyishV2"
                                />
                              )}
                              {item?.isLoading && <LoaderSpinner />}
                              <FiMinusCircle
                                className="text-white absolute top-5 -right-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNoteItem(noteItem, noteIndex, noteItemIndex)
                                }
                              />
                            </div>
                          );
                        } else if (item?.type_of_notes === "2") {
                          return (
                            <div
                              className="w-full grid grid-cols-3 gap-2 text-greyish py-3 relative"
                              key={`note-item${noteItemIndex}`}
                            >
                              <AudioCard url={item?.url_link} />
                              <FiMinusCircle
                                className="text-white absolute top-5 -right-1 cursor-pointer"
                                onClick={() =>
                                  handleDeleteNoteItem(noteItem, noteIndex, noteItemIndex)
                                }
                              />
                            </div>
                          );
                        }
                        return null;
                      })}

                      {noteIndex === currNoteIndex && (
                        <>
                          {(journalValues?.notes?.[currNoteIndex]?.noteitems?.length > 0 ||
                            journalValues?.notes?.[currNoteIndex]?.verse_start) && (
                            <div className="w-full border-b-2 border-greyishV2 mt-4"></div>
                          )}
                          <MyStatefulEditor
                            forceEditorUpdate={forceEditorUpdate}
                            labelColor="white"
                            labelText=""
                            darkMode={true}
                            placeholder="| What's God speaking to you?"
                            htmlFor="currNotesText"
                            name="currNotesText"
                            value={contents}
                            handleInputChange={(e) => {
                              setContents(e.target.value);
                            }}
                            minimalToolbar={true}
                            showToolbarOnFocus={true}
                          />
                        </>
                      )}
                    </div>
                    {noteIndex === currNoteIndex && (
                      <>
                        {!newVerseActive && (
                          <div className="w-full flex items-end justify-between">
                            <div className="flex items-center gap-3">
                              <div
                                className="border border-greyishV2 rounded-md py-[6px] px-3 cursor-pointer"
                                onClick={() => setIsWebcamActive(true)}
                              >
                                <img src={Camera} alt="Camera" />
                              </div>
                              <UploadAudioButton
                                handleAudioUpload={(e) => {
                                  if (contents !== "") {
                                    setJournalValues((prev) => {
                                      const updatedData = structuredClone(prev);
                                      updatedData.notes[currNoteIndex] = {
                                        ...(updatedData.notes[currNoteIndex] ?? {}),
                                        noteitems: [
                                          ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                                          { type_of_notes: "0", notes: contents },
                                        ],
                                      };
                                      return updatedData;
                                    });
                                    setContents("");
                                    setForceEditorUpdate(true);
                                    setTimeout(() => {
                                      setForceEditorUpdate(false);
                                    }, 500);
                                  }
                                  setJournalValues((prev) => {
                                    const itemIndex =
                                      prev?.notes?.[currNoteIndex]?.noteitems?.length;
                                    const updatedData = structuredClone(prev);
                                    updatedData.notes[currNoteIndex] = {
                                      ...(updatedData.notes[currNoteIndex] ?? {}),
                                      noteitems: [
                                        ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                                        { type_of_notes: "2", url_link: "", isLoading: true },
                                      ],
                                    };
                                    uploadAsset(currNoteIndex, itemIndex, e.target.files[0]);
                                    return updatedData;
                                  });
                                }}
                                handleAudioCapture={(file) => {
                                  if (contents !== "") {
                                    setJournalValues((prev) => {
                                      const updatedData = structuredClone(prev);
                                      updatedData.notes[currNoteIndex] = {
                                        ...(updatedData.notes[currNoteIndex] ?? {}),
                                        noteitems: [
                                          ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                                          { type_of_notes: "0", notes: contents },
                                        ],
                                      };
                                      return updatedData;
                                    });
                                    setContents("");
                                    setForceEditorUpdate(true);
                                    setTimeout(() => {
                                      setForceEditorUpdate(false);
                                    }, 500);
                                  }
                                  setJournalValues((prev) => {
                                    const itemIndex =
                                      prev?.notes?.[currNoteIndex]?.noteitems?.length;
                                    const updatedData = structuredClone(prev);
                                    updatedData.notes[currNoteIndex] = {
                                      ...(updatedData.notes[currNoteIndex] ?? {}),
                                      noteitems: [
                                        ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                                        { type_of_notes: "2", url_link: "", isLoading: true },
                                      ],
                                    };
                                    uploadAsset(currNoteIndex, itemIndex, file);
                                    return updatedData;
                                  });
                                }}
                              />
                              <div
                                className="flex items-center text-greyish gap-1 border border-greyishV2 rounded-md py-1 px-2 cursor-pointer"
                                onClick={() => setNewVerseActive(true)}
                              >
                                <img src={AddBtn} alt="AddBtn" />
                                <span className="hidden md:block">
                                  {currentLanguageTitles?.[0]?.bible?.verse_lbl ?? "Verse"}
                                </span>
                              </div>
                            </div>
                            <div
                              className="border border-yellowish px-4 md:px-8 py-[10px] cursor-pointer rounded-md"
                              onClick={() => {
                                handleSaveNote(noteIndex);
                              }}
                            >
                              <img src={RightTick} alt="RightTick" />
                            </div>
                          </div>
                        )}
                        {newVerseActive && (
                          <div className="w-full bg-greyishV2 p-3 md:p-8 rounded-2xl">
                            <div className="w-full flex items-center gap-4 pb-5 px-2">
                              <div className="w-full flex items-center bg-greyish gap-3 py-2 px-3 rounded-md">
                                <LuSearch className="text-2xl" />
                                <input
                                  type="text"
                                  value={searchTxt}
                                  placeholder={
                                    currentLanguageTitles?.[0]?.bible?.bible_search_placeholder ??
                                    "Search the Bible"
                                  }
                                  onChange={(e) => handleSearch(e)}
                                  className="w-full bg-greyish outline-none placeholder:text-blackish text-blackish text-lg"
                                />
                              </div>
                              <img
                                src={CloseIcon}
                                alt="CloseIcon"
                                className="h-5 cursor-pointer"
                                onClick={() => setNewVerseActive(false)}
                              />
                            </div>
                            <ul className="flex flex-col gap-1 flex-1 overflow-y-auto custom-scrollbar max-h-[30vh] pr-2">
                              {!isSearching &&
                                searchResults?.map((item, index) => {
                                  return (
                                    <div
                                      key={`search-item${index}`}
                                      className="py-2 hover:bg-black/30 rounded-md px-2 cursor-pointer text-white"
                                      onClick={() => {
                                        if (contents !== "") {
                                          setJournalValues((prev) => {
                                            const updatedData = structuredClone(prev);
                                            updatedData.notes[currNoteIndex] = {
                                              ...(updatedData.notes[currNoteIndex] ?? {}),
                                              noteitems: [
                                                ...(updatedData.notes[currNoteIndex]?.noteitems ??
                                                  []),
                                                { type_of_notes: "0", notes: contents },
                                              ],
                                            };
                                            return updatedData;
                                          });
                                          setContents("");
                                          setForceEditorUpdate(true);
                                          setTimeout(() => {
                                            setForceEditorUpdate(false);
                                          }, 500);
                                        }

                                        setJournalValues((prev) => {
                                          const updatedData = structuredClone(prev);
                                          updatedData.notes[currNoteIndex] = {
                                            ...(updatedData.notes[currNoteIndex] ?? {}),
                                            noteitems: [
                                              ...(updatedData.notes[currNoteIndex]?.noteitems ??
                                                []),
                                              {
                                                type_of_notes: "4",
                                                verse_start: item?.verse_start,
                                                verse_end: item?.verse_end,
                                                bookname: item?.book_name,
                                                bookid: item?.book_id,
                                                chapterid: item?.chapter,
                                                notes: item?.verse_text,
                                              },
                                            ],
                                          };
                                          return updatedData;
                                        });
                                        setNewVerseActive(false);
                                      }}
                                    >
                                      <p className="">{item?.verse_text}</p>
                                      <div className="w-full flex items-center justify-between text-greyishV3 text-sm">
                                        <p className="">
                                          {item?.book_name}{" "}
                                          <span className="">
                                            {item?.chapterid}:{item?.verse_start}
                                          </span>
                                        </p>
                                        <img
                                          src={AddButton}
                                          alt="plusButtonIcon"
                                          className="cursor-pointer"
                                        />
                                      </div>
                                    </div>
                                  );
                                })}
                              {isSearching && (
                                <li className="flex flex-col gap-2 items-center justify-center py-5 text-yellowish cursor-pointer">
                                  <LoaderSpinner />
                                  <p>Loading Results..</p>
                                </li>
                              )}
                              {!isSearching && searchResults?.length === 0 && (
                                <li className="flex flex-col gap-2 items-center justify-center py-10 text-yellowish cursor-pointer">
                                  <p>
                                    {currentLanguageTitles?.[0]?.bible?.no_results_lbl ??
                                      "No Results Found"}
                                  </p>
                                </li>
                              )}
                            </ul>
                          </div>
                        )}
                      </>
                    )}
                  </div>
                ))}

                <div
                  className="mt-5 border text-sm border-greyishV2 rounded-full rounded-tr-none py-3 px-5 flex items-center gap-3 cursor-pointer bg-blackish text-white"
                  onClick={addNote}
                >
                  <FaPlus />
                  <span>
                    {currentLanguageTitles?.[0]?.bible?.add_new_section_lbl ?? "Add New Section"}
                  </span>
                </div>
                {journalId && (
                  <div
                    className="mt-5 border text-sm border-red-500 rounded-full rounded-tr-none py-3 px-5 flex items-center gap-3 cursor-pointer text-white bg-red-500 justify-between"
                    onClick={handleDeleteJournal}
                  >
                    <span>
                      {currentLanguageTitles?.[0]?.bible?.delete_journal_lbl ?? "Delete Journal"}
                    </span>
                    <RiDeleteBin5Line className="text-lg" />
                  </div>
                )}
              </div>
            )}
          </div>
        </div>
      </ReactModal>
      {isWebcamActive && (
        <NotesWebcam
          addItem={(file, type) => {
            if (contents !== "") {
              setJournalValues((prev) => {
                const updatedData = structuredClone(prev);
                updatedData.notes[currNoteIndex] = {
                  ...(updatedData.notes[currNoteIndex] ?? {}),
                  noteitems: [
                    ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                    { type_of_notes: "0", notes: contents },
                  ],
                };
                return updatedData;
              });
              setContents("");
              setForceEditorUpdate(true);
              setTimeout(() => {
                setForceEditorUpdate(false);
              }, 500);
            }

            setJournalValues((prev) => {
              const itemIndex = prev?.notes?.[currNoteIndex]?.noteitems?.length;
              const updatedData = structuredClone(prev);
              updatedData.notes[currNoteIndex] = {
                ...(updatedData.notes[currNoteIndex] ?? {}),
                noteitems: [
                  ...(updatedData.notes[currNoteIndex]?.noteitems ?? []),
                  {
                    type_of_notes: type === "image" ? "3" : "1",
                    url_link: "",
                    isLoading: true,
                  },
                ],
              };
              uploadAsset(currNoteIndex, itemIndex, file);
              return updatedData;
            });
          }}
          isOpen={isWebcamActive}
          onClose={setIsWebcamActive}
        />
      )}
    </>
  );
};

export default NotesModel;
