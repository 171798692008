import React from "react";
import { useNavigate } from "react-router-dom";

const Authors = ({ data }) => {
  const navigate = useNavigate();

  return (
    <div className="w-full grid grid-cols-1 md:grid-cols-3 gap-10">
      {data?.map((item, index) => {
        return (
          <div
            key={`journal-author${index}`}
            className="flex items-center gap-5 cursor-pointer"
            onClick={() => navigate(`/author/${item?._id}`)}
          >
            <img
              src={item?.speakerDisplayImage}
              alt=""
              className="h-36 aspect-square object-cover rounded-xl"
            />
            <div className="flex flex-col gap-2">
              <h3 className="text-white font-semibold text-3xl first-">
                {item?.speakerDisplayName}
              </h3>
              <span className="text-white">{item?.speakerProfession}</span>
            </div>
          </div>
        );
      })}
      {data?.length === 0 && (
        <div className="w-full flex items-center justify-center h-[30vh]">
          No Authors Available
        </div>
      )}
    </div>
  );
};

export default Authors;
