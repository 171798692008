import { removeDuplicacyById } from "../../utils/Utils";
import * as actionTypes from "../ActionTypes";

const INITIAL = {
  categories: [],
  videos: [],
  curriculum: [],
  testimonials: [],
  journals: [],
  isMobile: false,
};

const commonReducer = (state = INITIAL, action) => {
  let newValues = null;
  switch (action.type) {
    case actionTypes.SET_GLOBAL_CATEGORY:
      return {
        ...state,
        categories: action.payload,
      };

    case actionTypes.ADD_GLOBAL_CATEGORY:
      newValues = removeDuplicacyById([...state.categories, ...action.payload]);
      return {
        ...state,
        categories: newValues,
      };

    case actionTypes.SET_GLOBAL_CURRICULUM:
      return {
        ...state,
        curriculum: action.payload,
      };

    case actionTypes.ADD_GLOBAL_CURRICULUM:
      newValues = removeDuplicacyById([...state.curriculum, ...action.payload]);
      return {
        ...state,
        curriculum: newValues,
      };

    case actionTypes.SET_GLOBAL_VIDEOS:
      return {
        ...state,
        videos: action.payload,
      };

    case actionTypes.ADD_GLOBAL_VIDEOS:
      newValues = removeDuplicacyById([...state.videos, ...action.payload]);
      return {
        ...state,
        videos: newValues,
      };

    case actionTypes.SET_GLOBAL_TESTIMONIALS:
      return {
        ...state,
        testimonials: action.payload,
      };

    case actionTypes.ADD_GLOBAL_TESTIMONIALS:
      newValues = removeDuplicacyById([...state.testimonials, ...action.payload]);
      return {
        ...state,
        testimonials: newValues,
      };

    case actionTypes.ADD_GLOBAL_JOURNALS:
      console.log('action.payload', action.payload)
      console.log('state.journals', state.journals)
      newValues = removeDuplicacyById([...state.journals, ...action.payload]);
      return {
        ...state,
        journals: newValues,
      };
    case actionTypes.SET_MOBILE_VIEW:
      return {
        ...state,
        isMobile: action.payload,
      };
    default:
      return state;
  }
};

export default commonReducer;
