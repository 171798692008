const SliderComp = ({
  value,
  handleChange,
  min = 0,
  max = 100,
  step = 1,
  small = false,
  vertical = false,
  className = "",
}) => {
  return (
    <div className={`grid place-items-center ${vertical ? "rotate-270 p-4" : ""} ${className}`}>
      <input
        className={`cursor-pointer accent-yellow-500 w-full ${small ? "h-[1px]" : "h-[4px]"} `}
        type="range"
        value={value}
        onChange={handleChange}
        max={max}
        min={min}
        step={step}
      />
    </div>
  );
};

export default SliderComp;
