import Axios from "../../utils/axios";

export const getBibleCountryLanguages = (payload) => {
  const URL = "/bible/countries";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleLanguages = (payload) => {
  const URL = "/bible/languages";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleVersions = (payload) => {
  const URL = "/bible/biblesforlanguage";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getVersionBooks = (payload) => {
  const URL = "/bible/bibledata";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterDetails = (payload) => {
  const URL = "/bible/biblebookdata";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleSearch = (payload) => {
  const URL = "/bible/search";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getBibleResourceKey = (payload) => {
  const URL = "/bible/key";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getUserJournals = (payload) => {
  const URL = "/journal/myjournals";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteUserJournals = (payload) => {
  const URL = "/journal/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getJournals = (payload) => {
  const URL = "/journal/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getVerseDetails = (payload) => {
  const URL = "/verse/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveVerseColor = (payload) => {
  const URL = "/user/verse/color/save";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getVerseColor = (payload) => {
  const URL = "/user/verse/color/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateVerseColor = (payload) => {
  const URL = "/user/verse/color/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const deleteVerseColor = (payload) => {
  const URL = "/user/verse/color/delete";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterVideos = (payload) => {
  const URL = "/chapter/twgvideos/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getChapterJournals = (payload) => {
  const URL = "/chapter/twgjournals/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const updateUserStreak = (payload) => {
  const URL = "/user/streak/update";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getUserStreakSummary = (payload) => {
  const URL = "/user/streak/summary";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getJournalSpeakers = (payload) => {
  const URL = "/journal/speaker/read";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getJournalsBySpeaker = (payload) => {
  const URL = "/journal/bySpeaker";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const getJournalsByCategory = (payload) => {
  const URL = "/journal/byCategory";
  return Axios.post(URL, payload).then((res) => res.data);
};

export const saveJournal = (payload) => {
  const URL = "/journal/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateJournal = (payload) => {
  const URL = "/journal/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteJournal = (payload) => {
  const URL = "/journal/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};


export const addJournalNote = (payload) => {
  const URL = "/journal/notes/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateJournalNote = (payload) => {
  const URL = "/journal/notes/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteJournalNote = (payload) => {
  const URL = "/journal/notes/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const addJournalNoteItem = (payload) => {
  const URL = "/journal/noteitems/save";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const updateJournalNoteItem = (payload) => {
  const URL = "/journal/noteitems/update";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const deleteJournalNoteItem = (payload) => {
  const URL = "/journal/noteitems/delete";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

export const bibleSummary = (payload) => {
  const URL = "/user/bible/summary";
  return Axios.post(URL, payload).then((res) => {
    return res.data;
  });
};

