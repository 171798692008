import { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import JournalsList from "../journals/JournalsList";
import SliderComp from "../../components/VideoPlayer/SliderComp";

import {
  getBibleResourceKeyAction,
  getChapterJournalsAction,
  getChapterVideosAction,
} from "../../store/Bible/BibleAction";

import { FaPlay } from "react-icons/fa";
import { LuVideo } from "react-icons/lu";
import { IoClose } from "react-icons/io5";
import { FaPause } from "react-icons/fa6";
import { FaCheck } from "react-icons/fa6";
import { FaChevronUp } from "react-icons/fa";
import { FaChevronDown } from "react-icons/fa";
import { MdClose, MdSkipPrevious } from "react-icons/md";
import { CgPlayTrackNext } from "react-icons/cg";
import { HiOutlineSpeakerWave } from "react-icons/hi2";

import personSpeakingIcon from "../../assets/icons/personSpeaking.svg";
import { useAuth0 } from "@auth0/auth0-react";

const AudioVideoCompModel = ({
  hideVideo = false,
  hideAudio = false,
  isTextAvailable,
  isAudioAvailable,
  isVideoAvailable,
  versionDetails,
  bookChapterAudioDetails,
  bookChapterAudioDetailsLoading,
  bookChapterVideoDetails,
  bookChapterVideoDetailsLoading,
  bibleParallelMode,
  verseDetails,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const { bookId, chapterNo } = useParams();
  const { isAuthenticated } = useAuth0();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  console.log("bookChapterAudioDetails", bookChapterAudioDetails);
  console.log("bookChapterAudioDetails verseDetails", verseDetails);

  const { bibleParallelMode: globalParallelMode } = useSelector((state) => state.bible);

  const audioPlayerRef = useRef(null);

  const [isVideoCompActive, setIsVideoCompActive] = useState(false);
  const [bibleResourceKey, setBibleResourceKey] = useState("");
  const [isAudioCompActive, setIsAudioCompActive] = useState(false);
  const [isAudioPlaying, setIsAudioPlaying] = useState(false);
  const [audioDuration, setAudioDuration] = useState(0);
  const [currentAudioProgress, setCurrentAudioProgress] = useState(0);
  const [showList, setShowList] = useState(false);
  const [activeTab, setActiveTab] = useState(0);
  const [activeSpeed, setActiveSpeed] = useState(1);
  const [selectedList, setSelectedList] = useState(0);
  const [twgVideos, setTwgVideos] = useState([]);
  const [twgVideosLoading, setTwgVideosLoading] = useState(false);
  const [chapterJournals, setChapterJournals] = useState([]);
  const [chapterJournalsLoading, setChapterJournalsLoading] = useState(false);

  useEffect(() => {
    if (versionDetails?.abbr) {
      setActiveTab(0);
    }
  }, [versionDetails]);

  const [videoTabs, setVideoTabs] = useState([]);

  useEffect(() => {
    const tabs = [];
    if (bookChapterVideoDetails?.length > 0) {
      tabs.push({ key: "videos_lbl", label: "Videos" });
    }
    if (twgVideos?.length > 0) {
      tabs.push({ key: "twg_content_lbl", label: "TWG Content" });
    }
    if (chapterJournals?.length > 0) {
      tabs.push({ key: "journals_lbl", label: "Journals" });
    }

    setVideoTabs(tabs);
  }, [bookChapterVideoDetails, twgVideos, chapterJournals]);

  console.log("videoTabs", videoTabs);
  console.log("bookChapterVideoDetails", bookChapterVideoDetails);
  console.log("twgVideos", twgVideos);
  console.log("chapterJournals", chapterJournals);
  console.log("globalParallelMode", globalParallelMode);

  const fetchBibleResourceKey = () => {
    dispatch(
      getBibleResourceKeyAction({
        callback: (response) => {
          setBibleResourceKey(response?.data?.[0]?.key);
        },
      })
    );
  };

  const fetchTWGContent = () => {
    setTwgVideosLoading(true);
    dispatch(
      getChapterVideosAction({
        apiPayloadRequest: {
          languageid: versionDetails?.language_id,
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (response) => {
          setTwgVideos(response?.data);
          setTwgVideosLoading(false);
        },
      })
    );
  };

  const fetchChapterJournals = () => {
    setChapterJournalsLoading(true);
    dispatch(
      getChapterJournalsAction({
        apiPayloadRequest: {
          languageid: versionDetails?.language_id,
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (response) => {
          setChapterJournals(response?.data);
          setChapterJournalsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchBibleResourceKey();
  }, []);

  useEffect(() => {
    if (versionDetails?.iso && versionDetails?.abbr && bookId && chapterNo && isAuthenticated) {
      fetchTWGContent();
      fetchChapterJournals();
    }
  }, [versionDetails?.iso, versionDetails?.abbr, bookId, chapterNo, isAuthenticated]);

  return (
    !bookChapterAudioDetailsLoading && (
      <>
        {(isTextAvailable || (isAudioAvailable && isVideoAvailable)) && (
          <div className="md:relative flex flex-col w-full">
            <div className="flex gap-3">
              {!hideVideo &&
                (bookChapterVideoDetails?.length > 0 ||
                  twgVideos?.length > 0 ||
                  chapterJournals?.length > 0) && (
                  <LuVideo
                    className="text-4xl text-yellowish cursor-pointer"
                    onClick={() => {
                      setIsVideoCompActive(true);
                      setIsAudioCompActive(false);
                    }}
                  />
                )}
              {!hideAudio && bookChapterAudioDetails?.length > 0 && (
                <HiOutlineSpeakerWave
                  className="text-white/80 text-3xl text-yellowish cursor-pointer"
                  onClick={() => setIsAudioCompActive((prev) => !prev)}
                />
              )}
            </div>
            {isVideoCompActive && (
              <div
                className={`w-[90vw] md:w-[700px] absolute -left-[15vw] md:-left-32 top-20 md:top-12 h-[60vh] bg-blackishV2/95 rounded-3xl rounded-ss-none overflow-hidden z-50 ${
                  bibleParallelMode ? "-translate-x-[65%]" : "translate-x-[15%]"
                }`}
              >
                <div className="w-full bg-blackishV2/95 backdrop-blur-2xl h-full overflow-y-auto custom-scrollbar grid grid-cols-12 p-4 md:p-10">
                  <div className="col-span-10">
                    <div className="flex flex-wrap gap-2 md:gap-4 border-b border-greyish pb-4">
                      {videoTabs?.map((tab, index) => (
                        <span
                          key={`video-tabitem${index}`}
                          className={`rounded-full border ${
                            activeTab === index
                              ? "text-yellowish border-yellowish"
                              : "border-greyish text-greyish"
                          } py-1 px-5 cursor-pointer`}
                          onClick={() => setActiveTab(index)}
                        >
                          {currentLanguageTitles?.[0]?.bible?.[tab?.key] ?? tab?.label}
                        </span>
                      ))}
                    </div>
                    {videoTabs?.map((tab, index) => {
                      return activeTab === index &&
                        tab.key === "videos_lbl" &&
                        bookChapterVideoDetails?.length > 0 ? (
                        <div className="py-5">
                          {bookChapterVideoDetails?.map((item, index) => {
                            return (
                              <div
                                className="py-5 border-b border-greyish cursor-pointer"
                                key={`chapter-video${index}`}
                                onClick={() => {
                                  navigate("/video-player", {
                                    state: {
                                      url: `${item?.path}?v=4&key=${bibleResourceKey}`,
                                      bible: true,
                                      title:
                                        `${item?.book_name} ${item?.chapter_start}:${item?.verse_start}` +
                                        (item?.chapter_end
                                          ? `- ${item?.chapter_end}:${item?.verse_end}`
                                          : item?.verse_end &&
                                            `- ${item?.chapter_start}:${item?.verse_end}`),
                                      _id: item?.book_id,
                                      image: item?.thumbnail,
                                    },
                                  });
                                }}
                              >
                                <div
                                  className="px-5 flex items-center justify-center h-44 aspect-[16/9] bg-cover cursor-pointer rounded-md"
                                  style={{ backgroundImage: `url(${item?.thumbnail})` }}
                                >
                                  <FaPlay className="z-20 text-3xl" />
                                </div>
                                <h3 className="text-xl font-semibold py-2">
                                  {item?.book_name} {item?.chapter_start}:{item?.verse_start}{" "}
                                  {item?.chapter_end
                                    ? `- ${item?.chapter_end}:${item?.verse_end}`
                                    : item?.verse_end &&
                                      `- ${item?.chapter_start}:${item?.verse_end}`}
                                </h3>
                              </div>
                            );
                          })}
                        </div>
                      ) : activeTab === index && tab.key === "twg_content_lbl" ? (
                        <div className="w-full py-5">
                          {twgVideosLoading ? (
                            <div className="w-full flex flex-col gap-4 justify-center">
                              <div className="w-full grid grid-cols-12 gap-5">
                                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                                <div className="col-span-9 flex flex-col gap-2">
                                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                                  <div className="w-full h-16 animate-pulse bg-zinc-600 rounded-2xl"></div>
                                </div>
                              </div>
                              <div className="w-full grid grid-cols-12 gap-5">
                                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                                <div className="col-span-9 flex flex-col gap-2">
                                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                                  <div className="w-full h-16 animate-pulse bg-zinc-600 rounded-2xl"></div>
                                </div>
                              </div>
                            </div>
                          ) : (
                            twgVideos?.map((item, index) => {
                              const thumbnail = item?.tumbnailurls?.find(
                                (d) => d?.type_of_url === "1"
                              );
                              const urls = item?.urls?.find((d) => d?.type_of_url === "1");
                              return (
                                <div
                                  className="py-5 border-b border-greyish gap-5 grid grid-cols-12 items-center w-full overflow-visible"
                                  key={`twg-video${index}`}
                                >
                                  <div
                                    className="flex items-center justify-center bg-cover cursor-pointer rounded-2xl aspect-video md:aspect-square col-span-12 md:col-span-3 bg-top"
                                    style={{ backgroundImage: `url(${thumbnail?.url_link})` }}
                                    onClick={() => {
                                      navigate("/video-player", {
                                        state: {
                                          url: urls?.url_link,
                                          title: item?.video_title,
                                          _id: item?._id,
                                          image: thumbnail?.url_link,
                                        },
                                      });
                                    }}
                                  >
                                    <FaPlay className="z-20 text-3xl" />
                                  </div>
                                  <div className="col-span-12 md:col-span-9">
                                    <h3 className="text-xl font-semibold pb-2">
                                      {item?.video_title}
                                    </h3>
                                    <p className="text-sm text-greyish overflow-hidden overflow-ellipsis line-clamp-4">
                                      {item?.video_description}
                                    </p>
                                  </div>
                                </div>
                              );
                            })
                          )}
                        </div>
                      ) : (
                        activeTab === index &&
                        tab.key === "journals_lbl" && (
                          <div className="w-full py-5 h-full">
                            {chapterJournalsLoading ? (
                              <div className="grid grid-cols-3 gap-3">
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ss-none"></div>
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ss-none"></div>
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-es-none"></div>
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ee-none"></div>
                                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-es-none"></div>
                              </div>
                            ) : (
                              <JournalsList gridItems={2} data={chapterJournals} chapter={true} />
                            )}
                          </div>
                        )
                      );
                    })}
                  </div>
                  <div className="col-span-2 flex justify-end">
                    <IoClose
                      className="text-yellowish cursor-pointer text-3xl "
                      onClick={() => {
                        setIsVideoCompActive(false);
                        setActiveTab(0);
                      }}
                    />
                  </div>
                </div>
              </div>
            )}
            {isAudioCompActive && (
              <div
                className={`absolute left-5 md:-left-[50%] top-12 md:top-3 z-50 ${
                  bibleParallelMode ? "translate-x-[65%]" : ""
                }`}
              >
                <div
                  className={`${
                    showList ? "md:w-[400px]" : "w-[300px]"
                  } bg-greyishV2 flex flex-col items-center rounded-[40px] py-2 mt-10 relative`}
                >
                  <MdClose
                    className="absolute top-2 right-5 text-yellowish cursor-pointer z-50"
                    onClick={() => setIsAudioCompActive(false)}
                  />

                  <div className="w-full flex flex-col items-center relative">
                    {showList && (
                      <div
                        className="bg-greyishV2 p-1 text-yellowish absolute -top-4 rounded-t-[3rem] cursor-pointer"
                        onClick={() => setShowList(false)}
                      >
                        <FaChevronUp />
                      </div>
                    )}
                    <div className="flex items-center gap-10 text-3xl text-yellowish pt-2">
                      <MdSkipPrevious
                        onClick={() => {
                          if (bookChapterAudioDetails?.length > 1) {
                            setSelectedList(
                              selectedList === 0
                                ? bookChapterAudioDetails?.length - 1
                                : selectedList - 1
                            );
                          }
                        }}
                      />{" "}
                      <button
                        onClick={() => {
                          setIsAudioPlaying((prev) => !prev);
                          if (isAudioPlaying) {
                            audioPlayerRef.current.pause();
                          } else {
                            audioPlayerRef.current.play();
                          }
                        }}
                      >
                        {isAudioPlaying ? <FaPause /> : <FaPlay />}
                      </button>{" "}
                      <CgPlayTrackNext
                        onClick={() => {
                          if (bookChapterAudioDetails?.length > 1) {
                            setSelectedList(
                              selectedList === bookChapterAudioDetails?.length - 1
                                ? 0
                                : selectedList + 1
                            );
                          }
                        }}
                      />
                    </div>
                    <audio
                      src={bookChapterAudioDetails?.[selectedList]?.path}
                      ref={audioPlayerRef}
                      onLoadedMetadata={() => {
                        if (audioPlayerRef.current) {
                          setAudioDuration(audioPlayerRef.current.duration);
                          audioPlayerRef.current.playbackRate = activeSpeed;
                        }
                      }}
                      onTimeUpdate={() => {
                        setCurrentAudioProgress(audioPlayerRef.current.currentTime / audioDuration);
                      }}
                      onPause={() => setIsAudioPlaying(false)}
                    ></audio>

                    <SliderComp
                      handleChange={(event) => {
                        if (audioPlayerRef?.current) {
                          const playedSeconds = parseFloat(
                            (audioDuration * (event.target.value / 100)).toFixed(2)
                          );
                          audioPlayerRef.current.currentTime = playedSeconds;
                          setCurrentAudioProgress(parseFloat(event.target.value / 100));
                        }
                      }}
                      value={currentAudioProgress * 100}
                      step={1}
                      className="my-3 w-10/12"
                    />
                    {!showList && (
                      <div
                        className="bg-greyishV2 text-yellowish absolute -bottom-4 rounded-full cursor-pointer"
                        onClick={() => setShowList(true)}
                      >
                        <FaChevronDown />
                      </div>
                    )}
                  </div>

                  {showList && (
                    <div className="w-full px-6 md:px-10">
                      <div className="w-full flex gap-2 items-center justify-center py-2">
                        {[0.75, 1, 1.25, 1.5, 1.75, 2]?.map((item, index) => (
                          <p
                            key={`audio-speed${index}`}
                            className={`p-1 cursor-pointer ${
                              activeSpeed === item
                                ? "text-blackish bg-yellowish rounded-md font-semibold cursor-pointer px-2"
                                : "text-greyish text-sm"
                            }`}
                            onClick={() => {
                              setActiveSpeed(item);
                              if (audioPlayerRef.current) {
                                audioPlayerRef.current.playbackRate = item; // Update playback speed
                              }
                            }}
                          >
                            {item}x
                          </p>
                        ))}
                      </div>
                      <div className="w-full flex flex-col gap-2 my-2 bg-greyishV5 max-h-[200px] overflow-y-auto custom-scrollbar mx-auto p-5 rounded-3xl">
                        {bookChapterAudioDetails?.map((item, index) => {
                          return (
                            <div
                              className={`px-2 py-0.5 flex items-center justify-between rounded-md gap-3 cursor-pointer ${
                                selectedList === index
                                  ? "bg-yellowish text-blackish scale-x-10 px-2"
                                  : "text-greyish"
                              }`}
                              key={index}
                              onClick={() => {
                                if (audioPlayerRef.current) audioPlayerRef.current.pause();
                                setSelectedList(index);
                                setCurrentAudioProgress(0);
                                setIsAudioPlaying(false);
                              }}
                            >
                              <div className="flex items-center gap-2">
                                {selectedList === index && (
                                  <img
                                    src={personSpeakingIcon}
                                    alt=""
                                    className="w-[18px] object-contain"
                                  />
                                )}
                                <div className="flex flex-col">
                                  <p
                                    className={`text-sm ${
                                      selectedList === index ? "font-semibold" : ""
                                    }`}
                                  >
                                    {verseDetails?.name}
                                  </p>
                                  <span
                                    className={`text-xs text-black/70 ${
                                      selectedList === index ? "font-semibold" : ""
                                    }`}
                                  >
                                    {item?.type === "audio_drama" ? "Audio Drama" : "Audio"}
                                  </span>
                                </div>
                              </div>

                              {selectedList === index && <FaCheck className="text-xl" />}
                            </div>
                          );
                        })}
                      </div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </div>
        )}
        {!isTextAvailable && !isVideoAvailable && isAudioAvailable && (
          <div className="relative flex flex-col">
            <div className="w-[400px] bg-greyishV2 flex flex-col items-center rounded-[40px] py-2 mt-10 relative">
              <div className="w-full flex flex-col items-center relative">
                <div className="flex items-center gap-10 text-3xl text-yellowish pt-2">
                  <MdSkipPrevious
                    onClick={() => {
                      if (bookChapterAudioDetails?.length > 1) {
                        setSelectedList(
                          selectedList === 0
                            ? bookChapterAudioDetails?.length - 1
                            : selectedList - 1
                        );
                      }
                    }}
                  />{" "}
                  <button
                    onClick={() => {
                      setIsAudioPlaying((prev) => !prev);
                      if (isAudioPlaying) {
                        audioPlayerRef.current.pause();
                      } else {
                        audioPlayerRef.current.play();
                      }
                    }}
                  >
                    {isAudioPlaying ? <FaPause /> : <FaPlay />}
                  </button>{" "}
                  <CgPlayTrackNext
                    onClick={() => {
                      if (bookChapterAudioDetails?.length > 1) {
                        setSelectedList(
                          selectedList === bookChapterAudioDetails?.length - 1
                            ? 0
                            : selectedList + 1
                        );
                      }
                    }}
                  />
                </div>
                <audio
                  src={bookChapterAudioDetails?.[selectedList]?.path}
                  ref={audioPlayerRef}
                  onLoadedMetadata={() => {
                    if (audioPlayerRef.current) {
                      setAudioDuration(audioPlayerRef.current.duration);
                      audioPlayerRef.current.playbackRate = activeSpeed;
                    }
                  }}
                  onTimeUpdate={() => {
                    setCurrentAudioProgress(audioPlayerRef.current.currentTime / audioDuration);
                  }}
                  onPause={() => setIsAudioPlaying(false)}
                ></audio>

                <SliderComp
                  handleChange={(event) => {
                    if (audioPlayerRef?.current) {
                      const playedSeconds = parseFloat(
                        (audioDuration * (event.target.value / 100)).toFixed(2)
                      );
                      audioPlayerRef.current.currentTime = playedSeconds;
                      setCurrentAudioProgress(parseFloat(event.target.value / 100));
                    }
                  }}
                  value={currentAudioProgress * 100}
                  step={1}
                  className="my-3 w-10/12"
                />
              </div>

              <div className="w-full px-10">
                <div className="w-full flex gap-2 items-center justify-center py-2">
                  {[0.75, 1, 1.25, 1.5, 1.75, 2]?.map((item, index) => (
                    <p
                      key={`audio-speed${index}`}
                      className={`p-1 cursor-pointer ${
                        activeSpeed === item
                          ? "text-blackish bg-yellowish rounded-md font-semibold cursor-pointer px-2"
                          : "text-greyish text-sm"
                      }`}
                      onClick={() => {
                        setActiveSpeed(item);
                        if (audioPlayerRef.current) {
                          audioPlayerRef.current.playbackRate = item; // Update playback speed
                        }
                      }}
                    >
                      {item}x
                    </p>
                  ))}
                </div>
                <div className="w-full flex flex-col gap-2 my-2 bg-greyishV5 max-h-[200px] overflow-y-auto custom-scrollbar mx-auto p-5 rounded-3xl">
                  {bookChapterAudioDetails?.map((item, index) => {
                    return (
                      <div
                        className={`px-2 py-0.5 flex items-center justify-between rounded-md gap-3 cursor-pointer ${
                          selectedList === index
                            ? "bg-yellowish text-blackish scale-x-10 px-2"
                            : "text-greyish"
                        }`}
                        key={index}
                        onClick={() => {
                          if (audioPlayerRef.current) audioPlayerRef.current.pause();
                          setSelectedList(index);
                          setCurrentAudioProgress(0);
                          setIsAudioPlaying(false);
                        }}
                      >
                        <div className="flex items-center gap-2">
                          {selectedList === index && (
                            <img
                              src={personSpeakingIcon}
                              alt=""
                              className="w-[18px] object-contain"
                            />
                          )}
                          <div className="flex flex-col">
                            <p
                              className={`text-sm ${selectedList === index ? "font-semibold" : ""}`}
                            >
                              {verseDetails?.vname}
                            </p>
                            <span
                              className={`text-xs text-black/70 ${
                                selectedList === index ? "font-semibold" : ""
                              }`}
                            >
                              {item?.filesetDetail?.type === "audio_drama"
                                ? "Audio Drama"
                                : "Audio"}
                            </span>
                          </div>
                        </div>

                        {selectedList === index && <FaCheck className="text-xl" />}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          </div>
        )}
        {!isTextAvailable && !isAudioAvailable && isVideoAvailable && (
          <div
            className={`h-[60vh] bg-blackishV2/95 rounded-3xl rounded-ss-none overflow-hidden ${
              globalParallelMode ? "w-full" : "w-full"
            }`}
          >
            <div className="w-full bg-blackishV2/95 backdrop-blur-2xl h-full overflow-y-auto custom-scrollbar grid grid-cols-12 p-10">
              <div className="col-span-10">
                <div className="flex flex-wrap gap-4 border-b border-greyish pb-4 ">
                  {videoTabs?.map((tab, index) => (
                    <span
                      key={`video-tabitem${index}`}
                      className={`rounded-full border ${
                        activeTab === index
                          ? "text-yellowish border-yellowish"
                          : "border-greyish text-greyish"
                      } py-1 px-5 cursor-pointer`}
                      onClick={() => setActiveTab(index)}
                    >
                      {tab}
                    </span>
                  ))}
                </div>
                {videoTabs?.map((tab, index) => {
                  return activeTab === index &&
                    tab === "Videos" &&
                    bookChapterVideoDetails?.length > 0 ? (
                    <div className="py-5">
                      {bookChapterVideoDetails?.map((item, index) => {
                        return (
                          <div
                            className="py-5 border-b border-greyish cursor-pointer"
                            key={`chapter-video${index}`}
                            onClick={() => {
                              navigate("/video-player", {
                                state: {
                                  url: `${item?.path}?v=4&key=${bibleResourceKey}`,
                                  bible: true,
                                  title:
                                    `${item?.book_name} ${item?.chapter_start}:${item?.verse_start}` +
                                    (item?.chapter_end
                                      ? `- ${item?.chapter_end}:${item?.verse_end}`
                                      : item?.verse_end &&
                                        `- ${item?.chapter_start}:${item?.verse_end}`),
                                  _id: item?.book_id,
                                  image: item?.thumbnail,
                                },
                              });
                            }}
                          >
                            <div
                              className="px-5 flex items-center justify-center h-44 aspect-[16/9] bg-cover cursor-pointer rounded-md"
                              style={{ backgroundImage: `url(${item?.thumbnail})` }}
                            >
                              <FaPlay className="z-20 text-3xl" />
                            </div>
                            <h3 className="text-xl font-semibold py-2">
                              {item?.book_name} {item?.chapter_start}:{item?.verse_start}{" "}
                              {item?.chapter_end
                                ? `- ${item?.chapter_end}:${item?.verse_end}`
                                : item?.verse_end && `- ${item?.chapter_start}:${item?.verse_end}`}
                            </h3>
                          </div>
                        );
                      })}
                    </div>
                  ) : activeTab === index && tab === "TWG Content" ? (
                    <div className="w-full py-5">
                      {twgVideosLoading ? (
                        <div className="w-full flex flex-col gap-4 justify-center">
                          <div className="w-full grid grid-cols-12 gap-5">
                            <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                            <div className="col-span-9 flex flex-col gap-2">
                              <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                              <div className="w-full h-16 animate-pulse bg-zinc-600 rounded-2xl"></div>
                            </div>
                          </div>
                          <div className="w-full grid grid-cols-12 gap-5">
                            <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                            <div className="col-span-9 flex flex-col gap-2">
                              <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                              <div className="w-full h-16 animate-pulse bg-zinc-600 rounded-2xl"></div>
                            </div>
                          </div>
                        </div>
                      ) : (
                        twgVideos?.map((item, index) => {
                          const thumbnail = item?.tumbnailurls?.find((d) => d?.type_of_url === "1");
                          const urls = item?.urls?.find((d) => d?.type_of_url === "1");
                          return (
                            <div
                              className="py-5 border-b border-greyish gap-5 grid grid-cols-12 items-center w-full overflow-visible"
                              key={`twg-video${index}`}
                            >
                              <div
                                className="flex items-center justify-center bg-cover cursor-pointer rounded-2xl aspect-square col-span-3 bg-top"
                                style={{ backgroundImage: `url(${thumbnail?.url_link})` }}
                                onClick={() => {
                                  navigate("/video-player", {
                                    state: {
                                      url: urls?.url_link,
                                      title: item?.video_title,
                                      _id: item?._id,
                                      image: thumbnail?.url_link,
                                    },
                                  });
                                }}
                              >
                                <FaPlay className="z-20 text-3xl" />
                              </div>
                              <div className="col-span-9">
                                <h3 className="text-xl font-semibold pb-2">{item?.video_title}</h3>
                                <p className="text-sm text-greyish overflow-hidden overflow-ellipsis line-clamp-4">
                                  {item?.video_description}
                                </p>
                              </div>
                            </div>
                          );
                        })
                      )}
                    </div>
                  ) : (
                    activeTab === index &&
                    tab === "Journals" && (
                      <div className="w-full py-5 h-full">
                        {chapterJournalsLoading ? (
                          <div className="grid grid-cols-3 gap-3">
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ss-none"></div>
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ss-none"></div>
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-es-none"></div>
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-ee-none"></div>
                            <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-es-none"></div>
                          </div>
                        ) : (
                          <JournalsList gridItems={3} data={chapterJournals} chapter={true} />
                        )}
                      </div>
                    )
                  );
                })}
              </div>
            </div>
          </div>
        )}
      </>
    )
  );
};

export default AudioVideoCompModel;
