import React from "react";
import { toast } from "react-toastify";

const Toastiy = (message, code) => {
  if (code === 1) {
    toast.success(message);
  } else {
    toast.error(message);
  }
};

export default Toastiy;
