import React, { useRef, useState } from "react";
import { useSelector } from "react-redux";
import { FaRegCircle } from "react-icons/fa";
import { FaChevronLeft } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

import plan4 from "../../assets/images/plan4.png";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import FooterBar from "../../components/common/FooterBar";
import { newToFaithPlanArray } from "../../constants/common";
import CommonCarousel from "../../components/home/CommonCarousel";
import ChapterDetails from "../verses/ChapterDetails";
import AudioVideoCompModel from "../verses/AudioVideoCompModel";
import { Keyboard, Mousewheel, Navigation } from "swiper/modules";
import { SwiperSlide, Swiper } from "swiper/react";

const PlanDetails = () => {
  const navigate = useNavigate();
  const [planStarted, setPlanStarted] = useState(false);
  const [showVerses, setShowVerses] = useState(false);
  const [activeLecture, setActiveLecture] = useState(1);
  const { isMobile } = useSelector((state) => state.common);
  const sliderRef = useRef();

  const [currentSlide, setCurrentSlide] = useState(0);
  const [slideActive, setSlideActive] = useState(false);
  const handleSlideChange = (swiper) => {
    setSlideActive(true);
    setCurrentSlide(swiper.activeIndex); // Update the current slide index
  };

  const handleSlideClick = (index) => {
    setSlideActive(true);
    setCurrentSlide(index);
    console.log("sliderRef.current", sliderRef.current);
    sliderRef.current?.slideTo(index, 500);
  };

  return (
    <div className="flex">
      <div className="flex w-full md:flex-row flex-col overflow-hidden">
        <Navbar active={"Plans"} />
        <div className="md:ml-[104px] flex flex-col w-full bg-black">
          <HeaderBar
            popCancle={true}
            tabValues={[]}
            active={0}
            absolute={false}
            handleTabChange={() => {}}
            getLanguagePreferred={() => {}}
            getCurrentLanguageTitles={() => {}}
          />
          <div className="flex flex-col bg-black h-full text-white overflow-y-auto w-[92vw] max-w-full px-7 py-5">
            {!planStarted && (
              <div>
                <div className="w-[30%]">
                  <div className="flex items-center text-yellowish text-3xl gap-3 font-semibold">
                    <FaChevronLeft
                      className="text-2xl cursor-pointer"
                      onClick={() => navigate(-1)}
                    />
                    <h2>The lost and fund</h2>
                  </div>
                  <div>
                    <img
                      src={plan4}
                      alt=""
                      className="w-full object-cover object-top rounded-xl overflow-hidden"
                    />
                    <div className="flex items-center justify-between">
                      <button
                        className="bg-yellowish py-1 px-10 rounded-full text-blackish font-semibold"
                        onClick={() => setPlanStarted(true)}
                      >
                        Start Plan
                      </button>
                      <span className="text-greyish">5 Days</span>
                    </div>
                    <p className="py-5">
                      This plan takes you on a journey through all four Gospels
                      in ninety days. Each book includes videos specifically
                      designed to enhance your understanding and engagement with
                      Scripture.
                    </p>
                  </div>
                </div>
                <div className="py-10">
                  <CommonCarousel
                    data={newToFaithPlanArray}
                    seeAll={true}
                    dataTitle={false}
                    dynamiclinkTo={true}
                    perViewImages={isMobile ? 2 : 8}
                    imageType={"landscape"}
                    title="Similar Plans"
                    showTitle={true}
                    showItemTitle={true}
                    isPlan={true}
                  />
                </div>
              </div>
            )}
            {planStarted && (
              <div className="w-full flex gap-20 px-16">
                <div className="w-[70%]">
                  <div className="flex items-center text-yellowish text-3xl gap-3 font-semibold">
                    <FaChevronLeft
                      className="text-2xl cursor-pointer"
                      onClick={() => setPlanStarted(false)}
                    />
                    <h2>The lost and fund</h2>
                  </div>
                  <div>
                    <img
                      src={plan4}
                      alt=""
                      className="w-full object-cover object-top rounded-xl overflow-hidden"
                    />
                    <div className="flex items-center gap-3">
                      <Swiper
                        grabCursor={true}
                        slidesPerView={"auto"}
                        spaceBetween={10}
                        centeredSlides={false}
                        className="h-[80px] mb-2 relative custom-arrow"
                        modules={[Keyboard, Mousewheel, Navigation]}
                        onSlideChange={handleSlideChange}
                        navigation={true}
                        
                        onSwiper={(swiper) => (sliderRef.current = swiper)}
                      >
                        {Array.from({ length: 10 })?.map((_, index) => (
                          <SwiperSlide
                            key={index}
                            className={`!w-fit ${
                              index === currentSlide
                                ? "text-[#F8BA34] text-xl my-0 py-1"
                                : "text-[#A3A3A3] text-xs py-2"
                            } `}
                          >
                            <div
                              className={`bg-greyishV2 flex flex-col items-center justify-center py-1 px-2 rounded-md cursor-pointer ${
                                currentSlide === index
                                  ? "border border-yellowish"
                                  : ""
                              }`}
                              onClick={() => handleSlideClick(index)}
                            >
                              <span className="text-xl font-semibold">
                                {index + 1}
                              </span>
                              <p className="text-sm text-greyish">
                                Jan {index + 15}
                              </p>
                            </div>
                          </SwiperSlide>
                        ))}
                      </Swiper>
                    </div>
                    <div>
                      <p className="font-semibold text-xl py-5">
                        Day {activeLecture} of 5
                      </p>
                      <div className="flex flex-col gap-3">
                        <div className="flex items-center gap-3">
                          {/* <FaRegCircle className="text-greyish" /> */}
                          <input
                            type="radio"
                            className=""
                            onClick={() => setShowVerses(true)}
                          />
                          <span>Luke 15:22</span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="w-full flex items-start gap-10">
                  {showVerses && (
                    <div className="w-full flex items-start">
                      <ChapterDetails versesId={1} className="!w-full" />
                      <AudioVideoCompModel hideVideo={true} />
                    </div>
                  )}
                </div>
              </div>
            )}
            <FooterBar />
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanDetails;
