import React from "react";
import { FaStar } from "react-icons/fa";
import { newToFaithPlanArray } from "../../constants/common";

const MyPlans = ({filterStatus}) => {
  return (
    <div className="w-full flex items-start justify-between py-10">
      <div className="">
        <h3 className="text-3xl font-bold py-5">New to Faith</h3>
        {filterStatus !== "My Plans" && newToFaithPlanArray?.filter((d)=>d?.status === filterStatus)?.map((item, index) => {
          return (
            <div className="w-full flex items-center gap-20 py-4">
              <div className="w-full flex items-center gap-5">
                <img
                  src={item?.image}
                  alt=""
                  className="h-32 aspect-square rounded-md"
                />
                <div className="w-full">
                  <p className="text-greyish">{item?.duration}</p>
                  <span className="text-2xl">{item?.name}</span>
                  <div className="w-full flex items-center">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
              <button
                className={`${
                  item?.status === "In progress"
                    ? "text-yellowish border-yellowish"
                    : "text-greyish border-greyish"
                } border w-48 py-1 rounded-full text-nowrap`}
              >
                {item?.status}
              </button>
            </div>
          );
        })}
        {filterStatus === "My Plans" && newToFaithPlanArray?.map((item, index) => {
          return (
            <div className="w-full flex items-center gap-20 py-4">
              <div className="w-full flex items-center gap-5">
                <img
                  src={item?.image}
                  alt=""
                  className="h-32 aspect-square rounded-md"
                />
                <div className="w-full">
                  <p className="text-greyish">{item?.duration}</p>
                  <span className="text-2xl">{item?.name}</span>
                  <div className="w-full flex items-center">
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                    <FaStar />
                  </div>
                </div>
              </div>
              <button
                className={`${
                  item?.status === "In progress"
                    ? "text-yellowish border-yellowish"
                    : "text-greyish border-greyish"
                } border w-48 py-1 rounded-full text-nowrap`}
              >
                {item?.status}
              </button>
            </div>
          );
        })}
      </div>
      <div className="text-yellowish py-10">See All</div>
    </div>
  );
};

export default MyPlans;
