export const APP_LOADING = "APP_LOADING";
export const USER_FAV = "USER_FAV";
export const USER_UN_FAV = "USER_UN_FAV";
export const GET_ACHIEVEMENTS = "GET_ACHIEVEMENTS";
export const LANGUAGE_PREFERRED = "LANGUAGE_PREFERRED";
export const LANGUAGE_DATA = "LANGUAGE_DATA";
export const LANGUAGE_LIST = "LANGUAGE_LIST";
export const LANGUAGE_CURRENT = "LANGUAGE_CURRENT";
export const DASHBOARD_ACTION_GET_NEW_DATA = "DASHBOARD_ACTION_GET_NEW_DATA";
export const LOGIN_HARD_BLOCKER = "LOGIN_HARD_BLOCKER";
export const SET_MOBILE_VIEW = "SET_MOBILE_VIEW";
export const HOVERED_ACTIVE_ITEM = "HOVERED_ACTIVE_ITEM";
export const MAIN_CAROUSEL_DATA = "MAIN_CAROUSEL_DATA";

//Login and Sign Up
export const SIGN_UP_REQUEST = "SIGN_UP_REQUEST";
export const LOGIN_REQUEST = "LOGIN_REQUEST";
export const EMAIL_VERIFICATION_REQUEST = "EMAIL_VERIFICATION_REQUEST";
export const RESEND_VERIFICATION_REQUEST = "RESEND_VERIFICATION_REQUEST";

//Categories
export const CATEGORIES_READ_REQUEST = "CATEGORIES_READ_REQUEST";
export const CATEGORIES_TESTIMONY_SAVE_REQUEST = "CATEGORIES_TESTIMONY_SAVE_REQUEST";
export const CATEGORIES_TESTIMONY_UPDATE_REQUEST = "CATEGORIES_TESTIMONY_UPDATE_REQUEST";
export const CATEGORIES_TESTIMONY_DELETE_REQUEST = "CATEGORIES_TESTIMONY_DELETE_REQUEST";
export const READ_LAYOUTS_REQUEST = "READ_LAYOUTS_REQUEST";

//Home
export const SET_USER_PROFILE_DETAILS = "SET_USER_PROFILE_DETAILS";
export const GET_PROFILE_REQUEST = "GET_PROFILE_REQUEST";
export const READ_LANGUAGE_REQUEST = "READ_LANGUAGE_REQUEST";
export const READ_FAV_REQUEST = "READ_FAV_REQUEST";
export const GET_LANGUAGE_LIST_REQUEST = "GET_LANGUAGE_LIST_REQUEST";
export const GET_PICK_WHERE_LEFT_REQUEST = "GET_PICK_WHERE_LEFT_REQUEST";
export const SAVE_PICK_WHERE_LEFT_REQUEST = "SAVE_PICK_WHERE_LEFT_REQUEST";
export const SAVE_USER_PROFILE_REQUEST = "SAVE_USER_PROFILE_REQUEST";
export const UPDATE_USER_PROFILE_REQUEST = "UPDATE_USER_PROFILE_REQUEST";
export const LAYOUT_HOME_SCREEN_REQUEST = "LAYOUT_HOME_SCREEN_REQUEST";
export const LAYOUT_MAIN_CAROUSEL_REQUEST = "LAYOUT_MAIN_CAROUSEL_REQUEST";
export const LAYOUT_TOP10_REQUEST = "LAYOUT_TOP10_REQUEST";
export const READ_VIDEO_REQUEST = "READ_VIDEO_REQUEST";
export const UPLOAD_ASSEST_REQUEST = "UPLOAD_ASSEST_REQUEST";
export const GET_PROMISE_OF_THE_DAY_REQUEST = "GET_PROMISE_OF_THE_DAY_REQUEST";
export const GET_PROMISE_REQUEST = "GET_PROMISE_REQUEST";

export const CURRICULUM_READ_REQUEST = "CURRICULUM_READ_REQUEST";
export const CURRICULUM_HOME_READ_REQUEST = "CURRICULUM_HOME_READ_REQUEST";

export const SAVE_SUPPORT_REQUEST = "SAVE_SUPPORT_REQUEST";
export const SAVE_PRAYER_REQUEST = "SAVE_PRAYER_REQUEST";

//Testimonial
export const TESTIMONIAL_READ_REQUEST = "TESTIMONIAL_READ_REQUEST";
export const TESTIMONY_OF_THE_DAY_READ_REQUEST = "TESTIMONY_OF_THE_DAY_READ_REQUEST";
export const TESTIMONIAL_SAVE_REQUEST = "TESTIMONIAL_SAVE_REQUEST";
export const TESTIMONIAL_CATEGORY_SAVE_REQUEST = "TESTIMONIAL_CATEGORY_SAVE_REQUEST";
export const TESTIMONIAL_CATEGORY_READ_REQUEST = "TESTIMONIAL_CATEGORY_READ_REQUEST";
export const TESTIMONIAL_READ_TRENDING_REQUEST = "TESTIMONIAL_READ_TRENDING_REQUEST";
export const TESTIMONIAL_READ_LIKED_REQUEST = "TESTIMONIAL_READ_LIKED_REQUEST";
export const TESTIMONIAL_READ_BY_CATEGORY_REQUEST = "TESTIMONIAL_READ_BY_CATEGORY_REQUEST";
export const TESTIMONIAL_READ_INREVIEW_REQUEST = "TESTIMONIAL_READ_INREVIEW_REQUEST";
export const TESTIMONIAL_READ_APPROVED_REQUEST = "TESTIMONIAL_READ_APPROVED_REQUEST";
export const TESTIMONY_DELETE_REQUEST = "TESTIMONY_DELETE_REQUEST";

//Common
export const ADD_GLOBAL_VIDEOS = "ADD_GLOBAL_VIDEOS";
export const ADD_GLOBAL_TESTIMONIALS = "ADD_GLOBAL_TESTIMONIALS";
export const ADD_GLOBAL_CURRICULUM = "ADD_GLOBAL_CURRICULUM";
export const ADD_GLOBAL_CATEGORY = "ADD_GLOBAL_CATEGORY";
export const SET_GLOBAL_VIDEOS = "SET_GLOBAL_VIDEOS";
export const SET_GLOBAL_TESTIMONIALS = "SET_GLOBAL_TESTIMONIALS";
export const SET_GLOBAL_CURRICULUM = "SET_GLOBAL_CURRICULUM";
export const SET_GLOBAL_CATEGORY = "SET_GLOBAL_CATEGORY";
export const ADD_GLOBAL_JOURNALS = "ADD_GLOBAL_JOURNALS";
export const SHOW_NAVIGATION_ALERT = "SHOW_NAVIGATION_ALERT";

//Bible
export const SET_BIBLE_VERSION = "SET_BIBLE_VERSION";
export const SET_BIBLE_COUNTRY_LANGUAGES = "SET_BIBLE_COUNTRY_LANGUAGES";
export const SET_BIBLE_LANGUAGE = "SET_BIBLE_LANGUAGE";
export const SET_VERSION_DETAILS = "SET_VERSION_DETAILS";
export const SET_BIBLE_PARALLEL_MODE = "SET_BIBLE_PARALLEL_MODE";
export const SET_BOOK_CHAPTER_DETAILS = "SET_BOOK_CHAPTER_DETAILS";
export const SET_BOOK_CHAPTER_DETAILS_LOADING = "SET_BOOK_CHAPTER_DETAILS_LOADING";
export const SET_PARALLEL_VERSION_DETAILS = "SET_PARALLEL_VERSION_DETAILS";
export const SET_PARALLEL_BOOK_CHAPTER_DETAILS = "SET_PARALLEL_BOOK_CHAPTER_DETAILS";
export const SET_PARALLEL_BOOK_CHAPTER_DETAILS_LOADING =
  "SET_PARALLEL_BOOK_CHAPTER_DETAILS_LOADING";
export const SET_USER_JOURNALS = "SET_USER_JOURNALS";
export const SET_ACTIVE_SEARCH_TAB = "SET_ACTIVE_SEARCH_TAB";
export const SET_ACTIVE_PARALLEL_SEARCH_TAB = "SET_ACTIVE_PARALLEL_SEARCH_TAB";
export const SET_ALL_BIBLE_LANGUAGES = "SET_ALL_BIBLE_LANGUAGES";
export const SET_ALL_BIBLE_VERSIONS = "SET_ALL_BIBLE_VERSIONS";

export const GET_VERSION_BOOKS_REQUEST = "GET_VERSION_BOOKS_REQUEST";
export const GET_BIBLE_VERSIONS_REQUEST = "GET_BIBLE_VERSIONS_REQUEST";
export const GET_BIBLE_LANGUAGES_REQUEST = "GET_BIBLE_LANGUAGES_REQUEST";
export const GET_BIBLE_COUNTRY_LANGUAGES_REQUEST = "GET_BIBLE_COUNTRY_LANGUAGES_REQUEST";
export const GET_BIBLE_CHAPTER_DETAILS = "GET_BIBLE_CHAPTER_DETAILS";
export const GET_BIBLE_SEARCH = "GET_BIBLE_SEARCH";
export const GET_BIBLE_RESOURCE_KEY = "GET_BIBLE_RESOURCE_KEY";
export const GET_USER_JOURNALS = "GET_USER_JOURNALS";
export const DELETE_USER_JOURNALS = "DELETE_USER_JOURNALS";
export const GET_VERSE_DETAILS = "GET_VERSE_DETAILS";
export const SAVE_VERSE_COLOR = "SAVE_VERSE_COLOR";
export const UPDATE_VERSE_COLOR = "UPDATE_VERSE_COLOR";
export const DELETE_VERSE_COLOR = "DELETE_VERSE_COLOR";
export const GET_VERSE_COLOR = "GET_VERSE_COLOR";
export const GET_CHAPTER_VIDEOS = "GET_CHAPTER_VIDEOS";
export const GET_CHAPTER_JOURNALS = "GET_CHAPTER_JOURNALS";
export const GET_JOURNALS = "GET_JOURNALS";
export const UPDATE_USER_STREAK = "UPDATE_USER_STREAK";
export const GET_USER_STREAK_SUMMARY = "GET_USER_STREAK_SUMMARY";
export const GET_JOURNAL_SPEAKERS = "GET_JOURNAL_SPEAKERS";
export const GET_JOURNALS_BY_SPEAKER = "GET_JOURNALS_BY_SPEAKER";
export const GET_JOURNALS_BY_CATEGORY = "GET_JOURNALS_BY_CATEGORY";
export const GET_BIBLE_SUMMARY = "GET_BIBLE_SUMMARY";

export const SAVE_JOURNAL_REQUEST = "SAVE_JOURNAL_REQUEST";
export const UPDATE_JOURNAL_REQUEST = "UPDATE_JOURNAL_REQUEST";
export const DELETE_JOURNAL_REQUEST = "DELETE_JOURNAL_REQUEST";

export const ADD_JOURNAL_NOTE_REQUEST = "ADD_JOURNAL_NOTE_REQUEST";
export const UPDATE_JOURNAL_NOTE_REQUEST = "UPDATE_JOURNAL_NOTE_REQUEST";
export const DELETE_JOURNAL_NOTE_REQUEST = "DELETE_JOURNAL_NOTE_REQUEST";

export const ADD_JOURNAL_NOTE_ITEM_REQUEST = "ADD_JOURNAL_NOTE_ITEM_REQUEST";
export const UPDATE_JOURNAL_NOTE_ITEM_REQUEST = "UPDATE_JOURNAL_NOTE_ITEM_REQUEST";
export const DELETE_JOURNAL_NOTE_ITEM_REQUEST = "DELETE_JOURNAL_NOTE_ITEM_REQUEST";
