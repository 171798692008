import React, { useState, useRef } from "react";
import ReactModal from "react-modal";

import close from "../../assets/icons/Cross.svg";
import { AudioRecorder } from "react-audio-voice-recorder";

const AudioRecorderModal = ({ isOpen, onClose, onSave }) => {
  const [isRecording, setIsRecording] = useState(false);
  const mediaRecorderRef = useRef(null);
  const audioChunks = useRef([]);

  const startRecording = async () => {
    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      mediaRecorderRef.current = new MediaRecorder(stream);

      mediaRecorderRef.current.ondataavailable = (event) => {
        if (event.data.size > 0) {
          audioChunks.current.push(event.data);
        }
      };

      mediaRecorderRef.current.onstop = () => {
        const audioBlob = new Blob(audioChunks.current, { type: "audio/webm" });
        const audioFile = new File([audioBlob], "audio-capture.webm", {
          type: "audio/webm",
        });
        onSave(audioFile);
        onClose();
      };

      mediaRecorderRef.current.start();
      setIsRecording(true);
    } catch (error) {
      console.error("Error accessing audio device:", error);
    }
  };

  const stopRecording = () => {
    if (mediaRecorderRef.current) {
      mediaRecorderRef.current.stop();
    }
    setIsRecording(false);
  };

  return (
    <ReactModal
      isOpen={isOpen}
      onRequestClose={() => {
        onClose(false);
      }}
      contentLabel="Custom Modal"
      className="custom-modal absolute flex justify-center items-center z-[40] bg-black bg-opacity-50 h-screen w-screen py-6"
      ariaHideApp={false}
    >
      <div className="MODAL-BODY h-fit relative flex flex-col items-center justify-center w-fit md:absolute rounded-2xl p-8 bg-blackishV2 text-white">
        <img
          className="cursor-pointer w-7 aspect-square absolute rounded-full bg-yellowish -top-2 -right-2 z-50"
          onClick={() => {
            onClose(false);
          }}
          src={close}
          alt=""
        />

        <h2 className="text-2xl font-semibold mb-6">Audio Recorder</h2>

        <AudioRecorder
          onRecordingComplete={(blob) => {
            const url = URL.createObjectURL(blob);
            console.log("audio captured url", url);
            onSave(url);
            onClose();
          }}
          showVisualizer={true}
          audioTrackConstraints={{
            noiseSuppression: true,
            echoCancellation: true,
          }}
          downloadFileExtension="webm"
        />
      </div>
    </ReactModal>
  );
};

export default AudioRecorderModal;
