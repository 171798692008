import { iso6393 } from "iso-639-3";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import BibleVersionSearchV2 from "./BibleVersionSearchV2";
import BibleCountrySearchV2 from "./BibleCountrySearchV2";
import BibleLangaugeSearchV2 from "./BibleLanguageSearchV2";

import {
  getBibleVersionsAction,
  setBibleLanguageAction,
  getBibleLanguagesAction,
  setActiveSearchTabAction,
  setAllBibleLanguagesAction,
  setBibleCountryLanguagesAction,
  setBibleVersionAction,
  getBibleCountryLanguagesAction,
} from "../../store/Bible/BibleAction";
import { SEARCH_TABS, SEARCH_TABS_KEYS } from "../../constants/common";

import DropDownIcon from "../../assets/icons/dropDownIcon.svg";

const BibleVersionControl = ({ containerKey = "bibleVersion" }) => {
  const dispatch = useDispatch();

  const { bibleVersion, activeSearchTab, bibleLanguage, countryLanguages, allBibleLanguages } =
    useSelector((state) => state.bible);

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [isDropdownActive, setIsDropdownActive] = useState(false);

  const [versions, setVersions] = useState([]);
  const [allVersions, setAllVersions] = useState([]);
  const [versionLoading, setVersionLoading] = useState(false);

  const [languagesLoading, setLanguagesLoading] = useState(false);
  const [countryLanguagesLoading, setCountryLanguagesLoading] = useState(false);
  const [countryLanguagesList, setCountryLanguagesList] = useState([]);

  const fetchBibleVersions = (language) => {
    setVersionLoading(true);
    dispatch(
      getBibleVersionsAction({
        apiPayloadRequest: { languagecode: language },
        callback: (res) => {
          setVersions(res?.data);
          setAllVersions(res?.data);
          setVersionLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchBibleVersions(bibleLanguage);
  }, [bibleLanguage]);

  const getLanguageName = (code) => {
    const language = iso6393.find((lang) => lang.iso6393 === code);
    return language ? language.name : "Unknown language";
  };

  const fetchCountryLanguages = () => {
    setCountryLanguagesLoading(true);
    dispatch(
      getBibleCountryLanguagesAction({
        apiPayloadRequest: {},
        callback: (res) => {
          const updatedResults = res?.data?.map((d) => {
            const updatedLanguages = d?.languages?.map((lang) => ({
              code: lang,
              name: getLanguageName(lang),
            }));

            return {
              ...d,
              languages: updatedLanguages,
            };
          });

          setCountryLanguagesList(updatedResults);
          dispatch(setBibleCountryLanguagesAction(updatedResults));

          setCountryLanguagesLoading(false);
        },
      })
    );
  };

  const fetchLanguages = () => {
    setLanguagesLoading(true);
    dispatch(
      getBibleLanguagesAction({
        apiPayloadRequest: {},
        callback: (res) => {
          dispatch(setAllBibleLanguagesAction(res?.data));
          setLanguagesLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (countryLanguages?.length === 0) {
      fetchCountryLanguages();
    } else {
      setCountryLanguagesList(countryLanguages);
    }
  }, [countryLanguages]);

  useEffect(() => {
    if (allBibleLanguages?.length === 0) {
      fetchLanguages();
    }
  }, [allBibleLanguages]);

  const handleDropdown = (e) => {
    isDropdownActive ? setIsDropdownActive(false) : setIsDropdownActive(true);
  };

  console.log("countryLanguages", countryLanguages);

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector(`#${containerKey}`);
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsDropdownActive((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  return (
    <div>
      <div
        className="flex items-center justify-between gap-2 bg-greyishV4 outline-none border-none rounded-md px-4 py-2 text-white cursor-pointer z-[1000]"
        onClick={() => handleDropdown()}
      >
        <span className="uppercase">{bibleVersion?.substring(3)}</span>
        <img src={DropDownIcon} alt="DropDownIcon" />
      </div>
      {isDropdownActive && (
        <div
          className="w-full md:w-[32vw] text-white absolute bg-greyishV2/90 backdrop-blur-md p-6 md:p-8 rounded-3xl rounded-ss-none left-0 md:left-auto top-16 md:top-24 h-[70vh] z-50 flex flex-col overflow-hidden"
          id={containerKey}
        >
          <div className="flex flex-wrap gap-2 md:gap-4 pb-4 text-sm md:text-base">
            {Object.values(SEARCH_TABS)?.map((tab, index) => (
              <span
                key={`video-tabitem${index}`}
                className={`rounded-full border ${
                  activeSearchTab === tab
                    ? "text-yellowish border-yellowish"
                    : "border-greyish text-greyish"
                } py-1 px-5 cursor-pointer`}
                onClick={() => dispatch(setActiveSearchTabAction(tab))}
              >
                {currentLanguageTitles?.[0]?.bible?.[SEARCH_TABS_KEYS?.[tab]] ?? tab}
              </span>
            ))}
          </div>

          <BibleVersionSearchV2
            isVisible={activeSearchTab === SEARCH_TABS.BIBLE}
            setIsDropdownActive={setIsDropdownActive}
            versions={versions}
            allVersions={allVersions}
            setVersions={setVersions}
            loading={versionLoading}
            handleSelection={(version) => dispatch(setBibleVersionAction(version))}
          />
          <BibleLangaugeSearchV2
            isVisible={activeSearchTab === SEARCH_TABS.LANGUAGE}
            setIsDropdownActive={setIsDropdownActive}
            loading={languagesLoading}
            handleLanguageChange={(lang) => {
              dispatch(setBibleLanguageAction(lang));
              dispatch(setActiveSearchTabAction(SEARCH_TABS.BIBLE));
            }}
          />
          <BibleCountrySearchV2
            isVisible={activeSearchTab === SEARCH_TABS.COUNTRY}
            setIsDropdownActive={setIsDropdownActive}
            loading={countryLanguagesLoading}
            setLanguages={setCountryLanguagesList}
            languages={countryLanguagesList}
            handleLanguageChange={(e) => {
              dispatch(setBibleLanguageAction(e.target.value));
              dispatch(setActiveSearchTabAction(SEARCH_TABS.BIBLE));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default BibleVersionControl;
