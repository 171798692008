import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { endOfWeek, format, startOfWeek } from "date-fns";

import BibleSearch from "./BibleSearch";
import BibleBookView from "./BibleBookView";
import ParallelVerses from "./ParallelVerses";
import BibleVersionControl from "./BibleVersionControl";
import JourneyRecapModal from "../../screens/verses/StreakModal";

import { getUserStreakSummaryAction } from "../../store/Bible/BibleAction";
import { BsLightningCharge } from "react-icons/bs";
import ContinueReading from "./ContinueReading";

const BibleHeader = ({ showParallelMode = false, showBibleBook = false }) => {
  const dispatch = useDispatch();
  const { isAuthenticated } = useAuth0();

  const [isJourneyRecapOpen, setIsJourneyRecapOpen] = useState(false);
  const [streakDetails, setStreakDetails] = useState({});
  const [streakDetailsLoading, setStreakDetailsLoading] = useState(false);

  console.log("streakDetails", streakDetails);

  const getUserStreakSummary = () => {
    setStreakDetailsLoading(true);
    // const startDate = startOfWeek(new Date(), { weekStartsOn: 0 }); // Week starts on Sunday
    const endDate = endOfWeek(new Date(), { weekStartsOn: 0 });
    const startDate = startOfWeek(endDate, 60); // Week starts on Sunday
    dispatch(
      getUserStreakSummaryAction({
        apiPayloadRequest: {
          startdate: format(startDate, "yyyy-MM-dd"),
          enddate: format(endDate, "yyyy-MM-dd"),
        },
        callback: (response) => {
          setStreakDetails(response);
          setStreakDetailsLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      getUserStreakSummary();
    }
  }, [isAuthenticated]);

  return (
    <div className="w-full flex items-center justify-between relative py-3 border-b border-greyishV2 text-white px-5 md:px-0">
      <div className="flex items-center gap-3">
        {showBibleBook && (
          <div className="hidden md:block">
            <BibleBookView />
          </div>
        )}
        <div className="hidden md:block">
          <ContinueReading
            streakDetails={streakDetails}
            streakDetailsLoading={streakDetailsLoading}
          />
        </div>
        <BibleSearch leftAlign={!streakDetails?.data?.[0]?.verseDetails?.[0]?.verseObjId} />
        <BibleVersionControl />
        {showParallelMode && <ParallelVerses showParallelIcons={true} showExit={true} />}
      </div>
      <div className="flex items-center gap-4">
        {/* <button
          className="text-yellowish border border-yellowish rounded-3xl py-1 w-24"
          onClick={() => navigate("/plan")}
        >
          Plans
        </button> */}
        {isAuthenticated && (
          <>
            {streakDetailsLoading ? (
              <div className="animate-pulse bg-zinc-600 rounded-full h-[50px] md:h-[60px] aspect-square"></div>
            ) : (
              <div
                className="h-[50px] md:h-[60px] cursor-pointer  aspect-square rounded-full border border-yellowish flex items-center justify-center"
                onClick={() => setIsJourneyRecapOpen(true)}
              >
                <div className="relative top-1">
                  <p className="text-greyish font-semibold text-lg">
                    {streakDetails?.stats?.dailystreaks ?? 0}
                  </p>
                  <BsLightningCharge className="text-yellowish text-sm absolute -top-2 -right-2" />
                </div>
              </div>
            )}
            <JourneyRecapModal
              streakDetails={streakDetails}
              showModal={isJourneyRecapOpen}
              setShowModal={setIsJourneyRecapOpen}
            />
          </>
        )}
      </div>
    </div>
  );
};

export default BibleHeader;
