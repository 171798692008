import { useEffect, useState } from "react";
import { confirmAlert } from "react-confirm-alert";
import { useDispatch, useSelector } from "react-redux";

import NotesModel from "../verses/NotesModel";
import ConfirmToast from "../../components/common/ConfirmToast";

import {
  deleteUserJournalsAction,
  getUserJournalsAction,
  setUserJournalsAction,
} from "../../store/Bible/BibleAction";

import { RiDeleteBinLine } from "react-icons/ri";
import { FaPlus } from "react-icons/fa";

import HoverJournals from "../../assets/icons/NavIcons/hoverJournals.svg";
import JournalsBarIcon from "../../assets/icons/NavIcons/journalsBarIcon.svg";
import { useLocation } from "react-router-dom";

const MyJournals = ({ gridItems = 8 }) => {
  const dispatch = useDispatch();
  const location = useLocation();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const { userJournals } = useSelector((state) => state.bible);
  const { isMobile } = useSelector((state) => state.common);

  const [searchTxt, setSearchTxt] = useState("");
  const [isNotesActive, setIsNotesActive] = useState(false);
  const [isNewNotesActive, setIsNewNotesActive] = useState(false);
  const [existingJournalId, setExistingJournalId] = useState(null);
  const [isLoading, setIsLoading] = useState(true);

  console.log("existingJournalId", existingJournalId);

  const handleJournalDelete = (id) => {
    const options = {
      customUI: ({ onClose }) => {
        return (
          <ConfirmToast
            title="Are you sure?"
            message="You want to delete this journal?"
            deleteButtonText="Yes"
            onClose={() => onClose()}
            onSuccess={() => {
              dispatch(
                deleteUserJournalsAction({
                  apiPayloadRequest: { _id: id },
                  callback: (response) => {
                    if (response?.type === 1) fetchUserJournals();
                    onClose();
                  },
                })
              );
            }}
          />
        );
      },
      closeOnEscape: true,
      closeOnClickOutside: true,
      keyCodeForClose: [8, 32],
      overlayClassName: "!bg-gray-500 !bg-opacity-75",
    };

    confirmAlert(options);
  };

  const fetchUserJournals = () => {
    setIsLoading(true);
    dispatch(
      getUserJournalsAction({
        callback: (response) => {
          dispatch(setUserJournalsAction(response?.data ?? []));
          setIsLoading(false);
          if (location?.state?.journalId) {
            const data = response?.data?.find((d) => d?._id === location?.state?.journalId);
            if (data?._id) {
              setIsNotesActive(true);
              setIsNewNotesActive(false);
              setExistingJournalId(data?._id);
            }
          }
        },
      })
    );
  };

  useEffect(() => {
    fetchUserJournals();
  }, []);

  const renderColumn = (columnIndex) => {
    const elements = [];
    for (let i = columnIndex; i < userJournals.length; i = i + gridItems) {
      if (i % 2 === 0) {
        elements.push(
          <div
            className={`bg-yellowish shadow-md p-4 w-full flex flex-col cursor-pointer ${
              elements.length % 3 === 0 && "rounded-br-3xl rounded-bl-3xl rounded-tr-3xl"
            } ${elements.length % 3 === 1 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"} ${
              elements.length % 3 === 2 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"
            }`}
            style={{
              gridRow: `span ${Math.ceil(
                (userJournals?.[i]?.journal_description?.length || 1) / 50
              )}`, // Dynamically adjust height
            }}
            onClick={() => {
              setIsNotesActive(true);
              setIsNewNotesActive(false);
              setExistingJournalId(userJournals?.[i]?._id);
            }}
          >
            <h4 className="text-lg font-semibold mb-2">{userJournals?.[i]?.journal_title}</h4>
            <p
              className={`text-xs text-greyishV2 overflow-ellipsis overflow-hidden flex-1 ${
                elements.length % 3 === 0 && "line-clamp-[12]"
              } ${elements.length % 3 === 1 && "line-clamp-6"} ${
                elements.length % 3 === 2 && "line-clamp-4"
              }`}
            >
              {userJournals?.[i]?.description}
            </p>
            <div className="flex items-center justify-between text-greyishV5 mt-3">
              <div className="flex items-center gap-1">
                {/* <MdOutlineBookmark />
                <FaShareAlt className="text-sm" /> */}
              </div>
              <RiDeleteBinLine
                onClick={(e) => {
                  e.stopPropagation();
                  handleJournalDelete(userJournals?.[i]?._id);
                }}
              />
            </div>
          </div>
        );
      } else {
        elements.push(
          <div
            className={`bg-yellowish shadow-md p-4 w-full flex flex-col cursor-pointer ${
              elements.length % 3 === 0 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"
            }  ${elements.length % 3 === 1 && "rounded-tl-3xl rounded-bl-3xl rounded-br-2xl"}  ${
              elements.length % 3 === 2 && "rounded-br-3xl rounded-bl-3xl rounded-tr-3xl"
            }`}
            style={{
              gridRow: `span ${Math.ceil(
                (userJournals?.[i]?.journal_description?.length || 1) / 50
              )}`, // Dynamically adjust height
            }}
            onClick={() => {
              setIsNotesActive(true);
              setIsNewNotesActive(false);
              setExistingJournalId(userJournals?.[i]?._id);
            }}
          >
            <h4 className="text-lg font-semibold mb-2">{userJournals?.[i]?.journal_title}</h4>
            <p
              className={`text-xs text-greyishV2 overflow-ellipsis overflow-hidden flex-1 ${
                elements.length % 3 === 0 && "line-clamp-2"
              } ${elements.length % 3 === 1 && "line-clamp-6"} ${
                elements.length % 3 === 2 && "line-clamp-4"
              }`}
            >
              {userJournals?.[i]?.journal_description}
            </p>
            <div className="flex items-center justify-between text-greyishV5 mt-3">
              <div className="flex items-center gap-1">
                {/* <MdOutlineBookmark />
                <FaShareAlt className="text-sm" /> */}
              </div>
              <RiDeleteBinLine
                onClick={(e) => {
                  e.stopPropagation();
                  handleJournalDelete(userJournals?.[i]?._id);
                }}
              />
            </div>
          </div>
        );
      }
    }
    return elements;
  };

  return (
    <>
      <div className="w-full flex flex-col bg-black flex-1 overflow-y-auto">
        <div className="flex items-center px-8 py-5">
          <div className="w-full flex items-center gap-3">
            <h4 className="text-yellowish text-3xl font-bold">
              {currentLanguageTitles?.[0]?.bible?.my_journal_lbl ?? "My Journals"}
            </h4>
            <img src={HoverJournals} alt="HoverJournals" className="w-9" />
            <img src={JournalsBarIcon} alt="JournalsBarIcon" className="w-8" />
          </div>
          {/* <div
            className={`min-w-[600px] flex items-center  bg-greyishV2 text-greyish gap-3 py-2 px-3 rounded-md`}
          >
            <LuSearch className="text-2xl text-greyish" />
            <input
              type="text"
              value={searchTxt}
              placeholder={"Search the Bible"}
              onChange={(e) => setSearchTxt(e.target.value)}
              className="w-full  bg-greyishV2 text-greyish outline-none text-lg"
            />
          </div> */}
        </div>
        <div className="px-8">
          <div className={`grid mb-6 ${isMobile ? "grid-cols-2" : "grid-cols-8"}`}>
            <div
              className="text-greyish bg-blackishV2 border border-yellowish text-sm px-6 py-3.5 flex items-center gap-2 cursor-pointer col-span-2 rounded-full rounded-ee-none"
              onClick={() => {
                setIsNotesActive(true);
                setIsNewNotesActive(true);
              }}
            >
              <FaPlus className="" />
              <span className="font-semibold">
                {currentLanguageTitles?.[0]?.bible?.add_new_jorunal_lbl ?? "Add New Journal"}
              </span>
            </div>
          </div>

          {isLoading ? (
            <div className={`grid gap-3 ${isMobile ? "grid-cols-2" : "grid-cols-8"}`}>
              <div className="flex flex-col gap-3">
                <div className="animate-pulse bg-zinc-600 aspect-[7/12] rounded-3xl rounded-ss-none"></div>
                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
              </div>
              <div className="flex flex-col gap-3">
                <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
              </div>
              {!isMobile && (
                <>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[7/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[7/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[7/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                  </div>
                  <div className="flex flex-col gap-3">
                    <div className="animate-pulse bg-zinc-600 aspect-[16/12] rounded-3xl rounded-ss-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-square rounded-3xl rounded-se-none"></div>
                    <div className="animate-pulse bg-zinc-600 aspect-[16/14] rounded-3xl rounded-ss-none"></div>
                  </div>
                </>
              )}
            </div>
          ) : (
            <div
              className={`w-full grid grid-cols-${gridItems} gap-x-2 max-h-[65vh] overflow-y-auto custom-scrollbar md:pr-4`}
            >
              {Array.from({ length: gridItems }).map((_, arrIndex) => {
                return (
                  <div key={arrIndex} className="flex flex-col gap-2">
                    {renderColumn(arrIndex)}
                  </div>
                );
              })}
            </div>
          )}

          {!isLoading && userJournals?.length === 0 && (
            <div className="w-full h-[50vh] flex items-center justify-center text-greyish">
              {currentLanguageTitles?.[0]?.bible?.no_journals_available_lbl ??
                "No Journals Available"}
            </div>
          )}
        </div>
      </div>
      {isNotesActive && (
        <NotesModel
          isNotesActive={isNotesActive}
          isNewNotesActive={isNewNotesActive}
          setIsNotesActive={() => {
            setIsNotesActive(false);
            setExistingJournalId(null);
          }}
          onDelete={() => fetchUserJournals()}
          showNewSection={true}
          existingJournalId={existingJournalId}
        />
      )}
    </>
  );
};

export default MyJournals;
