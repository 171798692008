import { useCallback, useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { getBibleSearchAction } from "../../store/Bible/BibleAction";

import { LuSearch } from "react-icons/lu";
import CloseIcon from "../../assets/icons/Cross.svg";
import { throttle } from "../../utils/Utils";
import PropTypes from "prop-types";

const BibleSearch = ({ leftAlign = false }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { versionDetails } = useSelector((state) => state.bible);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const inputRef = useRef(null);

  const [loading, setLoading] = useState(false);
  const [searchText, setSearchText] = useState("Jesus");
  const [isSearching, setIsSearching] = useState(false);
  const [searchResults, setSearchResults] = useState([]);

  const handleSearch = (search) => {
    setLoading(true);
    dispatch(
      getBibleSearchAction({
        apiPayloadRequest: {
          filesetid: versionDetails?.abbr,
          searchquery: search,
        },
        callback: (res) => {
          setSearchResults(res?.data?.verses?.data ?? []);
          setLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let preferredLanguageDrawer = document.querySelector("#bibleSearch");
      if (preferredLanguageDrawer && !preferredLanguageDrawer?.contains(e.target)) {
        setIsSearching((preState) => !preState);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  const throttledFetchResults = useCallback(throttle(handleSearch, 500), []);

  useEffect(() => {
    throttledFetchResults(searchText);
  }, []);

  return (
    <div>
      <div
        className={`w-fit md:w-[20vw] flex items-center bg-greyishV2 gap-3 py-2 px-3 rounded-md cursor-pointer`}
        onClick={() => {
          setIsSearching(true);
          setTimeout(() => {
            if (inputRef.current) inputRef.current.focus();
          }, 500);
        }}
      >
        <LuSearch className="text-2xl text-yellowish" />
        <p className="w-full bg-greyishV2 text-greyish whitespace-nowrap hidden md:flex">
          {currentLanguageTitles?.[0]?.bible?.bible_search_placeholder ?? "Search the Bible"}
        </p>
      </div>
      {isSearching && (
        <div
          id="bibleSearch"
          className={`w-full md:w-9/12 text-white absolute bg-greyishV2/80 backdrop-blur-sm p-5 rounded-md top-16 md:top-24 h-[65vh] overflow-y-auto custom-scrollbar z-50 flex flex-col ${
            leftAlign ? "" : "-translate-x-1/4"
          }`}
        >
          <div className="flex items-center gap-5 pb-7">
            <div
              className={`w-full md:min-w-[400px] flex items-center bg-greyish gap-3 py-2 px-3 rounded-md`}
            >
              <LuSearch className="text-2xl text-blackish" />
              <input
                type="text"
                ref={inputRef}
                value={searchText}
                placeholder={
                  currentLanguageTitles?.[0]?.bible?.bible_search_placeholder ?? "Search the Bible"
                }
                onChange={(e) => {
                  setSearchText(e.target.value);
                  throttledFetchResults(e.target.value);
                }}
                className="w-full bg-greyish outline-none placeholder:text-blackish text-blackish text-lg"
              />
            </div>
            <img
              src={CloseIcon}
              alt="CloseIcon"
              className="h-5 cursor-pointer"
              onClick={() => {
                setIsSearching(false);
                setSearchText("");
              }}
            />
          </div>
          <ul className="flex flex-col gap-1 flex-1 overflow-y-auto custom-scrollbar pr-2">
            {loading ? (
              <div className="flex flex-col gap-3">
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
                <div className="flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-2/12 h-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
            ) : (
              searchResults?.map((item, index) => {
                return (
                  <div
                    key={`search-item${index}`}
                    className="py-2 hover:bg-black/30 rounded-md px-2 cursor-pointer"
                    onClick={() => {
                      navigate(
                        `/chapter/${versionDetails?.abbr}/${item?.book_id}/${item?.chapter}`
                      );
                      setIsSearching(false);
                    }}
                  >
                    <p className="">{item?.verse_text}</p>
                    <div className="w-full flex items-center justify-between text-greyishV3 text-sm">
                      <p className="">
                        {item?.book_name}{" "}
                        <span className="">
                          {item?.chapter}:{item?.verse_start}
                        </span>
                      </p>
                    </div>
                  </div>
                );
              })
            )}

            {!loading && searchResults?.length === 0 && (
              <li className="flex flex-col gap-2 items-center justify-center py-10 text-yellowish cursor-pointer">
                <p>{currentLanguageTitles?.[0]?.bible?.no_results_lbl ?? "No Results Found"}</p>
              </li>
            )}
          </ul>
        </div>
      )}
    </div>
  );
};

BibleSearch.propTyp = {
  leftAlign: PropTypes.bool,
};

export default BibleSearch;
