import React, { useState } from "react";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";
import { useDispatch, useSelector } from "react-redux";
import { setLoginHardBlocker } from "../../store/Home/HomeAction";

import MyJournals from "./MyJournals";
import TWGJournals from "./TWGJournals";
import { journalsList } from "../../constants/common";

const Journals = () => {
  const dispatch = useDispatch();

  const { isMobile } = useSelector((state) => state.common);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const initialSeeAll = "See All";
  const initialMoreVideos = "More Videos";
  const checkToken = localStorage.getItem("token");
  const initialTabs = [
    currentLanguageTitles?.[0]?.bible?.twg_journal_lbl ?? "TWG Journals",
    currentLanguageTitles?.[0]?.bible?.my_journal_lbl ?? "My Journals",
  ];

  const [popCancle, setPopCancel] = useState(true);
  const [tabValues, setTabsValues] = useState(initialTabs);
  const [activeTab, setActiveTab] = useState(0);
  const [currentCode, setCurrectCode] = useState("");
  const [moreVideosbtn, setMoreVideosbtn] = useState(initialMoreVideos);
  const [seeAllBtn, setSeeAllBtn] = useState(initialSeeAll);

  const handleTabChange = (index) => {
    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  return (
    <>
      <div className="flex flex-col md:flex-row w-full overflow-hidden">
        <Navbar active={"Journals"} />
        <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
          <HeaderBar
            popCancle={popCancle}
            cancelBtn={!popCancle}
            cancelBtnHandle={() => setPopCancel(true)}
            tabValues={tabValues}
            active={activeTab}
            handleTabChange={handleTabChange}
            getLanguagePreferred={(code) => {
              setCurrectCode(code);
            }}
            getCurrentLanguageTitles={(titles) => {
              setTabsValues(initialTabs);
              setSeeAllBtn(initialSeeAll);
              setMoreVideosbtn(initialMoreVideos);
            }}
          />
          {activeTab === 0 && <TWGJournals />}
          {activeTab === 1 && <MyJournals data={journalsList} gridItems={isMobile ? 2 : 8} />}
        </div>
      </div>
    </>
  );
};

export default Journals;
