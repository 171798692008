import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { FaAngleDown } from "react-icons/fa6";
import { SEARCH_TABS, SEARCH_TABS_KEYS } from "../../constants/common";

const BibleCountrySearchV2 = ({
  isVisible,
  loading,
  setLanguages,
  languages,
  handleLanguageChange,
}) => {
  const { bibleLanguage, countryLanguages } = useSelector((state) => state.bible);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [selectedCountry, setSelectedCountry] = useState(-1);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (!searchText) {
      setLanguages(countryLanguages);
    } else {
      const text = searchText?.toLowerCase();
      const updatedLanguages = countryLanguages?.filter((item) => {
        return item?.name?.toLowerCase()?.includes(text);
      });
      setLanguages(updatedLanguages);
    }
  }, [countryLanguages, searchText]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  return (
    isVisible && (
      <div className="flex flex-col h-full pb-10">
        <div className="flex items-center gap-5 pb-7">
          <div className="w-full  bg-white/5 min-w-[400px] flex items-center gap-3 border rounded-full px-5 py-2 border-greyish">
            <input
              type="text"
              value={searchText}
              placeholder={
                currentLanguageTitles?.[0]?.bible?.[SEARCH_TABS_KEYS[SEARCH_TABS.COUNTRY]] ??
                "Search Country"
              }
              onChange={(e) => handleSearch(e)}
              className="w-full bg-transparent outline-none text-white placeholder:text-greyish"
            />
          </div>
        </div>
        <ul className="flex-1 overflow-y-auto custom-scrollbar">
          {loading ? (
            <div className="w-full flex flex-col gap-5 justify-center">
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-10 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-10 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-10 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
              <div className="w-full grid grid-cols-12 gap-5">
                <div className="animate-pulse bg-zinc-600 rounded-2xl col-span-3 h-full"></div>
                <div className="col-span-9 flex flex-col gap-2">
                  <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
                  <div className="w-full h-10 animate-pulse bg-zinc-600 rounded-2xl"></div>
                </div>
              </div>
            </div>
          ) : languages?.length === 0 ? (
            <div className="flex items-center justify-center w-full h-full">No Results found</div>
          ) : (
            languages?.map((country, index) => {
              return (
                <li key={index} className="w-full flex flex-col cursor-pointer">
                  <div
                    className="w-full flex items-center justify-between mb-5 hover:bg-black/30 py-2 rounded-md px-2"
                    onClick={() => {
                      if (selectedCountry === index) {
                        setSelectedCountry(null);
                      } else {
                        setSelectedCountry(index);
                      }
                    }}
                  >
                    <div className="flex items-center gap-5">
                      <img
                        src={`https://flagcdn.com/w160/${country?.codes?.iso_a2?.toLowerCase()}.png`}
                        alt="AudioPlay"
                        className="w-16 aspect-square object-cover rounded-md object-top"
                      />
                      <div className="flex flex-col gap-1 items-start">
                        <p className="text-lg font-semibold">{country?.name}</p>
                        <span className="text-greyish text-sm">
                          {country?.languages?.length} official language
                        </span>
                      </div>
                    </div>
                    <div className="">
                      <FaAngleDown className="text-yellowish" />
                    </div>
                  </div>
                  {selectedCountry === index && (
                    <ul className="flex flex-col gap-4 pb-6">
                      {country?.languages?.map((lang, index) => (
                        <li
                          key={`${country?.name}-${lang?.code}${index}`}
                          className="text-lg flex items-center gap-3 pl-8 cursor-pointer"
                        >
                          <input
                            type="radio"
                            id={lang?.code}
                            name="language"
                            value={lang?.code}
                            checked={bibleLanguage === lang?.code}
                            className="h-5 aspect-square"
                            onChange={handleLanguageChange}
                          />
                          <label
                            htmlFor={lang?.code}
                            className="text-sm text-white/80 cursor-pointer"
                          >
                            {lang?.name}
                          </label>
                        </li>
                      ))}
                    </ul>
                  )}
                </li>
              );
            })
          )}
        </ul>
      </div>
    )
  );
};

export default BibleCountrySearchV2;
