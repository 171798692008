import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import Toastiy from "../common/Toastiy";
import BibleCountrySearchV2 from "./BibleCountrySearchV2";
import BibleVersionSearchV2 from "./BibleVersionSearchV2";
import BibleLangaugeSearchV2 from "./BibleLanguageSearchV2";

import {
  getBibleVersionsAction,
  getVersionBooksAction,
  setActiveParallelSearchTabAction,
  setBibleParallelModeAction,
  setParallelVersionDetailsAction,
} from "../../store/Bible/BibleAction";
import { SEARCH_TABS, SEARCH_TABS_KEYS } from "../../constants/common";

import { TiDocumentText } from "react-icons/ti";

import DropDownIcon from "../../assets/icons/dropDownIcon.svg";

const ParallelVerses = ({
  showParallelIcons = false,
  showExit = false,
  containerKey = "parallelBibleVersion",
}) => {
  const dispatch = useDispatch();
  const { bibleParallelMode, activeParallelSearchTab, allBibleLanguages, parallelVersionDetails } =
    useSelector((state) => state.bible);
  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [versions, setVersions] = useState([]);
  const [languages, setLanguages] = useState([]);
  const [allVersions, setAllVersions] = useState([]);
  const [versionLoading, setVersionLoading] = useState(false);
  const [isDropdownActive, setIsDropdownActive] = useState(false);
  const [activeLanguage, setActiveLanguage] = useState("eng");

  const handleDropdown = (e) => {
    isDropdownActive ? setIsDropdownActive(false) : setIsDropdownActive(true);
  };

  useEffect(() => {
    const eventHandler = (e) => {
      let bibleVersionContainer = document.querySelector(`#${containerKey}`);
      if (bibleVersionContainer && !bibleVersionContainer?.contains(e.target)) {
        setIsDropdownActive(false);
      }
    };
    document.addEventListener("mouseup", eventHandler);

    return () => document.removeEventListener("mouseup", eventHandler);
  }, []);

  useEffect(() => {
    if (allBibleLanguages?.length > 0) setLanguages(allBibleLanguages);
  }, [allBibleLanguages]);

  const fetchBibleVersions = (language) => {
    setVersionLoading(true);
    dispatch(
      getBibleVersionsAction({
        apiPayloadRequest: { languagecode: language },
        callback: (res) => {
          setVersions(res?.data);
          setAllVersions(res?.data);
          setVersionLoading(false);
        },
      })
    );
  };

  useEffect(() => {
    fetchBibleVersions(activeLanguage);
  }, [activeLanguage]);

  return (
    <div className="md:relative">
      <div
        className={`flex items-center bg-greyishV2 gap-2 md:gap-5 px-3 rounded-md cursor-pointer ${
          bibleParallelMode && showExit ? "border border-yellowish" : ""
        } ${showExit ? "py-1" : "py-2"}`}
        onClick={() => {
          if (bibleParallelMode && showExit) {
            dispatch(setBibleParallelModeAction(false));
          } else {
            handleDropdown();
          }
        }}
      >
        {showExit ? (
          <span className="hidden md:block">
            {bibleParallelMode
              ? "Exit Parallel"
              : currentLanguageTitles?.[0]?.bible?.parallel_mode_lbl ?? "Parallel"}
          </span>
        ) : (
          <>
            <span className="uppercase">{parallelVersionDetails?.abbr?.substring(3)}</span>
            <img src={DropDownIcon} alt="DropDownIcon" />
          </>
        )}
        {showParallelIcons && (
          <div className="flex items-center">
            <TiDocumentText className="text-3xl text-yellowish" />
            <TiDocumentText className="text-3xl text-yellowish" />
          </div>
        )}
      </div>
      {isDropdownActive && (
        <div
          className="w-full md:w-[32vw] text-white absolute bg-greyishV2/90 backdrop-blur-md p-6 md:p-8 rounded-3xl rounded-ss-none left-0 md:left-auto top-16 md:top-20 h-[70vh] z-50 flex flex-col overflow-hidden"
          id={containerKey}
        >
          <div className="flex flex-wrap gap-2 md:gap-4 pb-4 text-sm md:text-base">
            {Object.values(SEARCH_TABS)?.map((tab, index) => (
              <span
                key={`video-tabitem${index}`}
                className={`rounded-full border ${
                  activeParallelSearchTab === tab
                    ? "text-yellowish border-yellowish"
                    : "border-greyish text-greyish"
                } py-1 px-5 cursor-pointer`}
                onClick={() => dispatch(setActiveParallelSearchTabAction(tab))}
              >
                {currentLanguageTitles?.[0]?.bible?.[SEARCH_TABS_KEYS?.[tab]] ?? tab}
              </span>
            ))}
          </div>

          <BibleVersionSearchV2
            isVisible={activeParallelSearchTab === SEARCH_TABS.BIBLE}
            setIsDropdownActive={setIsDropdownActive}
            bibleParallelMode={true}
            versions={versions}
            allVersions={allVersions}
            setVersions={setVersions}
            loading={versionLoading}
            handleSelection={(version) => {
              dispatch(
                getVersionBooksAction({
                  apiPayloadRequest: { versionid: version },
                  callback: (res) => {
                    if (res?.data?.books?.length) {
                      dispatch(setParallelVersionDetailsAction(res?.data));
                      dispatch(setBibleParallelModeAction(true));
                    } else {
                      Toastiy("No Chapter available for selected version");
                    }
                  },
                })
              );
              dispatch(setParallelVersionDetailsAction(version));
            }}
          />
          <BibleLangaugeSearchV2
            isVisible={activeParallelSearchTab === SEARCH_TABS.LANGUAGE}
            setIsDropdownActive={setIsDropdownActive}
            handleLanguageChange={(lang) => {
              setActiveLanguage(lang);
              dispatch(setActiveParallelSearchTabAction(SEARCH_TABS.BIBLE));
            }}
          />
          <BibleCountrySearchV2
            isVisible={activeParallelSearchTab === SEARCH_TABS.COUNTRY}
            setIsDropdownActive={setIsDropdownActive}
            loading={false}
            setLanguages={setLanguages}
            languages={languages}
            handleLanguageChange={(e) => {
              setActiveLanguage(e.target.value);
              dispatch(setActiveParallelSearchTabAction(SEARCH_TABS.BIBLE));
            }}
          />
        </div>
      )}
    </div>
  );
};

export default ParallelVerses;
