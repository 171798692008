import { useEffect, useState } from "react";
import { BsLightningCharge, BsX } from "react-icons/bs";
import ReactModal from "react-modal";
import { useSelector } from "react-redux";
import { getCurrentWeekDates } from "../../constants/bible";
import { format } from "date-fns";

const StreakModal = ({ showModal, setShowModal, streakDetails }) => {
  const { userProfileDetails, currentLanguageTitles } = useSelector(
    (state) => state.languageReducer.data
  );

  const [weekData, setWeekData] = useState([]);

  console.log("weekData", weekData);

  useEffect(() => {
    if (streakDetails) {
      const weekDates = getCurrentWeekDates();
      const weeklyData = [];
      weekDates.forEach((date, index) => {
        const streakData = streakDetails?.data?.find(
          (d) => d?.record_date === format(date, "yyyy-MM-dd")
        );
        weeklyData.push({ date: date, streakData: streakData ?? null });
      });
      setWeekData(weeklyData);
    }
  }, [streakDetails]);

  return (
    <ReactModal
      style={{
        overlay: {
          zIndex: 1000, // Adjust this value as needed
        },
        content: {
          zIndex: 1001, // Adjust this value as needed
        },
      }}
      className="flex justify-center items-center h-[100vh] overflow-hidden bg-black bg-opacity-80 relative "
      isOpen={showModal}
      onRequestClose={() => {
        setShowModal(false);
      }}
    >
      <div className="h-[90vh] w-10/12 md:w-4/12 flex items-center justify-center">
        <div className="py-10 px-8 md:px-12 bg-black w-full max-h-full h-fit object-cover relative overflow-y-auto rounded-xl flex flex-col items-center text-white">
          <button
            className="absolute  right-3 top-3 z-50"
            onClick={() => {
              setShowModal(false);
            }}
          >
            <BsX size={40} className="text-sm text-yellowish" />
          </button>
          <h2 className="text-3xl">
            {currentLanguageTitles?.[0]?.bible?.streak_greetings_lbl ?? "Hey"}{" "}
            {userProfileDetails?.firstName}!!!
          </h2>
          <p className="my-4 text-center">
            {currentLanguageTitles?.[0]?.bible?.streak_subheading_lbl ??
              "This to help you to read God's Word everyday."}
          </p>

          <div className="flex items-center mb-6">
            <BsLightningCharge className="text-yellowish text-6xl" />
            <div className="flex flex-col">
              <p className="font-extrabold text-5xl leading-none">
                {streakDetails?.stats?.dailystreaks}
              </p>
              <span className="leading-none font-semibold text-sm">
                {currentLanguageTitles?.[0]?.bible?.streak_days_lbl ?? "days"}
              </span>
            </div>
          </div>

          <p className="text-greyish ">
            <span className="text-xl font-extrabold">
              {streakDetails?.stats?.maxcontinousweeks ?? 0}
            </span>{" "}
            {currentLanguageTitles?.[0]?.bible?.streak_weeks_lbl ?? "weeks in a row"}
          </p>
          <p className="text-greyish">
            <span className="text-xl font-extrabold">
              {streakDetails?.stats?.daysinthisyear ?? 0}
            </span>{" "}
            {currentLanguageTitles?.[0]?.bible?.streak_year_lbl ??
              "days in Today With God this year"}
          </p>

          <div className="grid grid-cols-7 mt-5 mb-12 rounded-xl border border-greyish">
            {weekData?.map((item, index) => (
              <div
                className="flex flex-col gap-1 items-center py-1.5 px-3"
                key={`week-data${index}`}
              >
                <p>{format(item?.date, "EEE")}</p>
                <div className="relative text-[0.5rem] flex">
                  <p
                    className={`text-xs ${
                      format(new Date(), "yyyy-MM-dd") === format(item?.date, "yyyy-MM-dd")
                        ? "text-yellowish font-extrabold"
                        : "text-greyish font-medium"
                    }`}
                  >
                    {format(item?.date, "dd")}
                  </p>
                  {item?.streakData && (
                    <BsLightningCharge className="text-yellowish absolute -top-1 -right-2" />
                  )}
                </div>
              </div>
            ))}
          </div>

          <p className="text-yellowish/70 text-center">
            {currentLanguageTitles?.[0]?.bible?.steak_verse_lbl ??
              "“Man shall not live by bread alone, but by every Word that comes from the mouth of God.”"}
          </p>
        </div>
      </div>
    </ReactModal>
  );
};

export default StreakModal;
