import React, { useCallback, useRef, useState } from "react";
import ReactModal from "react-modal";
import Webcam from "react-webcam";
import { FaStop } from "react-icons/fa";

import Album from "../../assets/icons/Notes/album.svg";
import FlipCamera from "../../assets/icons/Notes/flipCamera.svg";
import CloseIcon from "../../assets/icons/Notes/closeIcon.svg";

import { Swiper, SwiperSlide } from "swiper/react";
import { Keyboard, Mousewheel } from "swiper/modules";

const NotesWebcam = ({ isOpen, onClose, addItem }) => {
  const webcamRef = useRef(null);
  const sliderRef = useRef(null);
  const inputRef = useRef(null);
  const mediaRecorderRef = useRef(null);
  const [capturing, setCapturing] = useState(false);
  const [recordedChunks, setRecordedChunks] = useState([]);
  const [startUpload, setStartUpload] = useState(false);
  const [currentSlide, setCurrentSlide] = useState(0);
  const allVerses = ["Photo", "Video"];
  const videoConstraints = {
    width: 1200,
    height: 800,
    facingMode: "user",
  };

  const handleStartCaptureClick = useCallback(() => {
    setCapturing(true);
    mediaRecorderRef.current = new MediaRecorder(webcamRef.current.stream, {
      mimeType: "video/webm",
    });
    mediaRecorderRef.current.addEventListener("dataavailable", handleDataAvailable);
    mediaRecorderRef.current.start();
  }, [webcamRef, setCapturing, mediaRecorderRef]);

  const handleDataAvailable = useCallback(({ data }) => {
    if (data.size > 0) {
      setRecordedChunks((prev) => prev.concat(data));
    }
  }, []);

  const handleStopCaptureClick = useCallback(() => {
    mediaRecorderRef.current.stop();
    setCapturing(false);
    setStartUpload(true);
  }, [mediaRecorderRef, webcamRef, setCapturing]);

  const capture = useCallback(() => {
    const imageSrc = webcamRef.current.getScreenshot();
    const byteString = atob(imageSrc.split(",")[1]);
    const ab = new ArrayBuffer(byteString.length);
    const ia = new Uint8Array(ab);
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i);
    }
    const blob = new Blob([ab], { type: "image/jpeg" });
    const file = new File([blob], "captured-image.jpg", { type: "image/jpeg" });

    addItem(file, currentSlide === 0 ? "image" : "video");
    onClose(false);
  }, [webcamRef]);

  const handleSlideChange = (swiper) => {
    setCurrentSlide(swiper.activeIndex); // Update the current slide index
    console.log("Slide changed to:", swiper.activeIndex);
  };
  const handleSlideClick = (index) => {
    setCurrentSlide(index);
    console.log("sliderRef.current", sliderRef.current);
    sliderRef.current?.slideTo(index, 500);
  };

  const onSave = () => {
    const blob = new Blob(recordedChunks, {
      type: "video/webm",
    });
    const file = new File([blob], "react-webcam-stream-capture.mp4", {
      type: "video/mp4",
    });

    console.log("file78", file);
    addItem(file, currentSlide === 0 ? "image" : "video");
    onClose(false);
  };

  return (
    <>
      <ReactModal
        isOpen={isOpen}
        className={"overflow-hidden"}
        onRequestClose={() => onClose(false)}
      >
        <div className="w-full h-[100vh] bg-blackish flex items-center justify-center flex-col gap-6 relative">
          <div className="bg-black h-[80%] md:h-[90%] w-[90%] md:w-fit flex flex-col relative">
            <>
              <Webcam
                style={{
                  height: "93%",
                  width: "100%",
                  borderRadius: "",
                }}
                audio={false}
                ref={webcamRef}
                screenshotFormat="image/jpeg"
                videoConstraints={videoConstraints}
                mirrored={false}
              />
              <div
                className="bg-black bg-opacity-60 aspect-square rounded-full absolute top-2 left-2 p-3 cursor-pointer"
                onClick={() => {
                  onClose(false);
                }}
              >
                <img src={CloseIcon} alt="CloseIcon" className="h-5" />
              </div>
              <div className="flex flex-col md:flex-row gap-4 items-center absolute left-[11%] bottom-[15%]">
                <div className="upload-btn-wrapper">
                  <button
                    className="cursor-pointer text-red-200 p-3 rounded-full bg-black bg-opacity-60 flex gap-2 items-center text-xs font-semibold hover:bg-red-700 transition-all ease-in-out duration-500"
                    onClick={() => inputRef.current.click()}
                  >
                    <img src={Album} alt="Album" className="cursor-pointer" />
                  </button>
                  <input
                    type="file"
                    ref={inputRef}
                    multiple
                    onChange={(e) => {
                      const { files } = e.target;
                      addItem(files?.[0], currentSlide === 0 ? "image" : "video");
                      onClose(false);
                    }}
                    name="myfile"
                    accept={`${
                      currentSlide === 0
                        ? "image/jpeg, image/png, image/gif, image/svg+xml, image/webp"
                        : "video/mp4, video/webm, video/ogg"
                    }`}
                    className="cursor-pointer hidden"
                  />
                </div>
              </div>
              {currentSlide === 0 ? (
                <button
                  className="text-red-200 p-6 rounded-full border-4 border-red-500 bg-red-800 bg-opacity-60 absolute left-1/2 -translate-x-1/2 bottom-[14%]"
                  onClick={capture}
                >
                  <FaStop size={20} className="invisible" />
                </button>
              ) : (
                <>
                  {capturing ? (
                    <div
                      className="h-24 aspect-square rounded-full border-4 border-white bg-black bg-opacity-60 absolute bottom-[14%] left-1/2 -translate-x-1/2 flex items-center justify-center"
                      onClick={handleStopCaptureClick}
                    >
                      <FaStop size={20} className="text-white" />
                    </div>
                  ) : (
                    <div
                      className="h-24 aspect-square rounded-full border-4 border-white bg-black bg-opacity-60 absolute bottom-[14%] left-1/2 -translate-x-1/2 flex items-center justify-center"
                      onClick={handleStartCaptureClick}
                    >
                      <div className="h-20 aspect-square rounded-full bg-white"></div>
                    </div>
                  )}
                </>
              )}

              {startUpload && recordedChunks.length > 0 && (
                <button
                  onClick={onSave}
                  className="absolute right-10 top-10 bg-yellowish drop-shadow-lg rounded-md px-4 py-2 text-black"
                >
                  Save
                </button>
              )}

              <div className="flex flex-col md:flex-row gap-4 items-center absolute right-[11%] bottom-[15%]">
                <div className="upload-btn-wrapper cursor-pointer">
                  <button className="text-red-200 p-3 rounded-full bg-black bg-opacity-60 flex gap-2 items-center text-xs font-semibold hover:bg-red-700 transition-all ease-in-out duration-500">
                    <img src={FlipCamera} alt="FlipCamera" className="h-[30px]" />
                  </button>
                </div>
              </div>
              <div className="bg-greyishV4 rounded-tl-2xl rounded-tr-2xl w-full h-20 absolute bottom-0 flex justify-center gap-5 pt-3">
                <Swiper
                  grabCursor={true}
                  slidesPerView={7}
                  spaceBetween={10}
                  centeredSlides={true}
                  className=""
                  modules={[Keyboard, Mousewheel]}
                  onSlideChange={handleSlideChange}
                  onSwiper={(swiper) => (sliderRef.current = swiper)}
                >
                  {allVerses?.map((row, index) => (
                    <SwiperSlide
                      key={index}
                      className={`!w-fit ${
                        index === currentSlide ? "text-[#F8BA34] text-xl" : "text-[#A3A3A3] text-xl"
                      } `}
                    >
                      <p
                        onClick={() => {
                          handleSlideClick(index);
                          console.log("index", index);
                        }}
                      >
                        {row}
                      </p>
                    </SwiperSlide>
                  ))}
                </Swiper>
              </div>
            </>
          </div>
        </div>
      </ReactModal>
    </>
  );
};

export default NotesWebcam;
