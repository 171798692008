import { useAuth0 } from "@auth0/auth0-react";
import React from "react";
import { RiBookmarkFill } from "react-icons/ri";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

const ContinueReading = ({ streakDetailsLoading = false, streakDetails }) => {
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  return (
    isAuthenticated &&
    (streakDetailsLoading ? (
      <div className="animate-pulse bg-zinc-600 rounded-xl w-full md:w-fit h-10"></div>
    ) : (
      streakDetails?.continuereading?.chapterid && (
        <div
          className="flex items-center gap-3 bg-greyishV2 p-2 rounded-md cursor-pointer"
          onClick={() =>
            navigate(
              `/chapter/${streakDetails?.continuereading?.versionid}/${streakDetails?.continuereading?.bookid}/${streakDetails?.continuereading?.chapterid}`
            )
          }
        >
          <RiBookmarkFill className="text-yellowish text-2xl" />
          <p className="">
            {currentLanguageTitles?.[0]?.bible?.continue_reading_lbl ?? "Continue reading"}
            <span className="text-yellowish px-1 font-semibold">
              {streakDetails?.continuereading?.bookname} {streakDetails?.continuereading?.chapterid}
            </span>
          </p>
        </div>
      )
    ))
  );
};

export default ContinueReading;
