import { startOfWeek, endOfWeek, eachDayOfInterval } from "date-fns";

export const oldTestament = [
  // Old Testament
  "Genesis",
  "Exodus",
  "Leviticus",
  "Numbers",
  "Deuteronomy",
  "Joshua",
  "Judges",
  "Ruth",
  "1 Samuel",
  "2 Samuel",
  "1 Kings",
  "2 Kings",
  "1 Chronicles",
  "2 Chronicles",
  "Ezra",
  "Nehemiah",
  "Esther",
  "Job",
  "Psalms",
  "Proverbs",
  "Ecclesiastes",
  "Song of Solomon",
  "Isaiah",
  "Jeremiah",
  "Lamentations",
  "Ezekiel",
  "Daniel",
  "Hosea",
  "Joel",
  "Amos",
  "Obadiah",
  "Jonah",
  "Micah",
  "Nahum",
  "Habakkuk",
  "Zephaniah",
  "Haggai",
  "Zechariah",
  "Malachi",
];

export const newTestament = [
  "Matthew",
  "Mark",
  "Luke",
  "John",
  "Acts",
  "Romans",
  "1 Corinthians",
  "2 Corinthians",
  "Galatians",
  "Ephesians",
  "Philippians",
  "Colossians",
  "1 Thessalonians",
  "2 Thessalonians",
  "1 Timothy",
  "2 Timothy",
  "Titus",
  "Philemon",
  "Hebrews",
  "James",
  "1 Peter",
  "2 Peter",
  "1 John",
  "2 John",
  "3 John",
  "Jude",
  "Revelation",
];

export const initialVersionDetails = {
  abbr: "ENGESV",
  alphabet: {
    script: "Latn",
    name: "Latin",
    direction: "ltr",
    unicode: true,
    requires_font: false,
    primary_font: null,
  },
  mark: "© The ESV Bible® (The Holy Bible, English Standard Version®) Copyright © 2001 by Crossway, a publishing ministry of Good News Pu",
  name: "English Standard Version®",
  description:
    'The English Standard Version (ESV) is an English translation of the Christian Bible. It is a revision of the 1971 edition of the Revised Standard Version. The translators\' stated purpose was to follow an \\\\\\"essentially literal\\\\\\" translation philosophy.',
  vname: "English Standard Version®",
  vdescription:
    'The English Standard Version (ESV) is an English translation of the Christian Bible. It is a revision of the 1971 edition of the Revised Standard Version. The translators\' stated purpose was to follow an \\\\\\"essentially literal\\\\\\" translation philosophy.',
  publishers: [
    {
      id: 5,
      slug: "Crossway-Good-News-Publishers",
      abbreviation: null,
      primaryColor: "#7998ac",
      secondaryColor: "#b7b09e",
      inactive: 0,
      url_facebook: "https://www.facebook.com/CrosswayBooks",
      url_website: "https://www.crossway.org",
      url_donate: "https://www.crossway.org/donate/",
      url_twitter: "https://twitter.com/crossway",
      address: "1300 Crescent Street; Wheaton, IL 60187 USA",
      address2: null,
      city: null,
      state: null,
      country: "US",
      zip: 60187,
      phone: "1-630-682-4300",
      email: "info@crossway.org",
      email_director: null,
      latitude: 41.8686485,
      longitude: -88.0874046,
      pivot: {
        bible_id: "ENGESV",
        organization_id: 5,
        relationship_type: "publisher",
      },
      logo_icon: {
        language_id: 6414,
        language_iso: "eng",
        url: "https://images.bible.cloud/icon/crossway_icon.svg",
        icon: 1,
      },
      translations: [
        {
          language_id: 6411,
          vernacular: 0,
          alt: 0,
          name: "Crossway (Publicaciones Buenas Nuevas)",
          description_short: null,
        },
        {
          language_id: 6414,
          vernacular: 0,
          alt: 0,
          name: "Crossway",
          description_short:
            "Crossway is a non-profit Christian ministry that proclaims the gospel through publishing translations of the Bible like the ESV.",
        },
      ],
    },
  ],
  providers: [],
  language: "English: USA",
  language_id: 17045,
  iso: "eng",
  language_rolv_code: "00025",
  date: "2001",
  country: "United States",
  books: [
    {
      book_id: "GEN",
      name: "Genesis",
      name_short: "Genesis",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50,
      ],
      book_seq: "A01",
      testament: "OT",
    },
    {
      book_id: "EXO",
      name: "Exodus",
      name_short: "Exodus",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40,
      ],
      book_seq: "A02",
      testament: "OT",
    },
    {
      book_id: "LEV",
      name: "Leviticus",
      name_short: "Leviticus",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27,
      ],
      book_seq: "A03",
      testament: "OT",
    },
    {
      book_id: "NUM",
      name: "Numbers",
      name_short: "Numbers",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      ],
      book_seq: "A04",
      testament: "OT",
    },
    {
      book_id: "DEU",
      name: "Deuteronomy",
      name_short: "Deuteronomy",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34,
      ],
      book_seq: "A05",
      testament: "OT",
    },
    {
      book_id: "JOS",
      name: "Joshua",
      name_short: "Joshua",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      ],
      book_seq: "A06",
      testament: "OT",
    },
    {
      book_id: "JDG",
      name: "Judges",
      name_short: "Judges",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      book_seq: "A07",
      testament: "OT",
    },
    {
      book_id: "RUT",
      name: "Ruth",
      name_short: "Ruth",
      chapters: [1, 2, 3, 4],
      book_seq: "A08",
      testament: "OT",
    },
    {
      book_id: "1SA",
      name: "1 Samuel",
      name_short: "1 Samuel",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
      ],
      book_seq: "A09",
      testament: "OT",
    },
    {
      book_id: "2SA",
      name: "2 Samuel",
      name_short: "2 Samuel",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      ],
      book_seq: "A10",
      testament: "OT",
    },
    {
      book_id: "1KI",
      name: "1 Kings",
      name_short: "1 Kings",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      book_seq: "A11",
      testament: "OT",
    },
    {
      book_id: "2KI",
      name: "2 Kings",
      name_short: "2 Kings",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
      ],
      book_seq: "A12",
      testament: "OT",
    },
    {
      book_id: "1CH",
      name: "1 Chronicles",
      name_short: "1 Chronicles",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29,
      ],
      book_seq: "A13",
      testament: "OT",
    },
    {
      book_id: "2CH",
      name: "2 Chronicles",
      name_short: "2 Chronicles",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36,
      ],
      book_seq: "A14",
      testament: "OT",
    },
    {
      book_id: "EZR",
      name: "Ezra",
      name_short: "Ezra",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      book_seq: "A15",
      testament: "OT",
    },
    {
      book_id: "NEH",
      name: "Nehemiah",
      name_short: "Nehemiah",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      book_seq: "A16",
      testament: "OT",
    },
    {
      book_id: "EST",
      name: "Esther",
      name_short: "Esther",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10],
      book_seq: "A17",
      testament: "OT",
    },
    {
      book_id: "JOB",
      name: "Job",
      name_short: "Job",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42,
      ],
      book_seq: "A18",
      testament: "OT",
    },
    {
      book_id: "PSA",
      name: "Psalms",
      name_short: "Psalms",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66, 67, 68, 69, 70, 71,
        72, 73, 74, 75, 76, 77, 78, 79, 80, 81, 82, 83, 84, 85, 86, 87, 88, 89, 90, 91, 92, 93, 94,
        95, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 106, 107, 108, 109, 110, 111, 112, 113,
        114, 115, 116, 117, 118, 119, 120, 121, 122, 123, 124, 125, 126, 127, 128, 129, 130, 131,
        132, 133, 134, 135, 136, 137, 138, 139, 140, 141, 142, 143, 144, 145, 146, 147, 148, 149,
        150,
      ],
      book_seq: "A19",
      testament: "OT",
    },
    {
      book_id: "PRO",
      name: "Proverbs",
      name_short: "Proverbs",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31,
      ],
      book_seq: "A20",
      testament: "OT",
    },
    {
      book_id: "ECC",
      name: "Ecclesiastes",
      name_short: "Ecclesiastes",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      book_seq: "A21",
      testament: "OT",
    },
    {
      book_id: "SNG",
      name: "The Song of Solomon",
      name_short: "The Song of Solomon",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8],
      book_seq: "A22",
      testament: "OT",
    },
    {
      book_id: "ISA",
      name: "Isaiah",
      name_short: "Isaiah",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50, 51, 52, 53, 54, 55, 56, 57, 58, 59, 60, 61, 62, 63, 64, 65, 66,
      ],
      book_seq: "A23",
      testament: "OT",
    },
    {
      book_id: "JER",
      name: "Jeremiah",
      name_short: "Jeremiah",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
        49, 50, 51, 52,
      ],
      book_seq: "A24",
      testament: "OT",
    },
    {
      book_id: "LAM",
      name: "Lamentations",
      name_short: "Lamentations",
      chapters: [1, 2, 3, 4, 5],
      book_seq: "A25",
      testament: "OT",
    },
    {
      book_id: "EZK",
      name: "Ezekiel",
      name_short: "Ezekiel",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28, 29, 30, 31, 32, 33, 34, 35, 36, 37, 38, 39, 40, 41, 42, 43, 44, 45, 46, 47, 48,
      ],
      book_seq: "A26",
      testament: "OT",
    },
    {
      book_id: "DAN",
      name: "Daniel",
      name_short: "Daniel",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12],
      book_seq: "A27",
      testament: "OT",
    },
    {
      book_id: "HOS",
      name: "Hosea",
      name_short: "Hosea",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      book_seq: "A28",
      testament: "OT",
    },
    {
      book_id: "JOL",
      name: "Joel",
      name_short: "Joel",
      chapters: [1, 2, 3],
      book_seq: "A29",
      testament: "OT",
    },
    {
      book_id: "AMO",
      name: "Amos",
      name_short: "Amos",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9],
      book_seq: "A30",
      testament: "OT",
    },
    {
      book_id: "OBA",
      name: "Obadiah",
      name_short: "Obadiah",
      chapters: [1],
      book_seq: "A31",
      testament: "OT",
    },
    {
      book_id: "JON",
      name: "Jonah",
      name_short: "Jonah",
      chapters: [1, 2, 3, 4],
      book_seq: "A32",
      testament: "OT",
    },
    {
      book_id: "MIC",
      name: "Micah",
      name_short: "Micah",
      chapters: [1, 2, 3, 4, 5, 6, 7],
      book_seq: "A33",
      testament: "OT",
    },
    {
      book_id: "NAM",
      name: "Nahum",
      name_short: "Nahum",
      chapters: [1, 2, 3],
      book_seq: "A34",
      testament: "OT",
    },
    {
      book_id: "HAB",
      name: "Habakkuk",
      name_short: "Habakkuk",
      chapters: [1, 2, 3],
      book_seq: "A35",
      testament: "OT",
    },
    {
      book_id: "ZEP",
      name: "Zephaniah",
      name_short: "Zephaniah",
      chapters: [1, 2, 3],
      book_seq: "A36",
      testament: "OT",
    },
    {
      book_id: "HAG",
      name: "Haggai",
      name_short: "Haggai",
      chapters: [1, 2],
      book_seq: "A37",
      testament: "OT",
    },
    {
      book_id: "ZEC",
      name: "Zechariah",
      name_short: "Zechariah",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14],
      book_seq: "A38",
      testament: "OT",
    },
    {
      book_id: "MAL",
      name: "Malachi",
      name_short: "Malachi",
      chapters: [1, 2, 3, 4],
      book_seq: "A39",
      testament: "OT",
    },
    {
      book_id: "MAT",
      name: "Matthew",
      name_short: "Matthew",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28,
      ],
      book_seq: "B01",
      testament: "NT",
    },
    {
      book_id: "MRK",
      name: "Mark",
      name_short: "Mark",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      book_seq: "B02",
      testament: "NT",
    },
    {
      book_id: "LUK",
      name: "Luke",
      name_short: "Luke",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24,
      ],
      book_seq: "B03",
      testament: "NT",
    },
    {
      book_id: "JHN",
      name: "John",
      name_short: "John",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21],
      book_seq: "B04",
      testament: "NT",
    },
    {
      book_id: "ACT",
      name: "Acts",
      name_short: "Acts",
      chapters: [
        1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22, 23, 24, 25,
        26, 27, 28,
      ],
      book_seq: "B05",
      testament: "NT",
    },
    {
      book_id: "ROM",
      name: "Romans",
      name_short: "Romans",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      book_seq: "B06",
      testament: "NT",
    },
    {
      book_id: "1CO",
      name: "1 Corinthians",
      name_short: "1 Corinthians",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
      book_seq: "B07",
      testament: "NT",
    },
    {
      book_id: "2CO",
      name: "2 Corinthians",
      name_short: "2 Corinthians",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      book_seq: "B08",
      testament: "NT",
    },
    {
      book_id: "GAL",
      name: "Galatians",
      name_short: "Galatians",
      chapters: [1, 2, 3, 4, 5, 6],
      book_seq: "B09",
      testament: "NT",
    },
    {
      book_id: "EPH",
      name: "Ephesians",
      name_short: "Ephesians",
      chapters: [1, 2, 3, 4, 5, 6],
      book_seq: "B10",
      testament: "NT",
    },
    {
      book_id: "PHP",
      name: "Philippians",
      name_short: "Philippians",
      chapters: [1, 2, 3, 4],
      book_seq: "B11",
      testament: "NT",
    },
    {
      book_id: "COL",
      name: "Colossians",
      name_short: "Colossians",
      chapters: [1, 2, 3, 4],
      book_seq: "B12",
      testament: "NT",
    },
    {
      book_id: "1TH",
      name: "1 Thessalonians",
      name_short: "1 Thessalonians",
      chapters: [1, 2, 3, 4, 5],
      book_seq: "B13",
      testament: "NT",
    },
    {
      book_id: "2TH",
      name: "2 Thessalonians",
      name_short: "2 Thessalonians",
      chapters: [1, 2, 3],
      book_seq: "B14",
      testament: "NT",
    },
    {
      book_id: "1TI",
      name: "1 Timothy",
      name_short: "1 Timothy",
      chapters: [1, 2, 3, 4, 5, 6],
      book_seq: "B15",
      testament: "NT",
    },
    {
      book_id: "2TI",
      name: "2 Timothy",
      name_short: "2 Timothy",
      chapters: [1, 2, 3, 4],
      book_seq: "B16",
      testament: "NT",
    },
    {
      book_id: "TIT",
      name: "Titus",
      name_short: "Titus",
      chapters: [1, 2, 3],
      book_seq: "B17",
      testament: "NT",
    },
    {
      book_id: "PHM",
      name: "Philemon",
      name_short: "Philemon",
      chapters: [1],
      book_seq: "B18",
      testament: "NT",
    },
    {
      book_id: "HEB",
      name: "Hebrews",
      name_short: "Hebrews",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13],
      book_seq: "B19",
      testament: "NT",
    },
    {
      book_id: "JAS",
      name: "James",
      name_short: "James",
      chapters: [1, 2, 3, 4, 5],
      book_seq: "B20",
      testament: "NT",
    },
    {
      book_id: "1PE",
      name: "1 Peter",
      name_short: "1 Peter",
      chapters: [1, 2, 3, 4, 5],
      book_seq: "B21",
      testament: "NT",
    },
    {
      book_id: "2PE",
      name: "2 Peter",
      name_short: "2 Peter",
      chapters: [1, 2, 3],
      book_seq: "B22",
      testament: "NT",
    },
    {
      book_id: "1JN",
      name: "1 John",
      name_short: "1 John",
      chapters: [1, 2, 3, 4, 5],
      book_seq: "B23",
      testament: "NT",
    },
    {
      book_id: "2JN",
      name: "2 John",
      name_short: "2 John",
      chapters: [1],
      book_seq: "B24",
      testament: "NT",
    },
    {
      book_id: "3JN",
      name: "3 John",
      name_short: "3 John",
      chapters: [1],
      book_seq: "B25",
      testament: "NT",
    },
    {
      book_id: "JUD",
      name: "Jude",
      name_short: "Jude",
      chapters: [1],
      book_seq: "B26",
      testament: "NT",
    },
    {
      book_id: "REV",
      name: "Revelation",
      name_short: "Revelation",
      chapters: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16, 17, 18, 19, 20, 21, 22],
      book_seq: "B27",
      testament: "NT",
    },
  ],
  links: [
    {
      bible_id: "ENGESV",
      type: "web",
      url: "http://bibles.org/versions/eng-ESV",
      title: "BibleSearch",
      provider: "American Bible Society",
      organization_id: 1,
    },
    {
      bible_id: "ENGESV",
      type: "audio_web",
      url: "http://crossway.org/product/9781433502958/",
      title: "Hear the Word",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433503764/",
      title: "German/English Parallel Hardcover",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433504006/",
      title: "Hardcover MacArthur Study",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433511448/",
      title: "Large Print - Hardcover",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433528651/",
      title: "Student Study",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433530302/",
      title: "Hebrew-English: BHS/ESV",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433537523/",
      title: "Spanish/English Parallel: RVR/ESV",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781433545207/",
      title: "For Kids - Hardcover",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781581346282/",
      title: "Greek-English Reverse Interlinear Hardcover",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781581347081/",
      title: "One Year Bible - Paperback",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://crossway.org/product/9781581347531/",
      title: "Outreach - Paperback",
      provider: "Crossway",
      organization_id: 5,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "https://bibleleague.ca/shop/english-standard-version-paperback-bible/",
      title: "FB",
      provider: "Bible League of Canada",
      organization_id: 2,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://www.biblescanada.com/catalog/products/4272.htm",
      title: "English Standard Version \\\\ Reina Valera 1960",
      provider: "Bible Society of Canada",
      organization_id: 56,
    },
    {
      bible_id: "ENGESV",
      type: "audio_web",
      url: "http://www.biblescanada.com/catalog/products/4700.htm",
      title: "Dramatized FB on CD",
      provider: "Bible Society of Canada",
      organization_id: 56,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "http://www.biblesociety-kenya.org/shop/index.php?route=product/product&path=2&product_id=119&sort=p.model&order=ASC",
      title: "FB",
      provider: "Bible Society of Kenya",
      organization_id: 84,
    },
    {
      bible_id: "ENGESV",
      type: "audio_web",
      url: "https://soundcloud.com/biblesociety/sets/marks-gospel-esv",
      title: "MRK",
      provider: "SoundCloud",
      organization_id: null,
    },
    {
      bible_id: "ENGESV",
      type: "web",
      url: "https://www.bible.com/bible/59/jhn.1.esv",
      title: "FB",
      provider: "YouVersion",
      organization_id: 531,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "https://www.biblesociety.org.uk/products/9780564048113/",
      title: "English-Welsh Bilingual Bible",
      provider: "British and Foreign Bible Society",
      organization_id: 153,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "https://www.biblesociety.org.uk/products/9780564050864/",
      title: "FB Chinese Union Version (Simplified) / English Standard Version",
      provider: "British and Foreign Bible Society",
      organization_id: 153,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "https://bibleleague.ca/shop/esv-holy-bible-value-edition/",
      title: "ESV Holy Bible, Value Edition",
      provider: "Bible League Canada",
      organization_id: 2,
    },
    {
      bible_id: "ENGESV",
      type: "print",
      url: "https://bibleleague.ca/shop/esv-large-print-paperback-bible/",
      title: "ESV Large Print Paperback Bible",
      provider: "Bible League Canada",
      organization_id: 2,
    },
    {
      bible_id: "ENGESV",
      type: "web",
      url: "https://www.biblegateway.com/versions/index.php?action=getVersionInfo&vid=47&lang=53",
      title: "English Standard Version",
      provider: "Bible Gateway",
      organization_id: 417,
    },
    {
      bible_id: "ENGESV",
      type: "web",
      url: "https://www.biblegateway.com/versions/index.php?action=getVersionInfo&vid=166&lang=53",
      title: "English Standard Version Anglicised",
      provider: "Bible Gateway",
      organization_id: 417,
    },
    {
      bible_id: "ENGESV",
      type: "web",
      url: "https://www.bible.com/versions/59",
      title: "ESV",
      provider: "YouVersion",
      organization_id: 531,
    },
    {
      bible_id: "ENGESV",
      type: "web",
      url: "https://live.bible.is/bible/ENGESV",
      title: "FB",
      provider: "Faith Comes By Hearing",
      organization_id: 9,
    },
    {
      bible_id: "ENGESV",
      type: "audio_web",
      url: "https://live.bible.is/bible/ENGESV",
      title: "FB",
      provider: "Faith Comes By Hearing",
      organization_id: 9,
    },
  ],
  filesets: {
    "dbp-prod": [
      {
        id: "ENGESVN1DA-opus16",
        type: "audio",
        size: "NT",
        stock_no: null,
        bitrate: "16kbps",
        codec: "opus",
        container: "webm",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN_ET-json",
        type: "text_json",
        size: "NT",
        stock_no: null,
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO1DA-opus16",
        type: "audio",
        size: "OT",
        stock_no: null,
        bitrate: "16kbps",
        codec: "opus",
        container: "webm",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN2DA",
        type: "audio_drama",
        size: "NT",
        stock_no: null,
        bitrate: "64kbps",
        codec: "mp3",
        container: "mp3",
        timing_est_err: "4",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO2DA-opus16",
        type: "audio_drama",
        size: "OT",
        stock_no: null,
        bitrate: "16kbps",
        codec: "opus",
        container: "webm",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO2DA",
        type: "audio_drama",
        size: "OT",
        stock_no: null,
        bitrate: "64kbps",
        codec: "mp3",
        container: "mp3",
        timing_est_err: "0",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO_ET-json",
        type: "text_json",
        size: "OT",
        stock_no: null,
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO_ET",
        type: "text_plain",
        size: "OT",
        stock_no: null,
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO1DA",
        type: "audio",
        size: "OT",
        stock_no: null,
        bitrate: "64kbps",
        codec: "mp3",
        container: "mp3",
        timing_est_err: "5",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN_ET",
        type: "text_plain",
        size: "NT",
        stock_no: null,
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN2DA-opus16",
        type: "audio_drama",
        size: "NT",
        stock_no: null,
        bitrate: "16kbps",
        codec: "opus",
        container: "webm",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO1SA",
        type: "audio_stream",
        size: "OT",
        stock_no: null,
        timing_est_err: "5",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN1SA",
        type: "audio_stream",
        size: "NT",
        stock_no: null,
        codec: "mp3",
        container: "mp3",
        timing_est_err: "4",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN1DA",
        type: "audio",
        size: "NT",
        stock_no: null,
        bitrate: "64kbps",
        codec: "mp3",
        container: "mp3",
        timing_est_err: "4",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN_ET-usx",
        type: "text_usx",
        size: "NT",
        stock_no: null,
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVN2SA",
        type: "audio_drama_stream",
        size: "NT",
        stock_no: null,
        codec: "mp3",
        container: "mp3",
        timing_est_err: "4",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO2SA",
        type: "audio_drama_stream",
        size: "OT",
        stock_no: null,
        timing_est_err: "0",
        volume: "English Standard Version®",
      },
      {
        id: "ENGESVO_ET-usx",
        type: "text_usx",
        size: "OT",
        stock_no: null,
        volume: "English Standard Version®",
      },
    ],
    "dbp-vid": [
      {
        id: "ENGESVP2DV",
        type: "video_stream",
        size: "NTP",
        stock_no: null,
        volume: "English Standard Version®",
        "youtube_playlist_id:JHN": "PLUx7vVXi-frOY4n5YrG8u5oyUvTJajzde",
        "youtube_playlist_id:LUK": "PLUx7vVXi-frO1YLMOALkMNBlZpaphhAxK",
        "youtube_playlist_id:MAT": "PLUx7vVXi-frMdx1mvvH0rpLPFmeVePzZ6",
        "youtube_playlist_id:MRK": "PLUx7vVXi-frNj9G-qyX1KeuqMzTt6tu-C",
      },
    ],
  },
  fonts: null,
};

export const getCurrentWeekDates = () => {
  // Get the start and end of the current week
  const start = startOfWeek(new Date(), { weekStartsOn: 0 }); // Week starts on Sunday
  const end = endOfWeek(new Date(), { weekStartsOn: 0 });

  // Get all dates of the week
  return eachDayOfInterval({ start, end });
};
