import React from "react";
import CommonCarousel from "../../components/home/CommonCarousel";
import { useSelector } from "react-redux";
import { twgJournalsCategories } from "../../constants/common";

const Categories = ({ data }) => {
  const { isMobile } = useSelector((state) => state.common);
  return (
    <div>
      {data?.map((item, index) => {
        const items = item?.journals?.map((d) => {
          const thumbnail = d?.bg_Images?.find((dd) => dd?.type_of_url === "1");
          return {
            _id: d?._id,
            title: d?.journal_title,
            name: d?.journal_title,
            subtitle: d?.journal_description,
            image: thumbnail?.url_link,
            type: "journal",
          };
        });
        return (
          items?.length > 0 && (
            <CommonCarousel
              key={`journal-category${index}`}
              data={items}
              seeAll={true}
              dataTitle={false}
              dynamiclinkTo={true}
              perViewImages={isMobile ? 2 : 5}
              imageType={"landscape"}
              title={item?.categoryname}
              showTitle={true}
              showItemTitle={true}
            />
          )
        );
      })}

      {data?.length === 0 && (
        <div className="w-full flex items-center justify-center h-[30vh]">
          No categories Available
        </div>
      )}
    </div>
  );
};

export default Categories;
