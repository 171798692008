import { LuVideo } from "react-icons/lu";
import { PiTextAaBold } from "react-icons/pi";

import AudioPlay from "../../assets/icons/audioPlay.svg";

const VersionDetailCard = ({ onClick, data, showAvailableTypes = false, active = false }) => {
  const isTextAvailable = [
    ...(data?.filesets?.["dbp-prod"] ?? []),
    ...(data?.filesets?.["dbp-vid"] ?? []),
  ]?.some((d) => d?.type === "text_plain" || d?.type === "text_format");

  const isAudioAvailable = [
    ...(data?.filesets?.["dbp-prod"] ?? []),
    ...(data?.filesets?.["dbp-vid"] ?? []),
  ]?.some((d) => ["audio", "audio_drama"].includes(d?.type) && d?.codec === "mp3");

  const isVideoAvailable = [
    ...(data?.filesets?.["dbp-prod"] ?? []),
    ...(data?.filesets?.["dbp-vid"] ?? []),
  ]?.some((d) => d?.type === "video_stream");

  const getAvailableText = () => {
    const availableType = [];
    if (isTextAvailable) {
      availableType.push("Text");
    }
    if (isAudioAvailable) {
      availableType.push("Audio");
    }
    if (isVideoAvailable) {
      availableType.push("Video");
    }

    if (availableType?.length === 3) {
      return `${availableType?.slice(0, 2)?.join(", ")} & ${availableType?.[2]}`;
    } else if (availableType?.length === 2) {
      return availableType?.splice(0, 2)?.join(" & ");
    } else if (availableType?.length === 1) {
      return availableType?.[0];
    }
    return null;
  };

  return (
    <div
      className={`w-full flex items-center justify-between py-2 hover:bg-black/30 rounded-md  pl-2 pr-8 ${
        active ? "bg-yellowish/30" : ""
      }`}
      onClick={() => onClick(data?.abbr)}
    >
      <div className="flex items-center gap-5 cursor-pointer">
        <div className="bg-greyish/30 text min-w-16 md:min-w-20 w-20 py-4 px-0.5 md:px-2 text-wrap flex items-center justify-center rounded-md">
          {data?.abbr?.substring(3)}
        </div>
        <div className="flex flex-col gap-1 items-start">
          {showAvailableTypes && (
            <div className="flex items-center gap-2">
              <p className="text-xs md:text-sm text-greyish">{getAvailableText()}</p>
              {isTextAvailable && <PiTextAaBold className="text-lg md:text-xl text-greyish" />}
              {isVideoAvailable && <LuVideo className="text-xl md:text-2xl text-greyish" />}
              {isAudioAvailable && <img src={AudioPlay} alt="AudioPlay" className="h-2.5 md:h-4" />}
            </div>
          )}
          <p className="text-sm md:text-base">{data?.name}</p>
        </div>
      </div>
    </div>
  );
};

export default VersionDetailCard;
