import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import StreakModal from "./StreakModal";
import ChapterDetails from "./ChapterDetails";
import AudioVideoCompModel from "./AudioVideoCompModel";
import BibleHeader from "../../components/bible/BibleHeader";
import {
  getUserJournalsAction,
  setActiveSearchTabAction,
  setParallelVersionDetailsAction,
  setUserJournalsAction,
  updateUserStreakAction,
} from "../../store/Bible/BibleAction";
import { IoIosArrowDropleft, IoIosArrowDropright } from "react-icons/io";
import { useAuth0 } from "@auth0/auth0-react";
import { SEARCH_TABS } from "../../constants/common";

const ChapterContainer = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { isAuthenticated } = useAuth0();
  const { chapterNo, bookId } = useParams();
  const {
    bibleParallelMode,
    versionDetails,
    parallelVersionDetails,
    bookChapterDetailsLoading,
    parallelBookChapterDetailsLoading,
  } = useSelector((state) => state.bible);

  const [streakModalOpen, setStreakModalOpen] = useState(false);
  const [activeVerseDetails, setActiveVerseDetails] = useState(null);

  const fetchUserJournals = () => {
    dispatch(
      getUserJournalsAction({
        callback: (response) => {
          dispatch(setUserJournalsAction(response?.data));
        },
      })
    );
  };

  const updateUserStreak = () => {
    if (isAuthenticated) {
      dispatch(
        updateUserStreakAction({
          apiPayloadRequest: {
            chapterObjId: activeVerseDetails?._id,
            versuObjId: activeVerseDetails?.verses?.[0]?.verseObjId,
          },
          callback: (response) => {
            console.log("user streak response", response);
          },
        })
      );
    }
  };

  useEffect(() => {
    dispatch(setParallelVersionDetailsAction({}));
    if (isAuthenticated) fetchUserJournals();
  }, [isAuthenticated]);

  useEffect(() => {
    if (activeVerseDetails?._id && isAuthenticated) updateUserStreak();
  }, [activeVerseDetails?._id, isAuthenticated]);

  const bookChapters = versionDetails?.books?.find((d) => d?.book_id === bookId) ?? {};

  console.log("bookChapters", bookChapters);
  console.log("activeVerseDetails1", activeVerseDetails);
  console.log("versionDetailsmain", versionDetails);

  return (
    <>
      <div className="w-full flex flex-col bg-black flex-1 overflow-y-auto px-4 md:px-8">
        <BibleHeader showParallelMode={true} showBibleBook={true} />
        <div className="w-full items flex h-full gap-4 text-white">
          {
            <div className="h-[70vh] flex items-center justify-center pr-3 text-yellowish text-3xl">
              {bookChapters?.chapters?.length > 0 && parseInt(chapterNo) > 1 && (
                <IoIosArrowDropleft
                  className="cursor-pointer"
                  onClick={() => {
                    dispatch(setActiveSearchTabAction(SEARCH_TABS.BIBLE));
                    navigate(
                      `/chapter/${versionDetails?.abbr}/${bookId}/${parseInt(chapterNo) - 1}`
                    );
                  }}
                />
              )}
            </div>
          }
          <div className="flex-1 flex flex-col md:flex-row relative py-5 justify-center gap-20">
            <ChapterDetails
              chapterNo={chapterNo}
              versionDetails={versionDetails}
              updateActiveVerseDetails={(data) => setActiveVerseDetails(data)}
              bookChapterDetailsLoading={bookChapterDetailsLoading}
            />
            {bibleParallelMode && (
              <ChapterDetails
                chapterNo={chapterNo}
                versionDetails={parallelVersionDetails}
                bibleParallelMode={bibleParallelMode}
                bookChapterDetailsLoading={parallelBookChapterDetailsLoading}
              />
            )}
          </div>
          {
            <div className="h-[70vh] flex items-center justify-center pr-3 text-yellowish text-3xl">
              {bookChapters?.chapters?.length > 0 &&
                parseInt(chapterNo) < bookChapters?.chapters?.length && (
                  <IoIosArrowDropright
                    className="cursor-pointer"
                    onClick={() => {
                      dispatch(setActiveSearchTabAction(SEARCH_TABS.BIBLE));
                      navigate(
                        `/chapter/${versionDetails?.abbr}/${bookId}/${parseInt(chapterNo) + 1}`
                      );
                    }}
                  />
                )}
            </div>
          }
        </div>
        <StreakModal showModal={streakModalOpen} setShowModal={setStreakModalOpen} />
      </div>
    </>
  );
};

export default ChapterContainer;
