import { useState } from "react";

import { AiOutlineStar, AiFillStar } from "react-icons/ai";

const StarRating = ({
  setRating,
  rating,
  editable = false,
  starClassName,
  startClassName = "",
}) => {
  const [hoverIndex, setHoverIndex] = useState(-1);

  const handleClick = (index) => {
    if (!editable) return;
    setRating(index);
  };

  const handleMouseEnter = (index) => {
    if (!editable) return;
    setHoverIndex(index);
  };

  const handleMouseLeave = () => {
    if (!editable) return;
    setHoverIndex(-1);
  };

  return (
    <div className="flex items-center gap-0.5">
      {[...Array(5)].map((_, index) => (
        <span
          key={"start-item" + index}
          onClick={() => handleClick(index + 1)}
          onMouseEnter={() => handleMouseEnter(index)}
          onMouseLeave={handleMouseLeave}
          className={`transition-all duration-300 ${starClassName}`}
        >
          {rating >= index + 1 || (editable && hoverIndex <= index) ? (
            <AiFillStar className={`text-yellow-500 ${startClassName}`} />
          ) : (
            <AiOutlineStar className={`text-yellow-500 ${startClassName}`} />
          )}
        </span>
      ))}
    </div>
  );
};
export default StarRating;
