import { useEffect, useMemo, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import NotesModel from "./NotesModel";
import VersesModel from "./VersesModel";
import VerseDetails from "./VerseDetails";
import Toastiy from "../../components/common/Toastiy";
import AudioVideoCompModel from "./AudioVideoCompModel";

import {
  deleteVerseColorAction,
  getBibleSummaryAction,
  getChapterDetailsAction,
  getVerseColorAction,
  getVerseDetailsAction,
  saveVerseColorAction,
  updateVerseColorAction,
} from "../../store/Bible/BibleAction";
import { userFavAction, userUnFavAction } from "../../store/Home/HomeAction";

import { useAuth0 } from "@auth0/auth0-react";
import { MdOutlineBookmark } from "react-icons/md";
import HoverJournals from "../../assets/icons/NavIcons/hoverJournals.svg";
import BibleVersionControl from "../../components/bible/BibleVersionControl";
import ParallelVerses from "../../components/bible/ParallelVerses";

const ChapterDetails = ({
  chapterNo,
  className = "",
  versionDetails = {},
  bibleParallelMode = false,
  updateActiveVerseDetails = () => {},
}) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const { bookId } = useParams();
  const { isAuthenticated } = useAuth0();

  const { bibleLanguage, bibleParallelMode: globalBibleParallelMode } = useSelector(
    (state) => state.bible
  );
  const { isMobile } = useSelector((state) => state.common);

  const [active, setActive] = useState(-1);
  const [activeVerseDetails, setActiveVerseDetails] = useState({});
  const [selectedVerses, setSelectedVerses] = useState({});
  const [isNotesActive, setIsNotesActive] = useState(false);
  const [isNewNotesActive, setIsNewNotesActive] = useState(false);
  const [isNotesPopupActive, setIsNotesPopupActive] = useState(false);
  const [existingJournalId, setExistingJournalId] = useState(null);
  const [isTextAvailable, setIsTextAvailable] = useState(false);
  const [bookChapterVerseDetails, setBookChapterVerseDetails] = useState([]);
  const [bookChapterVerseDetailsLoading, setBookChapterVerseDetailsLoading] = useState(false);
  const [isAudioAvailable, setIsAudioAvailable] = useState(false);
  const [bookChapterAudioDetails, setBookChapterAudioDetails] = useState([]);
  const [bookChapterAudioDetailsLoading, setBookChapterAudioDetailsLoading] = useState(false);
  const [isVideoAvailable, setIsVideoAvailable] = useState(false);
  const [bookChapterVideoDetails, setBookChapterVideoDetails] = useState([]);
  const [bookChapterVideoDetailsLoading, setBookChapterVideoDetailsLoading] = useState(false);
  const [bibleSummary, setBibleSummary] = useState({});

  console.log("selectedVerses", selectedVerses);
  console.log("versionDetails", versionDetails);
  console.log("active index", active);

  const handleColorChange = (color, index, item) => {
    console.log("firstselectedVerses", selectedVerses, bookId, item?.verse_start);
    setSelectedVerses((prev) => {
      let newData = structuredClone(prev);
      newData[`${bookId}${item?.verse_start}`] = { color: color };

      return newData;
    });
    setActive(-1);
    if (selectedVerses?.[`${bookId}${item?.verse_start}`]?.verseDetails?.[0]?.verseObjId) {
      dispatch(
        updateVerseColorAction({
          apiPayloadRequest: {
            color: color.replaceAll("#", ""),
            versuObjId:
              selectedVerses?.[`${bookId}${item?.verse_start}`]?.verseDetails?.[0]?.verseObjId,
          },
          callback: (response) => {
            console.log("verse save response", response);
            setActive(-1);
          },
        })
      );
    } else {
      dispatch(
        getVerseDetailsAction({
          apiPayloadRequest: {
            languageid: bibleLanguage,
            versionid: versionDetails?.abbr,
            bookid: bookId,
            bookname: bookName,
            chapterid: chapterNo,
            verse_start: bookChapterVerseDetails?.[index]?.verse_start,
            verse_end: bookChapterVerseDetails?.[index]?.verse_end,
            verseText: bookChapterVerseDetails?.[index]?.verse_text,
          },
          callback: (response) => {
            const verseDetails = response?.data?.[0];

            dispatch(
              saveVerseColorAction({
                apiPayloadRequest: {
                  color: color.replaceAll("#", ""),
                  chapterObjId: verseDetails?._id,
                  versuObjId: verseDetails?.verses?.[0]?.verseObjId,
                },
                callback: (response) => {
                  console.log("verse save response", response);
                  setSelectedVerses((prev) => {
                    let newData = structuredClone(prev);
                    newData[`${bookId}${item?.verse_start}`] = {
                      color: color,
                      verseDetails: [
                        {
                          chapterObjId: verseDetails?._id,
                          verseObjId: verseDetails?.verses?.[0]?.verseObjId,
                          verse_start: bookChapterVerseDetails?.[index]?.verse_start + "",
                        },
                      ],
                    };

                    return newData;
                  });
                  setActive(-1);
                },
              })
            );
          },
        })
      );
    }
  };

  const handleRemoveColor = (index, item) => {
    setSelectedVerses((prev) => {
      let newData = structuredClone(prev);
      delete newData[`${bookId}${item?.verse_start}`];

      return newData;
    });
    setActive(-1);
    if (selectedVerses?.[`${bookId}${item?.verse_start}`]?.verseDetails?.[0]?.verseObjId) {
      dispatch(
        deleteVerseColorAction({
          apiPayloadRequest: {
            versuObjId:
              selectedVerses?.[`${bookId}${item?.verse_start}`]?.verseDetails?.[0]?.verseObjId,
          },
          callback: (response) => {
            console.log("verse save response", response);
            setActive(-1);
          },
        })
      );
    }
  };

  const handleSaveVerse = (index) => {
    dispatch(
      getVerseDetailsAction({
        apiPayloadRequest: {
          languageid: bibleLanguage,
          versionid: versionDetails?.abbr,
          bookid: bookId,
          bookname: bookName,
          chapterid: chapterNo,
          verse_start: bookChapterVerseDetails?.[index]?.verse_start,
          verse_end: bookChapterVerseDetails?.[index]?.verse_end,
          verseText: bookChapterVerseDetails?.[index]?.verse_text,
        },
        callback: (response) => {
          const verseDetails = response?.data?.[0];
          dispatch(
            userFavAction({
              apiPayloadRequest: {
                type: "4",
                chapterObjId: verseDetails?._id,
                versuObjId: verseDetails?.verses?.[0]?.verseObjId,
              },
              callback: (response) => {
                console.log("verse save response", response);
                setActive(-1);
                Toastiy("Verse Saved Successfully", 1);
                fetchBibleSummary(versionDetails?.abbr, bibleLanguage);
              },
            })
          );
        },
      })
    );
  };

  const handleRemoveVerse = (chapterObjId, verseObjId) => {
    dispatch(
      userUnFavAction({
        apiPayloadRequest: {
          type: "4",
          chapterObjId: chapterObjId,
          versuObjId: verseObjId,
        },
        callback: (response) => {
          console.log("verse remove response", response);
          setActive(-1);
          Toastiy("Verse Removed", 1);
          fetchBibleSummary(versionDetails?.abbr, bibleLanguage);
        },
      })
    );
  };

  const fetchChapterVideoDetails = () => {
    setBookChapterVideoDetailsLoading(true);
    const bookDetails = versionDetails?.books?.find((book) => book?.book_id === bookId);
    console.log("bookDetails", bookDetails);
    const filesets = [
      ...(versionDetails?.filesets?.["dbp-prod"]?.filter(
        (d) =>
          (d?.size?.includes(bookDetails?.testament) || d?.size === "C") &&
          d?.type === "video_stream"
      ) ?? []),
      ...(versionDetails?.filesets?.["dbp-vid"]?.filter(
        (d) =>
          (d?.size?.includes(bookDetails?.testament) || d?.size === "C") &&
          d?.type === "video_stream"
      ) ?? []),
    ];

    setIsVideoAvailable(filesets?.length > 0);

    console.log("filesets", filesets);
    if (filesets?.length > 0) {
      dispatch(
        getChapterDetailsAction({
          apiPayloadRequest: {
            filesetid: filesets?.[0]?.id ?? "",
            bookid: bookId,
            chapterid: chapterNo,
          },
          callback: (res) => {
            setBookChapterVideoDetails(res?.data?.[0] ?? []);
            setIsVideoAvailable(res?.data?.[0]?.length > 0);
            setBookChapterVideoDetailsLoading(false);
          },
        })
      );
    } else {
      setBookChapterVideoDetailsLoading(false);
      setBookChapterVideoDetails([]);
      setIsVideoAvailable(false);
    }
  };

  const fetchChapterAudioDetails = () => {
    setBookChapterAudioDetailsLoading(true);
    const bookDetails = versionDetails?.books?.find((book) => book?.book_id === bookId);
    console.log("bookDetails", bookDetails);
    const filesets = [
      ...(versionDetails?.filesets?.["dbp-prod"]?.filter(
        (d) =>
          ["audio", "audio_drama"].includes(d?.type) &&
          d?.codec === "mp3" &&
          d?.size?.includes(bookDetails?.testament)
      ) ?? []),
      ...(versionDetails?.filesets?.["dbp-vid"]?.filter(
        (d) =>
          ["audio", "audio_drama"].includes(d?.type) &&
          d?.codec === "mp3" &&
          d?.size?.includes(bookDetails?.testament)
      ) ?? []),
    ];

    filesets?.sort((a, b) => (a?.type === "audio" ? -1 : b?.type === "audio" ? 1 : 0));

    setIsAudioAvailable(filesets?.length > 0);

    console.log("filesets", filesets);
    if (filesets?.length > 0) {
      dispatch(
        getChapterDetailsAction({
          apiPayloadRequest: {
            filesetid: filesets?.[0]?.id ?? "",
            bookid: bookId,
            chapterid: chapterNo,
          },
          callback: (res) => {
            setBookChapterAudioDetails(res?.data?.[0] ?? []);
            setIsAudioAvailable(res?.data?.[0]?.length > 0);
            setBookChapterAudioDetailsLoading(false);
          },
        })
      );
    } else {
      setBookChapterAudioDetailsLoading(false);
      setBookChapterAudioDetails([]);
      setIsAudioAvailable(false);
    }
  };

  const fetchChapterVerseDetails = () => {
    setBookChapterVerseDetailsLoading(true);
    const bookDetails = versionDetails?.books?.find((book) => book?.book_id === bookId);
    console.log("bookDetails", bookDetails);
    const filesets = [
      ...(versionDetails?.filesets?.["dbp-prod"]?.filter(
        (d) =>
          (d?.size?.includes(bookDetails?.testament) || d?.size === "C") &&
          (d?.type === "text_plain" || d?.type === "text_format")
      ) ?? []),
      ...(versionDetails?.filesets?.["dbp-vid"]?.filter(
        (d) =>
          (d?.size?.includes(bookDetails?.testament) || d?.size === "C") &&
          (d?.type === "text_plain" || d?.type === "text_format")
      ) ?? []),
    ];

    filesets?.sort((a, b) => (a?.type === "text_plain" ? -1 : b?.type === "text_plain" ? 1 : 0));

    setIsTextAvailable(filesets?.length > 0);

    console.log("filesets", filesets);
    if (filesets?.length > 0) {
      dispatch(
        getChapterDetailsAction({
          apiPayloadRequest: {
            filesetid: filesets?.[0]?.id ?? "",
            bookid: bookId,
            chapterid: chapterNo,
          },
          callback: (res) => {
            setBookChapterVerseDetails(res?.data?.[0] ?? []);
            setIsTextAvailable(res?.data?.[0]?.length > 0);
            setBookChapterVerseDetailsLoading(false);

            if (res?.data?.[0]?.length > 0 && location?.state?.verse_start) {
              setTimeout(() => {
                const element = document.querySelector(
                  `#${location?.state?.versionid}${location?.state?.bookid}${location?.state?.chapterid}${location?.state?.verse_start}`
                );

                if (element) element.scrollIntoView({ behavior: "smooth" });
              }, 500);
            }
          },
        })
      );
    } else {
      setBookChapterVerseDetailsLoading(false);
      setBookChapterVerseDetails([]);
      setIsTextAvailable(false);
    }
  };

  useEffect(() => {
    if (versionDetails?.abbr) {
      fetchChapterVerseDetails();
      fetchChapterAudioDetails();
      fetchChapterVideoDetails();
    }
  }, [versionDetails?.abbr, bookId, chapterNo]);

  const fetchBibleSummary = (version, language) => {
    dispatch(
      getBibleSummaryAction({
        apiPayloadRequest: { versionid: version, languageid: language },
        callback: (res) => {
          setBibleSummary(res?.data?.[0]);
        },
      })
    );
  };

  useEffect(() => {
    if (isAuthenticated) {
      fetchBibleSummary(versionDetails?.abbr, bibleLanguage);
    }
  }, [versionDetails?.abbr, bibleLanguage, isAuthenticated]);

  const updatedVerseList = useMemo(() => {
    const updatedList = bookChapterVerseDetails?.map((item) => {
      const noteFound = bibleSummary?.notessummary?.find(
        (d) =>
          d?.notes?.bookid === item?.book_id &&
          d?.notes?.chapterid === item?.chapter_alt &&
          d?.notes?.verse_start === item?.verse_start_alt
      );
      const verseFound = bibleSummary?.favsummary?.find(
        (d) =>
          d?.bookid === item?.book_id &&
          d?.chapterid === item?.chapter_alt &&
          d?.verseDetails?.[0]?.verse_start === item?.verse_start_alt
      );
      return {
        ...item,
        noteObjId: noteFound?.notes?.noteObjId,
        journalId: noteFound?._id,
        verseObjId: verseFound?.verseDetails?.[0]?.verseObjId,
        chapterObjId: verseFound?.chapterObjId,
      };
    });
    return updatedList;
  }, [bibleSummary, bookChapterVerseDetails]);

  const bookName = versionDetails?.books?.find((d) => d?.book_id === bookId)?.name ?? "";

  console.log("versionDetails", versionDetails);

  const fetchVerseColors = () => {
    dispatch(
      getVerseColorAction({
        apiPayloadRequest: {
          bookid: bookId,
          chapterid: chapterNo,
        },
        callback: (response) => {
          const uppdatedSelectedColors = {};
          response?.data?.forEach((d) => {
            uppdatedSelectedColors[`${bookId}${d?.verseDetails?.[0]?.verse_start}`] = {
              ...(d ?? {}),
              color: `#${d?.color}`,
            };
          });
          setSelectedVerses(uppdatedSelectedColors);
        },
      })
    );
  };

  useEffect(() => {
    if (isAuthenticated && bookId && chapterNo) fetchVerseColors();
  }, [isAuthenticated, bookId, chapterNo]);

  const fetchVerseDetails = (verse_start, verse_end, verse_text, responseCallback = null) => {
    dispatch(
      getVerseDetailsAction({
        apiPayloadRequest: {
          languageid: bibleLanguage,
          versionid: versionDetails?.abbr,
          bookid: bookId,
          bookname: bookName,
          chapterid: chapterNo,
          verse_start: verse_start,
          verse_end: verse_end,
          verseText: verse_text,
        },
        callback: (response) => {
          console.log("responseCallback", responseCallback);
          if (responseCallback !== null) {
            responseCallback(response?.data?.[0]);
          }
        },
      })
    );
  };

  useEffect(() => {
    if (
      isAuthenticated &&
      !bibleParallelMode &&
      bookChapterVerseDetails?.length > 0 &&
      versionDetails?.abbr
    ) {
      fetchVerseDetails(
        bookChapterVerseDetails?.[0]?.verse_start,
        bookChapterVerseDetails?.[0]?.verse_end,
        bookChapterVerseDetails?.[0]?.verse_text,
        (data) => updateActiveVerseDetails(data)
      );
    }
  }, [bibleParallelMode, bookChapterVerseDetails, versionDetails, isAuthenticated]);

  console.log("selectedVerses", selectedVerses);
  console.log("updatedVerseList", updatedVerseList);

  return (
    <>
      {bookChapterVerseDetailsLoading ? (
        <div className="w-full flex justify-center">
          <div className="p-6 w-full md:w-5/12 flex flex-col gap-3">
            <div className="w-7/12 h-6 mb-4 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-10/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-7/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-10/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-7/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-10/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-7/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-10/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-full h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
            <div className="w-7/12 h-6 animate-pulse bg-zinc-600 rounded-2xl"></div>
          </div>
        </div>
      ) : (
        <div
          className={`w-full md:w-2/4 flex flex-col gap-5 px-2 md:px-20 ${className} ${
            globalBibleParallelMode && isMobile ? "h-[30vh] overflow-y-auto" : ""
          }`}
        >
          <div className={`flex items-center gap-10 ${isTextAvailable ? "flex-row" : "flex-col"}`}>
            <p className="text-yellowish text-2xl font-black">
              {bookName}&nbsp;{chapterNo}
            </p>

            <AudioVideoCompModel
              isTextAvailable={isTextAvailable}
              isAudioAvailable={isAudioAvailable}
              isVideoAvailable={isVideoAvailable}
              versionDetails={versionDetails}
              bookChapterAudioDetails={bookChapterAudioDetails}
              bookChapterAudioDetailsLoading={bookChapterAudioDetailsLoading}
              bookChapterVideoDetails={bookChapterVideoDetails}
              bookChapterVideoDetailsLoading={bookChapterVideoDetailsLoading}
              bibleParallelMode={bibleParallelMode}
              verseDetails={versionDetails}
            />
          </div>
          {globalBibleParallelMode && bookName && versionDetails?.abbr && versionDetails?.name && (
            <div className="flex items-center gap-2 text-xs">
              {!bibleParallelMode && <BibleVersionControl containerKey="bibleChapterVersion" />}
              {bibleParallelMode && <ParallelVerses containerKey="parallelChapterBibleVersion" />}
              <p className="text-white text-[0.8rem]">{versionDetails?.name}</p>
            </div>
          )}
          <div className="">
            <h3 className="text-white text-lg font-semibold pb-3">{""}</h3>

            <ul className="text-greyish">
              {updatedVerseList?.map((item, index) => {
                return (
                  <li
                    key={`version-item${item?.verse_start}`}
                    className={`w-fit flex items-start my-2 cursor-pointer ${
                      active === index ? "border-b" : ""
                    } ${
                      selectedVerses?.[`${bookId}${item?.verse_start}`]?.color ? "text-black" : ""
                    }`}
                    onClick={() => {
                      setActive(index);
                      setActiveVerseDetails(item);
                    }}
                  >
                    <span
                      className="p-0.5 leading-[19.5px] whitespace-nowrap relative"
                      style={{
                        background: selectedVerses?.[`${bookId}${item?.verse_start}`]?.color ?? "",
                      }}
                    >
                      <span className="absolute -left-1 -translate-x-full top-0 flex items-center gap-0.5">
                        {item?.verseObjId && (
                          <MdOutlineBookmark
                            className="text-2xl text-yellowish cursor-pointer"
                            onClick={(e) => {
                              e.stopPropagation();
                              handleRemoveVerse(item?.chapterObjId, item?.verseObjId);
                            }}
                          />
                        )}
                        {item?.journalId && (
                          <img
                            src={HoverJournals}
                            alt="HoverJournals"
                            className="min-w-5 max-w-5"
                            onClick={(e) => {
                              e.stopPropagation();
                              setActiveVerseDetails(null);
                              setExistingJournalId(item?.journalId);
                              setIsNotesActive(true);
                            }}
                          />
                        )}
                      </span>
                      {item?.verse_start}
                    </span>
                    <VerseDetails
                      item={item}
                      index={index}
                      active={active}
                      bookId={bookId}
                      versionId={versionDetails?.abbr}
                      bookName={bookName}
                      chapterNo={chapterNo}
                      setActive={setActive}
                      verseText={item?.verse_text}
                      isNotesActive={isNotesActive}
                      parallelMode={bibleParallelMode}
                      handleSaveVerse={handleSaveVerse}
                      setIsNotesActive={setIsNotesActive}
                      handleColorChange={handleColorChange}
                      handleRemoveColor={handleRemoveColor}
                      setIsNotesPopupActive={setIsNotesPopupActive}
                      selectedVerses={selectedVerses}
                      bgColor={selectedVerses?.[`${bookId}${item?.verse_start}`]?.color ?? ""}
                    />
                  </li>
                );
              })}
            </ul>
          </div>
        </div>
      )}

      {isNotesPopupActive && (
        <VersesModel
          active={active}
          setActive={setActive}
          isNotesActive={isNotesActive}
          setIsNotesActive={setIsNotesActive}
          isNotesPopupActive={isNotesPopupActive}
          setIsNewNotesActive={setIsNewNotesActive}
          setIsNotesPopupActive={setIsNotesPopupActive}
          setExistingJournalId={setExistingJournalId}
          gridItems={isMobile ? 2 : 4}
        />
      )}
      {isNotesActive && (
        <NotesModel
          bookName={bookName}
          isNotesActive={isNotesActive}
          isNewNotesActive={isNewNotesActive}
          setIsNotesActive={() => {
            setIsNotesActive(false);
            setExistingJournalId(null);
            setActiveVerseDetails(null);
          }}
          activeVerseDetails={activeVerseDetails}
          setActiveVerseDetails={setActiveVerseDetails}
          existingJournalId={existingJournalId}
          versionDetails={versionDetails}
          onNoteSave={() => {
            fetchBibleSummary(versionDetails?.abbr, bibleLanguage);
          }}
        />
      )}
    </>
  );
};

export default ChapterDetails;
