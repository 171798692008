import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { SEARCH_TABS, SEARCH_TABS_KEYS } from "../../constants/common";

const BibleLangaugeSearchV2 = ({ isVisible, handleLanguageChange, loading }) => {
  const { allBibleLanguages, activeSearchTab } = useSelector((state) => state.bible);

  const { currentLanguageTitles } = useSelector((state) => state.languageReducer.data);

  const [languages, setLanguages] = useState([]);
  const [searchText, setSearchText] = useState("");

  useEffect(() => {
    if (allBibleLanguages?.length > 0) {
      setLanguages(allBibleLanguages);
    }
  }, [allBibleLanguages]);

  useEffect(() => {
    if (!searchText) {
      setLanguages(allBibleLanguages);
    } else {
      const text = searchText?.toLowerCase();
      const updatedLanguages = allBibleLanguages?.filter((item) => {
        return (
          item?.name?.toLowerCase()?.includes(text) || item?.code?.toLowerCase()?.includes(text)
        );
      });
      setLanguages(updatedLanguages);
    }
  }, [allBibleLanguages, searchText]);

  const handleSearch = (e) => {
    setSearchText(e.target.value);
  };

  console.log("allBibleLanguages", allBibleLanguages);

  return (
    <div className={`flex-col h-full pb-10 ${isVisible ? "flex" : "hidden"}`}>
      <div className="flex items-center gap-5 pb-7">
        <div className="w-full bg-white/5 md:min-w-[400px] flex items-center gap-3 border rounded-full px-5 py-2 border-greyish">
          <input
            type="text"
            value={searchText}
            placeholder={
              currentLanguageTitles?.[0]?.bible?.[SEARCH_TABS_KEYS[SEARCH_TABS.LANGUAGE]] ??
              "Search Language"
            }
            onChange={(e) => handleSearch(e)}
            className="w-full bg-transparent outline-none text-white placeholder:text-greyish"
          />
        </div>
      </div>
      <ul className="flex-1 flex flex-col gap-3 pl-3 overflow-y-auto custom-scrollbar">
        {loading ? (
          <div className="w-full flex flex-col gap-4">
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
            <div className="animate-pulse bg-zinc-600 rounded-2xl h-6 w-full"></div>
          </div>
        ) : (
          languages?.map((lang, index) => (
            <li
              key={`${lang?.name}-${lang?.id}${index}`}
              className="text-lg flex items-center gap-3 cursor-pointer"
              onClick={() => handleLanguageChange(lang?.id)}
            >
              <input
                type="radio"
                id={lang?.id}
                name="language"
                value={lang?.id}
                className="h-5 aspect-square"
              />
              <label htmlFor={lang?.id} className="text-sm text-white/80 cursor-pointer">
                {lang?.name}
              </label>
            </li>
          ))
        )}
      </ul>
    </div>
  );
};

export default BibleLangaugeSearchV2;
