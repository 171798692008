import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";

import ChapterContainer from "./ChapterContainer";
import Navbar from "../../components/common/Navbar";
import HeaderBar from "../../components/common/HeaderBar";

import { setLoginHardBlocker } from "../../store/Home/HomeAction";
import { getVersionBooksAction, setVersionDetailsAction } from "../../store/Bible/BibleAction";

const BookChapter = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const { chapterNo, bookId } = useParams();

  const { versionDetails, bibleVersion } = useSelector((state) => state.bible);

  const initialTabs = [
    "Bible",
    // "Plans"
  ];
  const checkToken = localStorage.getItem("token");

  const [activeTab, setActiveTab] = useState(0);

  const [tabValues, setTabsValues] = useState(initialTabs);

  const handleTabChange = (index) => {
    if (index === 0) {
      navigate("/bible");
    } else {
      navigate("/plan");
    }

    if (checkToken) {
      setActiveTab(index);
    } else {
      dispatch(setLoginHardBlocker(true));
    }
  };

  const fetchVersionBooks = (version) => {
    dispatch(
      getVersionBooksAction({
        apiPayloadRequest: { versionid: version },
        callback: (res) => {
          dispatch(setVersionDetailsAction(res?.data));
        },
      })
    );
  };

  useEffect(() => {
    if (versionDetails?.abbr !== bibleVersion) {
      navigate(`/chapter/${bibleVersion}/${bookId}/${chapterNo}`);
      fetchVersionBooks(bibleVersion);
    }
  }, [versionDetails, bibleVersion]);

  return (
    <>
      <div className="flex flex-col md:flex-row w-full overflow-hidden">
        <Navbar active={"Bible"} />
        <div className="md:ml-[104px] flex flex-col w-full min-h-screen">
          <HeaderBar
            tabValues={tabValues}
            active={activeTab}
            absolute={false}
            handleTabChange={handleTabChange}
          />
          <ChapterContainer />
        </div>
      </div>
    </>
  );
};

export default BookChapter;
